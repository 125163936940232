import React from 'react'

import CompleteTemporaryReserve from 'src/containers/Video/CompleteTemporaryReserve'

const TemporaryReserveConfirm: React.FC = () => {
  return <CompleteTemporaryReserve />
}

export const PATH = '/video/complete-temporary-reserve' as const
export default TemporaryReserveConfirm

import React from 'react'

import SuggestSubscriptionContainer from 'src/containers/AutomaticPlan/SuggestSubscription'

const SuggestShampooPage: React.FC = () => {
  return <SuggestSubscriptionContainer />
}

export const PATH = '/automatic-plan/suggest-subscription' as const
export default SuggestShampooPage

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FeedbackDetailResponseInterface } from 'src/interfaces/feedback'

type State = {
  loading: boolean
  entity: FeedbackDetailResponseInterface | null
}

const initialState: State = {
  loading: false,
  entity: null,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'FeedbackDetails',
  initialState,
  reducers: {
    updateEntity: (
      state,
      action: PayloadAction<FeedbackDetailResponseInterface>,
    ) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Col, Form, Row } from 'antd'
import { useNavigate } from 'react-router-dom'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import ButtonCustom from 'src/components/form/Button'
import { useSMSForm } from './useSMSForm'
import { InputField } from 'src/components/form'
import { verifyOTPRequestInterface } from 'src/interfaces/address'
import { useAppDispatch, useAppSelector } from 'src/store'
import { resendOTP } from 'src/useCase/address'
import { PLAN_TYPE } from 'src/constants/app'
import { getUserPlanType } from 'src/utils/helper'
import { getInfo } from 'src/useCase/user'
import LocalStorage from 'src/utils/LocalStorage'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'

const SMSAuthContainer: React.FC = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const planType =
    LocalStorage.planType || getUserPlanType(user?.state_transitions || [])
  const { form, onSubmit } = useSMSForm(planType)
  const { control, handleSubmit, formState } = form

  const back = () => {
    navigate(-1)
  }
  const handleResend = () => {
    dispatch(resendOTP())
  }

  const isSubscription = planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION

  useEffect(() => {
    if (user?.state_transitions !== undefined) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
    }
  }, [])
  return (
    <>
      <LPHeader />
      <div
        className={`w-full justify-center mx-auto register-form ${
          isSubscription ? 'layout-webview' : 'layout-webview-step'
        }`}
      >
        {!isSubscription && (
          <StepsHeader
            step={4}
            stepActive={3}
            stepActiveName={'会員登録'}
            className="top-70"
          />
        )}

        {/* only subscription */}
        {isSubscription && <HeaderUnderlined name="会員登録" />}
        <HeaderStyle>SMS認証</HeaderStyle>
        <p>SMSに届いたコードを入力してください。</p>
        <StyleForm onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-30">
            <Col xs={24} className="font-weight-bold">
              <label>コード</label>
            </Col>
            <Col xs={24}>
              <InputField<verifyOTPRequestInterface>
                controller={{
                  control,
                  name: 'otp',
                }}
                inputProps={{
                  placeholder: '入力してください',
                  pattern: '\\d*',
                }}
              />
            </Col>
          </Row>

          <div>
            <div>
              <p className="mb-0">
                SMS認証ボタンをクリック後、ご入力された電話番号のSMSに認証用コードが送信されます。
              </p>
              <p className="mb-0">
                受け取り後、コードを入力して認証を行ってください。
              </p>
            </div>
            <LinkStyle onClick={handleResend}>
              {isSubscription
                ? '再度、認証コードを送信する方はこちら'
                : '再度、認証コードを送信する方はこちらをクリックしてください。'}
            </LinkStyle>
          </div>
          <ButtonCustom
            className="btn-default btn-chestnut-rose mt-5"
            loading={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
            htmlType="submit"
            showIconRight
            name="認証する"
          />
        </StyleForm>
        <ButtonCustom
          className="btn-default btn-white mt-30"
          loading={false}
          onClick={back}
          htmlType="submit"
          name="戻る"
        />
      </div>
    </>
  )
}

const LinkStyle = styled.div`
  text-decoration: underline;
  color: #1e4c82;
  margin-top: 30px;
  text-align: center;
`
const StyleForm = styled(Form)``

export default SMSAuthContainer

import React from 'react'

import { PATH } from 'src/pages/kartePlan'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'

const AuthRoute: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.PRESCRIPTION_PROPOSAL,
        element: loadable(
          () => import('src/pages/kartePlan/PrescriptionProposal'),
        ),
      },
    ],
  },
]

export default AuthRoute

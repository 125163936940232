import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AdditionalShampoo } from 'src/interfaces/additionalShampoo'

type State = {
  entity: AdditionalShampoo | null
}

const initialState: State = {
  entity: null,
}

const { actions, reducer } = createSlice({
  name: 'purchase_additional_shampoo',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<AdditionalShampoo>) => {
      state.entity = action.payload
    },
  },
})

export { reducer }
export default actions

import { createSelector } from '@reduxjs/toolkit'

// Utils
import { diffBetweenTwoDates } from 'src/utils/helper'

export const selectMessages = createSelector(
  (state) => state.domain.chat.message.messages,
  (messages) => {
    const formattedMessages = messages
      .map((x) => {
        const daysAgo = diffBetweenTwoDates(
          new Date().toISOString(),
          x.created_at,
        )
        return {
          ...x,
          daysAgo,
        }
      })
      .reduce((accumulator, currentValue, currentIndex, currentMessages) => {
        const nextIndex = currentIndex + 1
        const nextValue = currentMessages[nextIndex]
        if (nextValue?.daysAgo !== currentValue?.daysAgo) {
          accumulator.push({
            ...currentValue,
            is_new_date: true,
          })
          return accumulator
        }
        accumulator.push({ ...currentValue })
        return accumulator
      }, [])
    return formattedMessages
  },
)

export const selectIsFetchingMessage = createSelector(
  (state) => state.domain.chat.message.isFetchingMessage,
  (isFetchingMessage) => isFetchingMessage,
)

export const selectIsSending = createSelector(
  (state) => state.domain.chat.message.isSending,
  (isSending) => isSending,
)

export const selectCountMessageRead = createSelector(
  (state) => state.domain.chat.message.countMessageRead,
  (countMessageRead) => countMessageRead,
)

export const selectLastPage = createSelector(
  (state) => state.domain.chat.message.lastPage,
  (lastPage) => lastPage,
)

export const selectScrollToBottom = createSelector(
  (state) => state.domain.chat.message.scrollToBottom,
  (scrollToBottom) => scrollToBottom,
)

import React, { Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Form, Row } from 'antd'

import { useAddressEntryForm } from './useAddressEntryForm'
import { InputField } from 'src/components/form'
import {
  InputLabel,
  LabelRequire,
} from 'src/components/layout/Header/HeaderStyle'
import ButtonCustom from 'src/components/form/Button'
import { getAddressByPostalCode } from 'src/useCase/address'
import { useAppDispatch, useAppSelector } from 'src/store'
import { UpdateAddressRequestInterface } from 'src/interfaces/address'
import { FROM_ADDRESS } from 'src/constants/app'
import { getUserPlanType } from 'src/utils/helper'
import { getInfo } from 'src/useCase/user'
import LocalStorage from 'src/utils/LocalStorage'

type Props = {
  from?: string
}

const AddressEntryForm = (props: Props) => {
  const dispatch = useAppDispatch()
  const [isPostalCode, setPostalCode] = useState(false)

  const { from } = props

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const planType =
    LocalStorage.planType || getUserPlanType(user?.state_transitions || [])

  const isMedical = from === FROM_ADDRESS.MEDICAL
  const { form, onSubmit } = useAddressEntryForm({ planType, isMedical })
  const { control, handleSubmit, formState } = form

  const addressByPostalCode = useAppSelector(
    (state) => state.domain.addressByPostalCode.addressByPostalCode.entity,
  )
  const postalCodeLoading = useAppSelector(
    (state) => state.domain.addressByPostalCode.addressByPostalCode.loading,
  )

  const handleGetAddress = () => {
    setPostalCode(true)
    dispatch(
      getAddressByPostalCode({
        postal_code: form.watch('postal_number') || '',
      }),
    )
  }

  useEffect(() => {
    if (isPostalCode) {
      form.setValue('prefecture_id', addressByPostalCode?.address1 || '')
      form.clearErrors('prefecture_id')
      form.setValue('city_id', addressByPostalCode?.address2 || '')
      form.clearErrors('city_id')
      // form.setValue('address', addressByPostalCode?.address3 || '')
      // form.clearErrors('address')
    }
  }, [addressByPostalCode, isPostalCode])

  useEffect(() => {
    form.setValue('email', user?.email || '')
    form.clearErrors('email')
  }, [user])

  useEffect(() => {
    if (user?.state_transitions !== undefined) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
    }
  }, [])

  return (
    <Fragment>
      <StyleForm>
        {!isMedical && (
          <>
            <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel>郵便番号</InputLabel>
                <LabelRequire>
                  <span>必須</span>
                </LabelRequire>
              </Col>
              <Row className="d-flex justify-content-between w-100">
                <Col xs={15}>
                  <InputField<UpdateAddressRequestInterface>
                    controller={{
                      control,
                      name: 'postal_number',
                    }}
                    inputProps={{
                      placeholder: '入力してください',
                      pattern: '\\d*',
                    }}
                  />
                </Col>
                <Col xs={8}>
                  <ButtonCustom
                    className="btn-default btn-black btn-postal"
                    loading={postalCodeLoading}
                    disabled={!form.watch('postal_number')}
                    onClick={handleGetAddress}
                    htmlType="button"
                    name="自動入力"
                  />
                </Col>
              </Row>
            </Row>
            <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel>都道府県</InputLabel>
                <LabelRequire>
                  <span>必須</span>
                </LabelRequire>
              </Col>
              <Col xs={24}>
                <InputField<UpdateAddressRequestInterface>
                  controller={{
                    control,
                    name: 'prefecture_id',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel>市区町村・番地</InputLabel>
                <LabelRequire>
                  <span>必須</span>
                </LabelRequire>
              </Col>
              <Col xs={24}>
                <InputField<UpdateAddressRequestInterface>
                  controller={{
                    control,
                    name: 'city_id',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                  }}
                />
              </Col>
            </Row>
            {/* <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel className="font-weight-bold">番地以降</InputLabel>
                <LabelRequire>
                  <span>必須</span>
                </LabelRequire>
              </Col>
              <Col xs={24}>
                <InputField<UpdateAddressRequestInterface>
                  controller={{
                    control,
                    name: 'address',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                  }}
                />
              </Col>
            </Row> */}
            <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel>{'建物名・部屋番号'}</InputLabel>
              </Col>
              <Col xs={24}>
                <InputField<UpdateAddressRequestInterface>
                  controller={{
                    control,
                    name: 'building_name',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel>携帯番号（ハイフンなし）</InputLabel>
                <LabelRequire>
                  <span>必須</span>
                </LabelRequire>
              </Col>
              <Col xs={24}>
                <InputField<UpdateAddressRequestInterface>
                  controller={{
                    control,
                    name: 'phone',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                    pattern: '\\d*',
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel>メールアドレス</InputLabel>
            <LabelRequire>
              <span>必須</span>
            </LabelRequire>
          </Col>
          <Col xs={24}>
            <InputField<UpdateAddressRequestInterface>
              controller={{
                control,
                name: 'email',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'email',
              }}
            />
          </Col>
        </Row>

        {!user?.hasPassword && (
          <>
            <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel>パスワード</InputLabel>
                <LabelRequire>
                  <span>必須</span>
                </LabelRequire>
              </Col>
              <Col xs={24}>
                <InputField<UpdateAddressRequestInterface>
                  controller={{
                    control,
                    name: 'password',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                    type: 'password',
                    pattern: 'd*',
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                className="d-flex-row d-flex justify-content-between"
              >
                <InputLabel>パスワード（確認）</InputLabel>
                <LabelRequire>
                  <span>必須</span>
                </LabelRequire>
              </Col>
              <Col xs={24}>
                <InputField<UpdateAddressRequestInterface>
                  controller={{
                    control,
                    name: 'password_confirmation',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                    type: 'password',
                    pattern: 'd*',
                  }}
                />
              </Col>
            </Row>
          </>
        )}
        {!isMedical && (
          <div>
            <p className="mb-0">
              SMS認証ボタンをクリック後、ご入力された電話番号のSMSに認証用コードが送信されます。
            </p>
            <p className="mb-0">
              受け取り後、コードを入力して認証を行ってください。
            </p>

            {!isMedical && <p>認証後、会員登録となります。</p>}
          </div>
        )}

        <ButtonCustom
          className="btn-default btn-chestnut-rose mt-5"
          loading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
          htmlType="submit"
          showIconRight
          name={isMedical ? '登録する' : 'SMS認証へ進む'}
        />

        {/* {!isMedical && (
          <ButtonCustom
            className="btn-default btn-white mt-3 mb-5"
            loading={false}
            onClick={() => navigate(PATH)}
            name="戻る"
          />
        )} */}
      </StyleForm>
    </Fragment>
  )
}

const StyleForm = styled(Form)``

export default AddressEntryForm

import React from 'react'

import SMSAuthContainer from 'src/containers/AutomaticPlan/SMSAuth'

const SMSAuthPage: React.FC = () => {
  return <SMSAuthContainer />
}

export const PATH = '/automatic-plan/sms-auth' as const
export default SMSAuthPage

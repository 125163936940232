import { UserInterface } from 'src/interfaces/user'
import {
  AddressByPostalCodeInterface,
  AddressByPostalCodeRequestInterface,
  UpdateAddressRequestInterface,
  verifyOTPRequestInterface,
} from 'src/interfaces/address'
import HTTPRequest from '../index'

const apiPathGetAddressByPostalCode = 'api/guest/postal-code'
const apiPathUpdateAddress = 'api/users/update-address'
const apiPathVerifyOTP = 'api/users/verify-otp'
const apiPathResendOTP = 'api/users/resend-otp'

class AddressByPostalCode extends HTTPRequest {
  getAddressByPostalCode(params: AddressByPostalCodeRequestInterface) {
    return this.get<AddressByPostalCodeInterface>(
      `${apiPathGetAddressByPostalCode}`,
      {
        params,
      },
    )
  }
  updateAddress(params: UpdateAddressRequestInterface) {
    return this.post<UserInterface>(`${apiPathUpdateAddress}`, { ...params })
  }
  verifyOTP(params: verifyOTPRequestInterface) {
    return this.post(`${apiPathVerifyOTP}`, { ...params })
  }
  resendOTP() {
    return this.post(`${apiPathResendOTP}`)
  }
}

export default new AddressByPostalCode()

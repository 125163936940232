import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useAppSelector } from 'src/store'

// Selectors
import { selectChannel } from 'src/store/domain/chat/channel/selector'
import LPHeader from 'src/components/layout/Header/LPHeader'

// Img
import defaultChat from 'src/assets/images/members/chat/default-doctor.png'

// helpers
import LocalStorage from 'src/utils/LocalStorage'

const ChatHeader = () => {
  const channel = useAppSelector(selectChannel)
  const manager = channel?.managers?.[0]
  const info = useMemo(() => {
    const name = manager?.name
    const imageUrl = manager?.avatar_url
    return {
      name,
      imageUrl,
    }
  }, [manager])

  return (
    <Wrapper>
      <LPHeader />
      <div className="chat-header">
        <div className="chat-header__user-info">
          <img
            src={info.imageUrl ? info.imageUrl : defaultChat}
            className="chat-header__user-info__avatar"
          />
          <div className="chat-header__user-info__info">
            {/* <h3 className="chat-header__user-info__info__title">
              {get(channel, 'managers[0].clinics[0].name', '')}
            </h3> */}
            {LocalStorage.getMenuName() && (
              <>
                <span>診療科目：{LocalStorage.getMenuName()}</span>
                <br />
              </>
            )}

            <span>担当医師：{info.name}</span>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .chat-header {
    &__title {
      font-weight: bold;
      padding: 10px 20px;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.13em;
      text-align: left;
    }

    &__user-info {
      display: flex;
      align-items: center;
      background-color: #cfc392;
      font-size: 15px;
      font-weight: 500;
      line-height: 23px;
      padding: 15px;

      &__avatar {
        width: 50px;
        height: 50px;
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
        border-radius: 50%;
        object-fit: cover;
      }

      &__info {
        padding-left: 15px;

        &__title {
          margin-bottom: 0;
        }
      }
    }
  }
`

export default ChatHeader

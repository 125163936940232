import { useAppDispatch } from 'src/store'
import { squareCharge, SquareChargeParams } from 'src/useCase/payment'

export type FormValues = {
  source_id: string
  plan_type: string
}
export const usePayment = () => {
  const dispatch = useAppDispatch()

  const onCharge = async (tokenResult, planType, orderParams) => {
    const { status, token } = tokenResult
    if (status === 'OK') {
      const chargeParams: SquareChargeParams = {
        source_id: token,
        plan_type: planType,
      }
      await dispatch(
        squareCharge({
          orderParams,
          chargeParams,
        }),
      )
    }
  }

  return {
    onCharge,
  }
}

import React, { useEffect } from 'react'
import styled from 'styled-components'
import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/store'
import history from 'src/libs/history'
import { PATH } from 'src/pages/payment'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import { getInfo } from 'src/useCase/user'
import DescriptionHint from 'src/components/layout/Content/DescriptionHint'

const PaymentErrorContainer: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const back = () => {
    navigate(-1)
    // navigate(PATH.PAYMENT_INFO)
  }

  const errCode = useAppSelector(
    (state) => state.domain.payment.squarePay.errorCode,
  )

  useEffect(() => {
    if (!errCode) {
      history.push(PATH.PAYMENT_INFO)
    }
  }, [])

  useEffect(() => {
    if (user?.state_transitions !== undefined) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
    }
  }, [])
  const user = useAppSelector((state) => state.ui.app.authInfo)

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview mt-40 register-form">
        <StepsHeader
          step={4}
          stepActive={2}
          stepActiveName="決済"
          className="top-70"
        />
        <HeaderStyle>決済エラー</HeaderStyle>
        <DescriptionHint
          listText={[
            'ご指定の方法での決済処理が完了できませんでした。 ',
            'お手数ですが決済方法をご確認の上再度お試しください。',
            '<br />',
            `ErrorCode: ${errCode}`,
          ]}
        />
        <ButtonCustom
          loading={false}
          className="btn-default btn-gurkha mt-5"
          name={'戻る'}
          onClick={() => back()}
          showIconRight
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default PaymentErrorContainer

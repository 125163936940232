import { Precaution } from 'src/interfaces/precaution'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  entity: Precaution[] | []
}

const initialState: State = {
  loading: false,
  entity: [],
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'precautions',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Precaution[]>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

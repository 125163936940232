import format from 'string-format'
import HTTPRequest from '../index'
import { ClinicInterface } from '../../interfaces/clinic'

const apiPathClinicDetail = 'api/v2/users/clinics/{0}'

class Clinic extends HTTPRequest {
  getClinicDetail(uuid: string) {
    return this.get<ClinicInterface>(format(`${apiPathClinicDetail}`, uuid))
  }
}

export default new Clinic()

import React from 'react'

import ChatContainer from 'src/containers/Chat'

const Chat: React.FC = () => {
  return <ChatContainer />
}

export const PATH = '/chat' as const
export default Chat

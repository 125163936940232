import HTTPRequest from '../index'

const apiPath = 'api/users'

class Reservation extends HTTPRequest {
  getVideoAppointment(token: string | undefined) {
    return this.get<Response.VideoAppointment>(
      `${apiPath}/reservations/video-appointment/${token}?time=${Date.now()}`,
    )
  }

  getAgoraToken() {
    return this.get<any>(`${apiPath}/agora-token?channel=doctor`)
  }

  endVideoCall() {
    return this.post<any>(`${apiPath}/end-call-video`)
  }
}

export default new Reservation()

import { useAppDispatch } from 'src/store'
import { squareChangeCard } from 'src/useCase/paymentInfo'

export type FormValues = {
  source_id: string
}
export const usePayment = () => {
  const dispatch = useAppDispatch()

  const onCharge = async (tokenResult) => {
    const { status, token } = tokenResult
    if (status === 'OK') {
      const params: FormValues = {
        source_id: token,
      }
      await dispatch(squareChangeCard(params))
    }
  }

  return {
    onCharge,
  }
}

import HTTPRequest from 'src/api'
import { UserInterface } from 'src/interfaces/user'

const apiPath = 'api/users'

class automaticPlan extends HTTPRequest {
  updateIdentityCard(data: any) {
    return this.post(`${apiPath}/update-identity-card`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }

  uploadHeadImage(data: any) {
    return this.post<UserInterface>(`${apiPath}/upload_head_image`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }
}

export default new automaticPlan()

import React from 'react'
import styled from 'styled-components'

type Props = {
  name: string
  backgroundColor?: string
}

const HeaderUnderlined = (props: Props) => {
  const { name, backgroundColor } = props

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <div className="step-header-padding">
        <div className="d-flex justify-content-between step-header">
          <span className="step-name">{name}</span>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  left: 0;
  top: 0;
  background-color: var(${(props) => props.backgroundColor ?? '--color-white'});
  .step-header-padding {
    padding: 10px 0;
    border-bottom: 1px solid var(--color-black);
  }
  .step-name {
    font-weight: 400;
    // padding-left: 10px;
    font-size: 18px;
  }
  .icon-close {
    height: 1rem;
    margin-top: 5px;
  }
`

export default HeaderUnderlined

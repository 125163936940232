import React, { useState } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'antd'

type propsType = {
  imgSrc?: any
  height?: string
  width?: string
  className?: string
}

const LazyLoadImage: React.FC<propsType> = (props: propsType) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { imgSrc, className, height, width } = props

  const onLoad = () => {
    setIsLoaded(true)
  }

  return (
    <>
      <img
        style={{
          display: isLoaded ? 'block' : 'none',
          animation: 'imageFadeIn 0.5s',
        }}
        src={imgSrc}
        onLoad={onLoad}
        className={className}
      />
      <Wrapper
        style={{ display: !isLoaded ? 'block' : 'none' }}
        height={height}
        width={width}
        className={className}
      >
        {<Skeleton.Image active={true} />}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  width: ${(props) => props.width ?? '100%'};
  .ant-skeleton {
    width: 100% !important;
    height: ${(props) => props.height ?? '200px'};
  }
  .ant-skeleton-image {
    width: auto;
    height: 100%;
  }
  .ant-skeleton-image::after {
    left: -200%;
    right: -200%;
  }
`
export default LazyLoadImage

import React from 'react'

import { PATH } from 'src/pages/additionalShampoo'
import RestrictLayout from 'src/components/application/RestrictLayout'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'

const MyPageRoute: IRouter[] = [
  {
    element: <RestrictLayout />,
    children: [
      {
        path: PATH.PURCHASE_ADDITIONAL_PATH,
        element: loadable(
          () => import('src/pages/additionalShampoo/AdditionalShampoo'),
        ),
      },
      {
        path: PATH.CONFIRM_DELIVERY_PATH,
        element: loadable(
          () => import('src/pages/additionalShampoo/ConfirmDelivery'),
        ),
      },
    ],
  },
]

export default MyPageRoute

import React from 'react'

import ConfirmDeliveryContainer from 'src/containers/Regular/ConfirmDelivery'

const ConfirmDeliveryPage: React.FC = () => {
  return <ConfirmDeliveryContainer />
}

export const PATH = '/regular/confirm-delivery' as const
export default ConfirmDeliveryPage

import React from 'react'

import MedicalExaminationContainer from 'src/containers/AutomaticPlan/MedicalExamination'

const MedicalExaminationPage: React.FC = () => {
  return <MedicalExaminationContainer />
}

export const PATH = '/automatic-plan/medical-examination' as const
export default MedicalExaminationPage

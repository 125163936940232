import { combineReducers } from '@reduxjs/toolkit'

import { reducer as doctorFreeTime } from './doctorFreeTime'
import { reducer as workingDate } from './workingDate'
import { reducer as videoAppointment } from './videoAppointment'

export default combineReducers({
  doctorFreeTime,
  workingDate,
  videoAppointment,
})

import { createAsyncThunk } from '@reduxjs/toolkit'

// Services
import channelService from 'src/api/requests/channel'
// Actions
import { actions } from 'src/store/domain/chat/channel'

export const getChannelRequest = createAsyncThunk(
  'channel/getChannelRequest',
  async (payload: { with: string }, { dispatch }) => {
    dispatch(actions.setIsFetchingChannel(true))
    const { data } = await channelService.getChannel(payload)
    dispatch(actions.getChannelListRequestSuccess(data))
    dispatch(actions.setIsFetchingChannel(false))
    return data
  },
)

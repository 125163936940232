import { PlanResponseInterface } from 'src/interfaces/plan'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  entity: PlanResponseInterface[] | null
}

const initialState: State = {
  loading: false,
  entity: null,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'plans',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<PlanResponseInterface[]>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

import { createAsyncThunk } from '@reduxjs/toolkit'

import feedbackDetailRequest from 'src/api/requests/feedback'
import feedbackDetailActions from 'src/store/domain/feedback/feedbackDetail'

export interface FeedbackDetailRequestInterface {
  feedback_id: number
  with: string
}

export const getFeedbackDetail = createAsyncThunk<
  any,
  FeedbackDetailRequestInterface
>('get_feedback_detail', async (params, { dispatch }) => {
  try {
    dispatch(feedbackDetailActions.updateLoading(true))
    const res = await feedbackDetailRequest.getFeedbackDetail(
      params.feedback_id,
      {
        with: params.with,
      },
    )
    if (res.data) {
      dispatch(feedbackDetailActions.updateEntity(res.data))
    }
    dispatch(feedbackDetailActions.updateLoading(false))
  } catch (error) {
    dispatch(feedbackDetailActions.updateLoading(false))
  }
})

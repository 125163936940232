import { createAsyncThunk } from '@reduxjs/toolkit'

import { AdditionalShampoo } from 'src/interfaces/additionalShampoo'
import purchaseActions from 'src/store/domain/additionalShampoo/purchase'

export const purchaseAdditionalShampoo = createAsyncThunk<
  any,
  AdditionalShampoo
>('purchase_additional_shampoo', async (data, { dispatch }) => {
  dispatch(purchaseActions.updateEntity(data))
})

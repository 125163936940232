import styled from 'styled-components'
import iconArrow from 'src/assets/images/landing/icon-arrow_001.svg'

export const Wrapper = styled.div`
  .landing-result {
    background: #f1efe7;

    p {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.05em;
      padding: 30px 0 0 0;
    }

    &__content {
      padding: 10px 15px 150px 15px;
    }

    .content {
      &__title {
        font-style: normal;
        font-weight: 700;
        line-height: 27px;
        color: #ffffff;
        background: #000000;
        margin-left: -15px;
        margin-right: -15px;
        padding: 15px;
      }

      .card {
        border: 2px solid #998800;
        border-radius: 10px;

        &__title {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 27px;
          text-align: center;
          letter-spacing: 0.13em;
          padding: 15px;
          color: #ffffff;
          background: #998800;
        }

        &__content {
          padding: 15px;
          background: #fff;
          border-radius: 0 0 10px 10px;

          img {
            margin: 30px auto;
            display: block;
          }

          p {
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 24px;
            color: #000000;
            margin-top: 15px;
          }
        }
      }

      .card__doctor {
        margin: 20px 0 0 0;
        padding: 15px 20px 40px 20px;
        background-color: #fff;
        position: relative;
        border-bottom: 2px solid #998800;

        &-name {
          font-weight: bold;
        }

        &-sub {
          font-size: 12px;
        }

        &-title {
          color: #998800;
          font-size: 12px;
        }

        &-img {
          position: absolute;
          top: -19%;
          right: 0;
          width: 163px;
          height: 163px;
        }

        &-content {
          &-name {
            padding: 5px 10px;
            color: #fff;
            font-weight: bold;
            position: absolute;
            bottom: -20px;
            left: 0;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
            width: 100%;

            &-x {
              background-color: #998800;
              padding: 5px 10px;
              width: 100%;
            }
          }
        }
      }

      .card__measures {
        background-color: #fff;
        padding: 20px 10px 10px;
        font-size: 14px;

        &-bold {
          font-weight: bold;
        }
        &-color {
          color: #998800;
          font-weight: bold;
        }
      }
    }

    &__plan {
      background-color: #fff;
      padding: 15px;
      margin-top: 15px;
      text-align: center;

      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }

      &-header {
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;
        background-color: #cfc392;
        padding: 10px;
        width: 100%;
        text-align: center;
      }

      &-treatment {
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
        text-align: left;
        margin: 10px 0;
      }

      .line-btn {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
        margin: auto;
      }
    }
    &__title {
      margin-top: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      margin-bottom: 20px;
    }
    &__explain {
      background-color: #fff;
      padding: 0 15px 15px;
      margin-top: 15px;

      &-header {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin-top: 15px;
      }

      &-content {
        margin: 15px 0 0 0;
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
      }

      &-sub {
        font-size: 13px;
        font-weight: 700;
        padding: 15px 0;
      }

      &-badge {
        border-left: 4px solid #998800;
        font-weight: 900;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.1em;
        padding-left: 5px;
        margin: 20px 0;
      }

      &-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-self: center;
        font-size: 23px;
        margin-top: -15px;

        .card__doctor-title {
          font-size: 15px !important;
        }
      }

      &-img {
        margin-left: 10px;
        width: 90px;
        height: 90px;
      }
    }
    &__line {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }

    &__plans {
      &-title {
        letter-spacing: 0.05em;
        border: 1px solid #000000;
        font-weight: 900;
        padding: 5px 0;
      }

      &-header {
        font-weight: 900;
        font-size: 20px;
        line-height: 22px;
        color: #998800;
      }

      &-des {
        font-weight: 600;
        font-size: 13px;
        line-height: 22px;
      }

      &-note {
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
      }

      &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        padding: 10px;

        div {
          text-align: left;
        }
      }

      &-right {
        width: 60%;
      }

      &-left {
        width: 40%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
      }
    }
  }
  .line-button {
    width: 100%;
    max-width: 350px;
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    background: transparent
      linear-gradient(180deg, var(--unnamed-color-00c300), #00c300 100%) 0% 0%
      no-repeat padding-box;
    background: ${() => `url(${iconArrow})`} right 8px center no-repeat,
      transparent linear-gradient(180deg, #00c300 0%, #00c300 100%) 0% 0%
        no-repeat padding-box;
    box-shadow: inset 0px 0px 10px #0000005c, 0px 3px 16px #00000033;
    border-radius: 80px;
    position: relative;
    animation: mymove 0.3s infinite alternate;
    transition: all mymove 0.3s;
    /* margin-top: 30px; */
    padding: 0 15px;
    margin: auto;

    &-div {
      margin-top: 20px;
      position: fixed;
      bottom: 0px;
      width: 100%;
      left: 0;
      padding: 10px 0;
      background: rgba(255, 255, 255, 0.8);
      margin: auto;
    }

    &-text {
      font-weight: 700;
      font-size: 19px;
      line-height: 22px;
      text-align: center;
      letter-spacing: 0.2em;
      color: #9a2f2f;
      margin-bottom: 10px;
      /* background: rgba(255, 255, 255, 0.8); */
    }
  }
  .position-set {
    position: static;
  }

  .fonts-16 {
    font-size: 16px;
  }

  .fonts-17 {
    font-size: 17px;
  }

  .landing__content-img {
    max-width: 100%;
    height: auto;
  }

  @keyframes mymove {
    0% {
      bottom: 0px;
    }
    100% {
      bottom: 7px;
    }
  }

  .btn-inline {
    border-radius: 10px !important;
    margin: auto;
    font-size: 12px !important;
  }
  .line-button-div-footer {
    padding: 0 10px;
  }
  .line-button-inline {
    margin: 0 5px;

    .line-button-text {
      font-size: 13px;
    }
    .btn-inline-web {
      background: #998800;
      background: ${() => `url(${iconArrow})`} #998800 right 8px center
        no-repeat;
    }
  }
  .cl-web {
    color: #998800;
  }
  .cl-line {
    color: #00c300;
  }
`

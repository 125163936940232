export const legalNotices = [
  {
    id: 1,
    title: '販売業者名',
    content: 'SheepMedical株式会社',
  },
  {
    id: 2,
    title: '販売責任者',
    content: '松本直純',
  },
  {
    id: 3,
    title: '所在地',
    content: '〒175-0045 東京都板橋区西台4-3-28',
  },
  {
    id: 4,
    title: 'メールアドレス',
    content: 'bioportplus@sheepmedical.com',
  },
  {
    id: 5,
    title: 'サイト名',
    content: 'BIOPORT+',
  },
  {
    id: 6,
    title: 'ホームページURL',
    link: 'https://bioportplus.jp/',
  },
  {
    id: 7,
    title: '販売価格',
    content: '各商品ページをご参照ください',
  },
  {
    id: 8,
    title: '販売数量',
    content: '各商品ページをご参照ください',
  },
  {
    id: 9,
    title: '商品代金以外の必要料金',
    content: '',
    items: [
      {
        id: 1,
        content: '・消費税(軽減税率8%、10%)',
      },
      {
        id: 2,
        content: '・送料:550円　クール便利用の場合は送料1100円',
      },
      {
        id: 3,
        content: '・決済手数料：無料',
      },
    ],
  },
  {
    id: 10,
    title: '代金の支払い方法',
    content: '下記のお支払い方法をご利用いただけます。',
    items: [
      {
        id: 1,
        content: '〇クレジットカード',
      },
      {
        id: 2,
        content:
          'Visa、Mastercard、American Express、JCB、Diners Club、Discoverがご利用可能です。',
      },
      {
        id: 3,
        content:
          '各クレジットカード会員規約に基づき引き落としさせていただきます。',
      },
    ],
  },
  {
    id: 11,
    title: '配送時期',
    content: '',
    items: [
      {
        id: 1,
        content: '原則、ご注文日翌日に発送致します。',
      },
      {
        id: 2,
        content: '※天候や交通状況により遅れが発生する場合がございます。',
      },
      {
        id: 3,
        content: 'あらかじめご了承くださいますようお願いいたします。',
      },
    ],
  },
  {
    id: 12,
    title: '定期コースの停止（ご解約）について',
    content: '',
    items: [
      {
        id: 1,
        content: '・お客様の状況により処方の月数は異なります。',
      },
      {
        id: 2,
        content:
          '・定期配送プランは、1ヶ月毎の配送を基本としており自動的に薬をお届けします。',
      },
      {
        id: 3,
        content:
          '・定期配送プランの解約・発送予定日変更・お届け内容変更をご希望の際は、次回発送予定日の12日前までお問合せフォームより手続きが可能です。',
      },
    ],
  },
  {
    id: 13,
    title: '返品・不良品・キャンセルについて',
    content:
      'BIOPORT＋は、法令に則ったオンラインカウンセリングサービスです。 通常の医療機関の受診と同様に診察・処方・調剤を行いますので、処方確定後のお薬及びその他商品の返品・交換・キャンセルは出来ません。 ただし、万が一処方された薬が届いた薬と違う場合、または破損している場合、直ちに交換いたします。その際の送料は当社負担です。',
  },
]

import React from 'react'
import LPHeader from 'src/components/layout/Header/LPHeader'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate } from 'react-router-dom'
import ShampooHeader from 'src/components/layout/Header/ShampooHeader'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import DescriptionHint from 'src/components/layout/Content/DescriptionHint'

export type propTypes = {
  path?: string
}

const StatusErrorSkipStepContainer: React.FC<propTypes> = (
  props: propTypes,
) => {
  const navigate = useNavigate()

  const handleMyPage = () => {
    navigate(props.path || '')
  }

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form not-padding-p">
        <ShampooHeader name="バノバギイレブン 運営事務局" className="top-70" />
        <HeaderStyle>申し訳ございません。</HeaderStyle>
        <DescriptionHint
          listText={[
            '画像・動画アップロードはまだ行っていません。',
            '<br />',
            '以下の「画像・動画アップロード」ボタンをタップしていただき、引き続きお手続きを継続してください。',
          ]}
        />

        <ButtonCustom
          className="btn-default btn-black mt-5 btn-submit"
          loading={false}
          onClick={handleMyPage}
          name="画像・動画アップロード"
        />
      </div>
    </>
  )
}

export default StatusErrorSkipStepContainer

import React from 'react'

import RegistrationCompleteContainer from 'src/containers/Auth/RegistrationComplete'

const RegistrationCompletePage: React.FC = () => {
  return <RegistrationCompleteContainer />
}

export const PATH = '/registration-complete' as const
export default RegistrationCompletePage

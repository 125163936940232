import React from 'react'

import PersonalInfoContainer from 'src/containers/Regular/PersonalInfo'

const PersonalInfoPage: React.FC = () => {
  return <PersonalInfoContainer />
}

export const PATH = '/regular/personal-info' as const
export default PersonalInfoPage

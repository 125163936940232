import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { Skeleton } from 'antd'

import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import DeliveryListCollapse from 'src/components/common/DeliveryListCollapse'
import { getDetailPaidOrder } from 'src/useCase/myPage'
import { useAppDispatch, useAppSelector } from 'src/store'
// import { PATH } from 'src/pages/myPage/PurchaseHistory'
import { skipOrderTracking } from 'src/useCase/orderTracking'
import { TrackingInterface } from 'src/interfaces/tracking'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  let { id } = useParams<{ id: string }>()
  const order = useAppSelector(
    (state) => state.domain.myPage.detailPaidOrder.entity,
  )
  const loading = useAppSelector(
    (state) => state.domain.myPage.detailPaidOrder.loading,
  )
  const handleSkipOrderTracking = () => {
    dispatch(
      skipOrderTracking({
        orders_tracking_id: currentOrderTracking?.id ?? 0,
        orders_id: currentOrderTracking?.order_id ?? 0,
      }),
    )
  }
  const [currentOrderTracking, setCurrentOrderTracking] =
    useState<null | TrackingInterface>(null)

  useEffect(() => {
    const params = { with: 'plan,tracking' }

    dispatch(getDetailPaidOrder({ id: String(id), params }))
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      <div className="delivery-schedule">
        <div className="delivery-schedule__header">
          <HeaderUnderlined
            name="配送スケジュール"
            backgroundColor="--color-spring-wood-2"
          />
        </div>
        {!!order && !loading ? (
          <>
            <div className="delivery-schedule__content">
              <div className="section">
                {/* {order.plan.cycle !== null ? (
                  <div className="sec-title">一年間スケジュール</div>
                ) : (
                  <div className="sec-title">スケジュール</div>
                )} */}

                {order.tracking.length === 0 ? (
                  <div className="pl-5 pr-5 pt-5 text-center">
                    データはありません
                  </div>
                ) : (
                  <DeliveryListCollapse
                    order={order}
                    currentOrderTracking={currentOrderTracking}
                    actionSubmit={handleSkipOrderTracking}
                    setCurrentOrderTracking={setCurrentOrderTracking}
                  />
                )}
              </div>
            </div>
            <div className="px-4 mx-2">
              <ButtonCustom
                className="btn-default btn-gurkha mt-5"
                loading={loading}
                onClick={() => navigate(-1)}
                htmlType="submit"
                showIconRight
                name="戻る"
              />
            </div>
          </>
        ) : (
          <Skeleton className="m-20" />
        )}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .delivery-schedule {
    &__header {
      background-color: var(--color-spring-wood-2);
      padding: 20px;
      padding-bottom: 0;
    }
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.13em;
      color: #000000;
    }

    &__link {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #1e4c82;
      margin-bottom: 15px;
      float: right;
    }

    &__submit {
      margin-bottom: 40px;
      width: 100%;
      padding: 19px;
      color: #000000;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #ffffff;
      border: 1px solid #000000;
    }

    .section {
      margin-top: 10px;

      .sec-title {
        background: #f1efe7;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        padding: 10px 20px;
      }
    }
  }
`

export default Container

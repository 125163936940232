import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import LPHeader from 'src/components/layout/Header/LPHeader'
import MenuHeader from 'src/components/layout/Header/MenuHeader'
import IconShampooSubscription from 'src/assets/images/members/shampoo_subscription.png'
import IconDecrease from 'src/assets/images/members/icon/decrease.png'
import IconIncrease from 'src/assets/images/members/icon/increase.png'
import { getPlans } from 'src/useCase/plan'
import { PLAN_SHAMPOO_ADDITIONAL_ID } from 'src/constants/order'
import ButtonCustom from 'src/components/form/Button'
import { useAppDispatch, useAppSelector } from 'src/store'
import { purchaseAdditionalShampoo } from 'src/useCase/additionalShampoo'
import { PATH } from 'src/pages/additionalShampoo'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [name, setName] = useState<string>('')
  const [id, setId] = useState<number>(0)
  const [price, setPrice] = useState<number>(0)
  const [quantity, setQuantity] = useState<number>(1)
  const [total, setTotal] = useState<number>(0)
  const plans = useAppSelector((state) => state.domain.plans.plans.entity)

  const decrease = () => {
    if (quantity > 1) {
      const newQuantity = quantity - 1
      setQuantity(newQuantity)
    }
  }
  const increase = () => {
    setQuantity(quantity + 1)
  }

  useEffect(() => {
    dispatch(getPlans({ page: 1, per_page: 100 }))
  }, [])

  useEffect(() => {
    setTotal(price * quantity)
  }, [price, quantity])

  useEffect(() => {
    if (plans && plans.length > 0) {
      const shampooAdditional = _.find(
        plans,
        (i) => i.id === PLAN_SHAMPOO_ADDITIONAL_ID,
      )
      if (shampooAdditional) {
        setName(shampooAdditional.name)
        setId(shampooAdditional.id)
        setPrice(parseInt(shampooAdditional.price_first))
      }
    }
  }, [plans])

  const submit = () => {
    const data = {
      name: name,
      planId: id,
      price: price,
      quantity: quantity,
    }

    dispatch(purchaseAdditionalShampoo(data))
    navigate(PATH.CONFIRM_DELIVERY_PATH)
  }

  return (
    <Wrapper>
      <LPHeader />
      <MenuHeader name="シャンプーの追加購入" />
      <div className="shampoo w-full justify-center mx-auto layout-webview">
        <div className="title mt-30 border-bottom-gray pb-20">
          シャンプーの単品購入が可能です。追加でシャンプー購入をご希望の方は下記にて数量を選択のうえ、ご購入お願いいたします。
        </div>
        <div className="shampoo__chosen">
          <p className="shampoo__chosen___name">{name}</p>
          <p className="shampoo__chosen___weight">２００g</p>
          <div className="shampoo-image d-flex justify-content-between">
            <img
              src={IconShampooSubscription}
              alt=""
              height="140"
              width="140"
            />
            <div className="shampoo-price">
              <div>
                <h3 className="price-item">
                  <b>{price.toLocaleString()}</b> 円
                </h3>
                <div className="d-flex justify-content-between">
                  <span className="shampoo-decrease add-btn" onClick={decrease}>
                    <img src={IconDecrease} alt="" />
                  </span>
                  <div className="quantity">{quantity}</div>
                  <span className="shampoo-increase add-btn" onClick={increase}>
                    <img src={IconIncrease} alt="" />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex amount">
          <div className="item text">
            <p>総額</p>
          </div>
          <div className="item price">
            <h3>
              <b>{total.toLocaleString()}</b> 円
            </h3>
          </div>
        </div>

        <ButtonCustom
          loading={false}
          name="購入手続きに進む"
          className="btn-default btn-black mt-30"
          onClick={submit}
        />

        <ButtonCustom
          className="btn-default btn-white mt-20"
          loading={false}
          onClick={() => navigate(PATH_MY_PAGE.OTHER_MENU)}
          htmlType="button"
          name="戻る"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .shampoo {
    &__title {
      font-family: 'Hiragino Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
    }

    &__content {
      padding-bottom: 20px;
      margin: 20px 0;
      font-size: 14px;
      border-bottom: 1px solid #cccccc;
    }

    &__chosen {
      padding: 0px 20px 20px 20px;
      margin: 20px 0;
      font-size: 14px;
      border-bottom: 1px solid #cccccc;
      .shampoo__chosen___name {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
      }
      .shampoo__chosen___weight {
        font-size: 15px;
        font-weight: 700;
        line-height: 16px;
      }
    }

    /* submit */
    &-submit {
      margin-top: 60px;
      width: 100%;
      padding: 19px;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #000000;
      margin-bottom: 55px;
    }

    &-image {
      padding-top: 10px;
    }

    &-price {
      display: flex;
      align-items: flex-end;
      margin-bottom: 20px;
      .price-item {
        font-size: 18px;
        text-align: right;
        margin-bottom: 20px;
      }
      .add-btn {
        width: 30px;
        height: 30px;
        background-color: transparent;
        background-image: none;
        border: none;
        img {
          max-width: 100%;
        }
      }
      .quantity {
        display: flex;
        align-items: center;
        width: 30px;
        height: 30px;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        justify-content: center;
      }
      p {
        font-family: 'Noto Sans', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 24.52px;
      }
    }
    .amount {
      justify-content: flex-end;
      .item {
        text-align: right;
      }
      .text {
        padding-top: 5px;
      }
      .text p {
        font-family: 'Noto Sans', sans-serif;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
      .price {
        padding-right: 20px;
        margin-left: 10px;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;

        h3 {
          color: #9a2f2f;
        }
      }
    }
  }
`

export default Container

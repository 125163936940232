import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  TemporaryOrderResponseInterface,
  PlanInterface,
  TemporaryOrderInterface,
} from 'src/interfaces/plan.d'
type State = {
  loading: boolean
  entity: TemporaryOrderResponseInterface
}

const initialState: State = {
  loading: false,
  entity: {
    plan: null as unknown as PlanInterface,
    temporaryOrder: [] as unknown as TemporaryOrderInterface[],
  },
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'plans',
  initialState,
  reducers: {
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateEntity: (
      state,
      action: PayloadAction<TemporaryOrderResponseInterface>,
    ) => {
      state.entity = action.payload
    },
  },
})

export { reducer }
export default actions

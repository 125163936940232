import React from 'react'

import Treatment from 'src/containers/MyPage/Treatment'

const TreatmentPage: React.FC = () => {
  return <Treatment />
}

export const PATH = '/my-page/treatments' as const
export default TreatmentPage

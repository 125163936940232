import notFoundErrorPage, { PATH as NOT_FOUND } from './404'
import forbiddenErrorPage, { PATH as FORBIDDEN } from './403'
import HTTPInternalErrorPage, { PATH as HTTP_INTERNAL } from './500'
import statusError, { PATH as STATUS_ERROR } from './StatusError'

export default {
  notFoundErrorPage,
  forbiddenErrorPage,
  HTTPInternalErrorPage,
  statusError,
}
export const PATH = {
  NOT_FOUND,
  FORBIDDEN,
  HTTP_INTERNAL,
  STATUS_ERROR,
}

import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import cs from 'classnames'
import _uniqueId from 'lodash/uniqueId'
import { useAppDispatch } from 'src/store'
import { useAppSelector } from 'src/store'

// Actions
import { createMessagesRequest } from 'src/useCase/message'
import { actions } from 'src/store/domain/chat/message'
// Selectors
import { selectChannel } from 'src/store/domain/chat/channel/selector'
import { selectIsSending } from 'src/store/domain/chat/message/selector'
// Constants
import { MESSAGE_TYPE, SENDER_BY } from 'src/containers/Chat/constants'
// Icons
import IconSentMess from 'src/assets/images/members/icon-sent-mess.svg'

const NewMessageForm = () => {
  const channel = useAppSelector(selectChannel)
  const isSending = useAppSelector(selectIsSending)
  const textareaInputDoctor: any = useRef(null)
  const [message, setMessage] = useState('')
  const dispatch = useAppDispatch()

  useEffect(() => {
    autoResizeTextarea()
  }, [message])

  const isAndroid = () => {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('android') > -1
  }

  const autoResizeTextarea = () => {
    textareaInputDoctor.current.style.height = '32px'
    textareaInputDoctor.current.style.height =
      textareaInputDoctor.current.scrollHeight + 'px'
  }

  const handleChange = (e) => {
    setMessage(e.target.value)
  }

  const sendMessage = () => {
    if (!message || isSending) return
    const temporaryMessage = {
      id: _uniqueId('temporary'),
      key: 'is_temporary',
      channelId: channel.id,
      is_temporary: true,
      type_message: MESSAGE_TYPE.TEXT,
      sender_by: SENDER_BY.USER,
      message_content: message,
    }
    const data = {
      channelId: channel.id,
      type: MESSAGE_TYPE.TEXT,
      message,
    }
    dispatch(actions.setIsSending(true))
    dispatch(actions.createMessage(temporaryMessage))
    dispatch(createMessagesRequest(data))
    setMessage('')
  }

  return (
    <Wrapper>
      <div
        className={cs({
          'new-message-form': true,
          'new-message-form__no-camera': isAndroid(),
        })}
      >
        <textarea
          className={cs({
            'new-message-form__message-input': true,
            'new-message-form__message-input__no-camera': isAndroid(),
          })}
          value={message}
          rows={1}
          ref={textareaInputDoctor}
          placeholder="メッセージ"
          onChange={handleChange}
          onBlur={autoResizeTextarea}
        />
        <button
          disabled={!message || isSending}
          className="new-message-form__send-message"
          onClick={sendMessage}
        >
          <img src={IconSentMess} alt="send-message" className="mx-auto" />
        </button>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .new-message-form {
    display: flex;
    background: #f1efe7;
    padding: 18px;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);

    &__no-camera {
      padding: 10px 1.5rem !important;
    }

    &__message-input {
      font-size: 12px;
      flex: 1;
      line-height: 18px;
      resize: none;
      padding: 5px 10px;
      margin-right: 20px;
      border: 1px solid #998800;
      border-radius: 4px;
      max-height: 100px;

      &::-webkit-scrollbar {
        opacity: 0;
      }

      &__no-camera {
        width: 70% !important;
      }
    }

    &__send-message {
      font-size: 14px;
      color: white;
      height: 30px;
      border-radius: 50%;
      padding: 0;
      cursor: pointer;
      border: 0 solid #e2e8f0;
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
`

export default NewMessageForm

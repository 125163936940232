import React from 'react'

import DeliveryDestinationInfo from 'src/containers/MyPage/DeliveryDestinationInfo'

const DeliveryDestinationInfoPage: React.FC = () => {
  return <DeliveryDestinationInfo />
}

export const PATH = '/my-page/delivery-destination-info' as const
export default DeliveryDestinationInfoPage

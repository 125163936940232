import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  entity: number
}

const initialState: State = {
  loading: false,
  entity: 0,
}

const getUnreadNotice = (number: number) => (number > 0 ? number : 0)

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'count_unread_notification',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<number>) => {
      state.entity = getUnreadNotice(action.payload)
    },
    decrementEntity: (state, action: PayloadAction<number>) => {
      state.entity = getUnreadNotice(state.entity - action.payload)
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

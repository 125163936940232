import React from 'react'

import AgaContainer from 'src/containers/Aga/Question'

const RateAnswers: React.FC = () => {
  return <AgaContainer />
}

export const PATH = '/aga' as const
export default RateAnswers

import React from 'react'

import DeliverySchedule from 'src/containers/MyPage/DeliverySchedule'

const DeliverySchedulePage: React.FC = () => {
  return <DeliverySchedule />
}

export const PATH = '/my-page/delivery-schedule/:id' as const
export default DeliverySchedulePage

import React from 'react'

import PolicyContainer from 'src/containers/Policy'

const PolicyPage: React.FC = () => {
  return <PolicyContainer />
}

export const PATH = '/policy' as const
export default PolicyPage

import { MessageITF } from 'src/store/domain/chat/message/interface'
import HTTPRequest from '../index'

const apiPathMessage = 'api/users/messages'
class Message extends HTTPRequest {
  async getMessages(params: {
    id: string | number
    per_page: number
    page: number
    order: string
  }) {
    const result = await this.get<{ data: MessageITF[]; last_page: number }>(
      `${apiPathMessage}/${params.id}`,
      { params, headers: { 'skip-loading': true } },
    )
    return result.data
  }

  async createMessage(params) {
    const result = await this.post(
      `${apiPathMessage}/${params.channelId}`,
      params,
      {
        headers: {
          timezone: 'UTC+09:00',
          'skip-loading': true,
        },
      },
    )
    return result.data
  }

  async resetUnread(params: { channelId: string | number }) {
    const result = await this.get(
      `${apiPathMessage}/${params.channelId}/reset-unread`,
      { headers: { 'skip-loading': true } },
    )
    return result.data
  }
}

export default new Message()

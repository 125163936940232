import React from 'react'

import PrecautionContainer from 'src/containers/AutomaticPlan/Precaution'

const PrecautionPage: React.FC = () => {
  return <PrecautionContainer />
}

export const PATH = '/automatic-plan/precaution' as const
export default PrecautionPage

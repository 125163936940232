import { createAsyncThunk } from '@reduxjs/toolkit'

import orders from 'src/store/domain/orders/list'
import orderApi from 'src/api/requests/orders'

export type PayloadIndexOrdersType = {
  with?: string
  status?: string
}

export const getOrdersList = createAsyncThunk<any, PayloadIndexOrdersType>(
  'get_orders_list',
  async (params, { dispatch }) => {
    try {
      dispatch(orders.updateLoading(true))
      const res = await orderApi.index(params)

      dispatch(orders.updateEntities(res.data.data))
      dispatch(orders.updateLoading(false))
    } catch (error) {
      dispatch(orders.updateLoading(false))
    }
  },
)

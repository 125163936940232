import React from 'react'

import { PATH } from 'src/pages/error'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import GuestLayout from 'src/routes/components/GuestLayout'

const ErrorRoute: IRouter[] = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATH.NOT_FOUND,
        element: loadable(() => import('src/pages/error/404')),
      },
      {
        path: PATH.HTTP_INTERNAL,
        element: loadable(() => import('src/pages/error/500')),
      },
      {
        path: PATH.FORBIDDEN,
        element: loadable(() => import('src/pages/error/403')),
      },
      {
        path: PATH.STATUS_ERROR,
        element: loadable(() => import('src/pages/error/StatusError')),
      },
    ],
  },
]

export default ErrorRoute

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { State, MessageITF } from './interface'

const initialState: State = {
  isFetchingMessage: false,
  isSending: false,
  scrollToBottom: false,
  messages: [],
  countMessageRead: 0,
  lastPage: null,
}

const { actions, reducer } = createSlice({
  name: 'message',
  initialState,
  reducers: {
    getMessagesRequestSuccess(
      state,
      action: PayloadAction<{ data: MessageITF[]; lastPage: number }>,
    ) {
      state.messages = [...state.messages, ...action.payload.data]
      state.lastPage = action.payload.lastPage
      state.scrollToBottom = true
    },
    createMessage(state, action: PayloadAction<MessageITF>) {
      state.messages.unshift(action.payload)
      state.scrollToBottom = true
    },
    updateMessage(state, action: PayloadAction<MessageITF>) {
      const { key } = action.payload
      state.messages = state.messages.map((x) => {
        if (key === 'is_temporary' && x[key]) {
          return {
            ...x,
            ...action.payload,
          }
        }
        if (x.id === action.payload.id) {
          return {
            ...x,
            ...action.payload,
          }
        }
        return x
      })
    },
    deleteMessage(state, action: PayloadAction<{ id: string | number }>) {
      state.messages = state.messages.filter((x) => x.id !== action.payload.id)
    },
    setFetchingMessage(state, action: PayloadAction<boolean>) {
      state.isFetchingMessage = action.payload
    },
    setIsSending(state, action: PayloadAction<boolean>) {
      state.isSending = action.payload
    },
    setCountMessageRead(state, action: PayloadAction<number>) {
      state.countMessageRead = action.payload
    },
    resetMessageStore() {
      return initialState
    },
  },
})

export { reducer, actions }
export default reducer

import React from 'react'

import { PATH } from 'src/pages/chat'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'

const AuthRoute: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.CHAT,
        element: loadable(() => import('src/pages/chat/Chat')),
      },
    ],
  },
]

export default AuthRoute

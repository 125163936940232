import HTTPRequest from '../index'
import { FormValues } from 'src/containers/Aga/useAga'

const apiPath = 'api/users/aga'

class Aga extends HTTPRequest {
  rateAnswers(value: FormValues) {
    return this.post<Response.Aga>(`${apiPath}/rate-answers`, { ...value })
  }

  getDiagnosisQuestions(params) {
    return this.get(`${apiPath}/questions`, {
      params,
    })
  }
}

export default new Aga()

import React, { useRef, useState } from 'react'
import { Form } from 'antd'
import styled from 'styled-components'

import { FILE_SIZE, useUploadImage } from './useUploadImage'
import ButtonCustom from 'src/components/form/Button'
import { useAppDispatch } from 'src/store'
import uiActions from 'src/store/ui/notification'

import head1 from 'src/assets/images/common/head_1.svg'
import head2 from 'src/assets/images/common/head_2.svg'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()

  const inputElFront = useRef<HTMLInputElement>(null)
  const [selectedFileFront, setSelectedFileFront] = useState<string>('')
  const formEl = useRef<any>(null)
  const { form, onSubmit } = useUploadImage()
  const { handleSubmit, setValue, formState } = form

  return (
    <Wrapper>
      <Form ref={formEl} onFinish={handleSubmit(onSubmit)}>
        <input
          accept="image/jpeg,image/png,image/jpg"
          type="file"
          ref={inputElFront}
          style={{ display: 'none' }}
          onChange={(event) => {
            if (event.target.files && event.target.files[0].size > FILE_SIZE) {
              dispatch(
                uiActions.onNotification({
                  type: 'error',
                  message: '6MB以下の画像をアップロードしてください。',
                }),
              )
              return
            }
            if (!event.target.files) return
            setSelectedFileFront(URL.createObjectURL(event.target.files[0]))
            setValue('image', event.target.files[0])
          }}
        />
        <div className="upload-card-input">
          <p className="upload-card-input__text-header">
            薄毛・抜け毛が進行している頭部だけ写ってる画像で問題ございません
          </p>
          <div>
            {selectedFileFront ? (
              <div className="image_upload d-flex justify-content-center">
                <img
                  style={{ maxWidth: '100%' }}
                  src={selectedFileFront}
                  alt=""
                />
              </div>
            ) : (
              <>
                <div className="upload-card-input__image-demo">
                  <div className="upload-card-input__image-demo__col d-flex">
                    <img src={head1} />
                  </div>
                  <div className="upload-card-input__image-demo__col d-flex">
                    <img src={head2} />
                  </div>
                </div>
              </>
            )}
            <div className="update-image">
              <p
                className="upload-new"
                onClick={() => {
                  inputElFront.current?.click()
                }}
              >
                画像を選択する
              </p>
            </div>
          </div>
        </div>
        <ButtonCustom
          loading={formState.isSubmitting}
          htmlType="submit"
          className="btn-default btn-black fs-20 mt-10 mb-30 w-100"
          name="アップロード"
          disabled={!selectedFileFront}
        />
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default Container

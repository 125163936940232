import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { Skeleton } from 'antd'

import LPHeader from 'src/components/layout/Header/LPHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import iconRight from 'src/assets/icons/right_white.svg'
import medicalInterview from 'src/assets/images/members/mypage/medical_interview.png'
import { PATH } from 'src/pages/myPage/OtherMenu'
import { formatNumPrice } from 'src/utils/helper'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import { getOrdersList } from 'src/useCase/order'
import { getPaidOrders } from 'src/useCase/myPage'
import useQueryUrl from 'src/hook/useQueryUrl'
import LazyLoadImage from 'src/components/common/LazyLoadImage'
import ButtonFixedBottom from 'src/components/common/ButtonFixedBottom'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { query } = useQueryUrl()
  const loading = useAppSelector(
    (state) => state.domain.myPage.paidOrder.loading,
  )
  const isOrdersCancelOrComplete = query.status === 'cancelled_or_complete'
  const makeOrderList = () => {
    if (isOrdersCancelOrComplete) {
      return useAppSelector((state) => state.domain.orders.ordersList.entities)
    }

    return useAppSelector((state) => state.domain.myPage.paidOrder.entities)
  }
  const ordersList = makeOrderList()

  useEffect(() => {
    const params = { with: 'plan, tracking, userTreatment.menu' }
    if (isOrdersCancelOrComplete) {
      params['status'] = query.status
      dispatch(getOrdersList(params))
      return
    }

    dispatch(getPaidOrders({ params: params }))
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      <div className="container">
        <HeaderUnderlined
          name={isOrdersCancelOrComplete ? '契約履歴' : 'ご契約中のプラン'}
          backgroundColor="--color-spring-wood-2"
        />
        {loading ? (
          <Skeleton className="m-10" />
        ) : (
          <div className="mypage">
            <div className="content">
              {ordersList?.length === 0 && (
                <p className="margin-b-15px mypage__title-empty">
                  購入商品(定期、シャンプー）がありません
                </p>
              )}

              {ordersList?.length !== 0 &&
                ordersList?.map((paidOrder, index) => {
                  return (
                    <div key={index}>
                      <div className="bg-white p-10">
                        {/* treatment name */}
                        <div className="treatment-name">
                          {paidOrder.userTreatment?.menu?.name
                            ? `診療科目 : ${paidOrder.userTreatment?.menu?.name}`
                            : ''}
                        </div>
                        <div className="d-flex">
                          <div className="w-33">
                            <LazyLoadImage
                              imgSrc={paidOrder.plan?.image_url}
                              className="mw-100"
                              height="110px"
                            />
                          </div>
                          <div className="w-67 d-flex justify-content-between flex-column pl-5">
                            <span className="section-title">
                              <p>{paidOrder?.plan?.name}</p>
                            </span>
                            <div className="section-price">
                              <p className="fz-13">
                                {!paidOrder.plan?.cycle
                                  ? `数量：${paidOrder.quantity}`
                                  : ''}
                              </p>

                              {paidOrder.tracking &&
                              paidOrder.tracking.length > 1 &&
                              _.get(paidOrder, 'plan.price_first', 0) !=
                                _.get(paidOrder, 'plan.price_after', 0) ? (
                                <div>
                                  <div style={{ textAlign: 'end' }}>
                                    <span className="fs-11 cl-crimson fw-bold pr-5">
                                      初回
                                    </span>
                                    <span>
                                      {formatNumPrice(
                                        parseInt(
                                          _.get(
                                            paidOrder,
                                            'plan.price_first',
                                            '',
                                          ),
                                        ),
                                      ).toLocaleString()}
                                      円
                                    </span>
                                    <span className="fz-13 cl-black">
                                      (税込)
                                      {paidOrder.plan?.cycle
                                        ? `/${paidOrder.plan?.cycle}日`
                                        : ''}
                                    </span>
                                  </div>
                                  <div style={{ textAlign: 'end' }}>
                                    <span>
                                      {formatNumPrice(
                                        parseInt(
                                          _.get(
                                            paidOrder,
                                            'plan.price_after',
                                            '',
                                          ),
                                        ),
                                      ).toLocaleString()}
                                      円
                                    </span>
                                    <span className="fz-13 cl-black">
                                      (税込)
                                      {paidOrder.plan?.cycle
                                        ? `/${paidOrder.plan?.cycle}日`
                                        : ''}
                                    </span>
                                  </div>
                                </div>
                              ) : (
                                <div style={{ textAlign: 'end' }}>
                                  <span>
                                    {parseInt(
                                      _.get(paidOrder, 'plan.price_first', ''),
                                    ).toLocaleString()}
                                    円
                                  </span>
                                  <span className="fz-13 cl-black">
                                    (税込)
                                    {paidOrder.plan?.cycle
                                      ? `/${paidOrder.plan?.cycle}日`
                                      : ''}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between section-bottom">
                        <span>配送情報を見る</span>
                        <img width="8px" height="22px" src={iconRight} alt="" />
                      </div>
                    </div>
                  )
                })}
            </div>

            {/* {menus.map((item, index) => {
              return (
                <div className="menu-item pl-4" key={index}>
                  <Link
                    to={item.link}
                    className="d-flex justify-content-between"
                  >
                    <div className="flex-auto fw-bold d-flex cl-black">
                      {item.name}
                    </div>
                    <div className="flex-auto d-flex items-center justify-end">
                      <img width="8px" height="13px" src={iconRight} />
                    </div>
                  </Link>
                </div>
              )
            })} */}

            {ordersList?.length === 0 && (
              <div className="p-20 medical_interview">
                <img className="mw-100" src={medicalInterview} alt="" />
                <div className="w-100 p-5">
                  <button
                    type="button"
                    className="p-4 border-none fw-bold btn btn-primary btn-new-warning"
                  >
                    薬の処方を希望する
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <ButtonFixedBottom
        className="btn-default btn-gurkha"
        loading={false}
        onClick={() => navigate(PATH)}
        name="戻る"
        showIconRight
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100vh;
  background-color: var(--color-spring-wood-2);
  & .container {
    padding: 20px;
    padding: 20px;
    padding-bottom: 150px;
  }

  .mypage {
    .content {
      padding-top: 20px;
    }
    .section-title,
    .treatment-name {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      color: var(--color-rangoon-green);
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .section-price {
      display: flex;
      justify-content: space-between;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.13em;
      color: #000000;
    }

    .section-bottom {
      background-color: var(--color-gurkha);
      padding: 10px 15px 10px 20px;
      color: #ffffff;
      margin-bottom: 15px;
    }

    .fz-13 {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
`

export default Container

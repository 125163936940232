import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Col, Form, Row } from 'antd'
import Picker from 'react-mobile-picker'
import moment from 'moment'

import { useRegister } from './useRegisterForm'
import { InputField } from 'src/components/form'
import { UserRequestInterface } from 'src/interfaces/user'
import {
  InputLabel,
  LabelRequire,
} from 'src/components/layout/Header/HeaderStyle'
import GenderModal from 'src/components/Modal/GenderModal'
import BirthDateModal from 'src/components/Modal/BirthDateModal'
import BirthDateAGenderModal from 'src/components/Modal/BirthDateAGenderModal'
import ButtonCustom from 'src/components/form/Button'
import { years, months } from 'src/utils/helper'
import { PLAN_TYPE } from 'src/constants/app'

import { PATH as REGULAR_PATH } from 'src/pages/regular'
import { PATH as PATH_AUTH } from 'src/pages/auth'

import femaleIcon from 'src/assets/icons/female.svg'
import maleIcon from 'src/assets/icons/male.svg'
import { USER_GENDER } from 'src/constants/user'

type Props = {
  flowName?: string | null
}

const UserInfoForm = ({ flowName }: Props) => {
  const { form, onSubmit } = useRegister()
  const { control, handleSubmit, formState } = form
  const [selectGender, setSelectGender] = useState(true)

  const [birthDatePopup, setBirthDatePopup] = useState<boolean>(false)
  const [genderPopup, setGenderPopup] = useState<boolean>(false)
  const [birthDateAGenderPopup, setBirthDateAGenderPopup] =
    useState<boolean>(false)
  const [isShowDatePicker, setIsShowDatePicker] = useState(false)
  const [birthDay, setBirthDay] = useState('1980-01-01')
  const [errorMessage, setErrorMessage] = useState('')

  const isShampooSubscription = flowName === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION
  const redirectPath = isShampooSubscription
    ? REGULAR_PATH.CONFIRM_DELIVERY
    : PATH_AUTH.MEDICAL_SPECIALTY

  const renderDays = (
    currentYear: string = '1980年',
    currentMonth: string = '1月',
  ) => {
    const lastDay = new Date(
      parseInt(currentYear),
      parseInt(currentMonth),
      0,
    ).getDate()

    return Array.from({ length: lastDay }, (_, index) => {
      return `${index + 1}日`
    })
  }

  const [valueGroups, setValueGroups] = useState({
    year: '1980年',
    month: '1月',
    day: '1日',
  })
  const [optionGroups, setOptionGroups] = useState({
    year: years(),
    month: months(),
    day: renderDays(valueGroups.year, valueGroups.month),
  })

  useEffect(() => {
    setOptionGroups({
      ...optionGroups,
      day: renderDays(valueGroups.year, valueGroups.month),
    })
  }, [valueGroups])

  const handleError = (
    data: UserRequestInterface,
    redirectPath: string,
    currentFlow: string | null = null,
  ) => {
    onSubmit(data, redirectPath, currentFlow)
  }

  const handleChangeDate = (name, value) => {
    setValueGroups({
      ...valueGroups,
      [name]: value,
    })
  }

  const showDatePicker = () => {
    setIsShowDatePicker(true)
    document.body.classList.add('stop-scroll')
  }

  const cancelDatePicker = () => {
    document.body.classList.remove('stop-scroll')
    setIsShowDatePicker(false)
  }

  const confirmBirthday = () => {
    document.body.classList.remove('stop-scroll')
    setIsShowDatePicker(false)
    const birthdayFormat = moment({
      year: parseInt(valueGroups.year),
      month: parseInt(valueGroups.month) - 1,
      day: parseInt(valueGroups.day),
    }).format('YYYY-MM-DD')
    setBirthDay(birthdayFormat)
    form.setValue('date_of_birth', birthdayFormat)

    // Validate age
    const birthday = moment({
      year: parseInt(valueGroups.year),
      month: parseInt(valueGroups.month) - 1,
      day: parseInt(valueGroups.day),
    })
    const age = moment().diff(birthday, 'years')

    if (age < 18) {
      setErrorMessage(
        'オンラインカウンセリングのお申し込みは18歳以上の方でないとお受け付けできません｡',
      )
    } else {
      setErrorMessage('')
      form.setValue('date_of_birth', birthdayFormat)
    }
  }

  const setGender = (gender) => {
    setSelectGender(gender === USER_GENDER.MALE)
    form.setValue('gender_id', gender)
  }

  return (
    <Fragment>
      <StyleForm>
        <Row className="justify-content-between">
          <Col xs={11}>
            <InputLabel className="font-weight-bold">姓</InputLabel>
            <InputField<UserRequestInterface>
              controller={{
                control,
                name: 'family_name',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
          <Col xs={11} className="ml-10">
            <Row className="justify-content-between">
              <InputLabel className="font-weight-bold">名</InputLabel>
              <LabelRequire>
                <span>必須</span>
              </LabelRequire>
            </Row>
            <InputField<UserRequestInterface>
              controller={{
                control,
                name: 'given_name',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
        </Row>
        <Row className="justify-content-between">
          <Col xs={11}>
            <InputLabel className="font-weight-bold">姓(カナ)</InputLabel>
            <InputField<UserRequestInterface>
              controller={{
                control,
                name: 'family_name_kana',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
          <Col xs={11} className="ml-10">
            <Row className="justify-content-between">
              <InputLabel className="font-weight-bold">名(カナ)</InputLabel>
              <LabelRequire>
                <span>必須</span>
              </LabelRequire>
            </Row>
            <InputField<UserRequestInterface>
              controller={{
                control,
                name: 'given_name_kana',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Row className="justify-content-between">
              <InputLabel className="font-weight-bold">生年月日</InputLabel>
              <LabelRequire>
                <span>必須</span>
              </LabelRequire>
            </Row>

            <div
              className="ant-form-item input-birthday"
              onClick={showDatePicker}
            >
              <p className="ant-input aga-input">{birthDay}</p>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
            </div>
            {isShowDatePicker && (
              <div className="picker-group">
                <div className="picker-group__item">
                  <div className="d-flex justify-content-space-between birth-action">
                    <b onClick={cancelDatePicker} className="btn-gray">
                      キャンセル
                    </b>
                    <b className="btn-warning" onClick={confirmBirthday}>
                      OK
                    </b>
                  </div>
                  <div className="d-flex">
                    <Picker
                      optionGroups={optionGroups}
                      valueGroups={valueGroups}
                      onChange={handleChangeDate}
                      wheel="normal"
                    />
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Row className="justify-content-between">
              <InputLabel className="font-weight-bold">性別</InputLabel>
              <LabelRequire>
                <span>必須</span>
              </LabelRequire>
            </Row>
            <Row className="justify-content-between">
              <Col xs={11}>
                <GenderItem
                  onClick={() => setGender(USER_GENDER.MALE)}
                  className={selectGender ? `selected` : ''}
                >
                  <GenderImg src={maleIcon} alt="" />
                  <GenderHint>男性</GenderHint>
                </GenderItem>
              </Col>
              <Col xs={11} className="ml-10">
                <GenderItem
                  onClick={() => setGender(USER_GENDER.FEMALE)}
                  className={!selectGender ? `selected` : ''}
                >
                  <GenderImg src={femaleIcon} alt="" />
                  <GenderHint>女性</GenderHint>
                </GenderItem>
              </Col>
            </Row>
          </Col>
          <Col xs={24} className="mt-20">
            <Row className="justify-content-between">
              <InputLabel className="font-weight-bold">
                メールアドレス
              </InputLabel>
              <LabelRequire>
                <span>必須</span>
              </LabelRequire>
            </Row>
            <InputField<UserRequestInterface>
              controller={{
                control,
                name: 'email',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'email',
              }}
            />
          </Col>
        </Row>
        <ButtonCustom
          className="btn-default btn-chestnut-rose br-60"
          loading={formState.isSubmitting}
          onClick={handleSubmit(async (data: UserRequestInterface) => {
            handleError(data, redirectPath)
          })}
          htmlType="submit"
          name={isShampooSubscription ? '次へ' : '次へ進む'}
          showIconRight
        />
      </StyleForm>

      <BirthDateModal isOpen={birthDatePopup} onSubmit={setBirthDatePopup} />
      <GenderModal isOpen={genderPopup} onSubmit={setGenderPopup} />
      <BirthDateAGenderModal
        isOpen={birthDateAGenderPopup}
        onSubmit={setBirthDateAGenderPopup}
      />
    </Fragment>
  )
}

const StyleForm = styled(Form)`
  .input-birthday {
    .aga-input {
      font-size: 16px;
    }
  }

  .picker-group {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100vh;
    background: #000000a8;
    z-index: 10000000;

    &__item {
      position: absolute;
      width: 100%;
      background-color: #fff;
      bottom: 0;
      left: 0;
      font-size: 16px;
      border-radius: 10px 10px 0 0;

      .birth-action {
        background-color: #ffffff;
        padding: 15px;
        border-bottom: 1px solid #998800;
        border-radius: 10px 10px 0 0;
        justify-content: space-between;

        .btn-warning {
          color: var(--color-dusty-gray);
          background-color: var(--color-white);
          cursor: pointer;
        }

        .btn-gray {
          color: #999999;
          cursor: pointer;
        }
      }
    }
  }

  .picker-date {
    background-color: #f1efe7;
    border: 1px solid #cfc392;
    padding: 10px;
  }

  .picker-inner {
    -webkit-mask-box-image-source: none !important;
  }

  .picker-container .picker-column .picker-item.picker-item-selected {
    font-weight: bold;
  }

  .error-message {
    color: #ff4d4f;
    line-height: 20px;
    overflow: visible;
    white-space: break-spaces;
    font-size: 12px;
  }
`

const GenderItem = styled.div`
  display: flex;
  border: none;
  padding: 10px;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--color-pampas);

  &.selected {
    border-color: #980;
    background-color: #f1efe7;
    border: unset;
  }
`
const GenderHint = styled.p`
  height: 40px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 13px;
  margin-left: 10px;
`
const GenderImg = styled.img`
  width: 40px;
  height: 40px;
`

export default UserInfoForm

import React, { useEffect } from 'react'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate } from 'react-router-dom'
import { PATH as REGULAR_PATH } from 'src/pages/regular'
import DescriptionHint from 'src/components/layout/Content/DescriptionHint'
import { useAppDispatch, useAppSelector } from 'src/store'
import LodashGet from 'lodash/get'
import { getDetailUserTreatment } from 'src/useCase/userTreatment'
import LocalStorage from 'src/utils/LocalStorage'
import { getFirstDoctor } from 'src/useCase/doctor'

const SuggestSubscriptionContainer: React.FC = (props: any) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)

  const next = () => {
    navigate(REGULAR_PATH.REGULAR_SUBSCRIPTION)
  }

  const userTreatment = useAppSelector(
    (state) => state.domain.myPage.detailUserTreatment.entity,
  )

  useEffect(() => {
    const id = LocalStorage.getUserTreatmentId()
    const params = {
      with: 'lastDoctorFeedback.doctor',
    }
    dispatch(getDetailUserTreatment({ id, params }))
  }, [])

  useEffect(() => {
    if (
      userTreatment &&
      userTreatment.id &&
      !userTreatment?.last_doctor_feedback
    ) {
      dispatch(getFirstDoctor())
    }
  }, [userTreatment])

  const firstDoctor = useAppSelector(
    (state) => state.domain.doctor.firstDoctor.entity,
  )

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form">
        <StepsHeader
          step={10}
          stepActive={4}
          stepActiveName={'処方のご提案'}
          className="top-70"
        />
        <HeaderStyle>お薬なし定期購入のご提案</HeaderStyle>

        <DescriptionHint
          listText={[
            `${LodashGet(user, 'clinics[0].name', '')} 担当医師の${LodashGet(
              userTreatment,
              'last_doctor_feedback.doctor.name',
              firstDoctor?.name,
            )}です。`,
            '<br />',
            ' 問診のご協力ありがとうございました。 ',
            '大変申し訳ありませんが、診察の結果お薬の処方ができないため、医師の診察が不要の定期購入プランのみのご提案となります。',
            '<br />',
            '定期購入プランをご希望の場合は下記のボタンより定期便のプランをご確認ください。',
          ]}
        />
        <ButtonCustom
          className="btn-default btn-chestnut-rose mt-5"
          loading={false}
          onClick={next}
          htmlType="submit"
          name="定期購入プランへ"
          showIconRight
        />
      </div>
    </>
  )
}

export default SuggestSubscriptionContainer

import React from 'react'

import { PATH } from 'src/pages/auth'
import RestrictLayout from 'src/routes/components/RestrictLayout'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'

const AuthRoute: IRouter[] = [
  {
    element: <RestrictLayout />,
    children: [
      {
        path: PATH.SIGN_IN,
        element: loadable(() => import('src/pages/auth/SignIn')),
      },
      {
        path: PATH.REGISTER,
        element: loadable(() => import('src/pages/auth/Register')),
      },
      {
        path: PATH.SEND_MAIL_RESET_PASSWORD,
        element: loadable(() => import('src/pages/auth/SendMailResetPassword')),
      },
      {
        path: PATH.RESET_PASSWORD_PATH,
        element: loadable(() => import('src/pages/auth/ResetPassword')),
      },
      {
        path: PATH.COMPLETE_PAGE,
        element: loadable(() => import('src/pages/auth/ResetPasswordComplete')),
      },
    ],
  },
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.LINK_LINE,
        element: loadable(() => import('src/pages/auth/LinkLine')),
      },
      {
        path: PATH.REGISTRATION_COMPLETE,
        element: loadable(() => import('src/pages/auth/RegistrationComplete')),
      },
      {
        path: PATH.MEDICAL_SPECIALTY,
        element: loadable(() => import('src/pages/auth/MedicalSpecialty')),
      },
      {
        path: PATH.REGISTERED,
        element: loadable(() => import('src/pages/auth/Registered')),
      },
    ],
  },
]

export default AuthRoute

import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { sendMailResetPassword } from 'src/useCase/auth'
import { useAppDispatch } from 'src/store'

export type FormValues = {
  email?: string
}

export const useSendMailResetPassword = () => {
  const dispatch = useAppDispatch()

  const defaultValues: FormValues = {
    email: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('メールアドレスを正しく入力してください')
      .required('入力してください。'),
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await dispatch(
      sendMailResetPassword({ mailResetPassType: data, form: form }),
    )
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

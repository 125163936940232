import { createAsyncThunk } from '@reduxjs/toolkit'
import history from 'src/libs/history'
import LocalStorage from 'src/utils/LocalStorage'
import uiActions from 'src/store/ui/notification'
import appActions from 'src/store/ui/app'

export const authorization = createAsyncThunk(
  'authorization',
  (_, { dispatch }) => {
    if (history.location.pathname !== '/login') {
      LocalStorage.removeInfo()
      history.push('/login')

      dispatch(appActions.updateAuthInfo(null))

      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: 'Unauthorized',
        }),
      )
    }

    return false
  },
)

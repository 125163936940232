import React from 'react'

import SignInContainer from 'src/containers/Auth/SignIn'

const SignInPage: React.FC = () => {
  return <SignInContainer />
}

export const PATH = '/login' as const
export default SignInPage

import React, { useEffect, useState } from 'react'
import { find } from 'lodash'
import { Skeleton } from 'antd'

import LPHeader from 'src/components/layout/Header/LPHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import { getPlans } from 'src/useCase/plan'
import LocalStorage from 'src/utils/LocalStorage'
import { getInfo } from 'src/useCase/user'
import PlanInfoComponent from 'src/containers/AutomaticPlan/components/Plan/PlanInfo'
import { PlanInterface } from 'src/interfaces/plan'
import { ProposalDiv } from 'src/containers/AutomaticPlan/Proposal'
import { PLAN_TYPE } from 'src/constants/app'

const ShampooSubscriptionContainer: React.FC = (props: any) => {
  const dispatch = useAppDispatch()

  const plans = useAppSelector((state) => state.domain.plans.plans.entity)
  const plansLoading = useAppSelector(
    (state) => state.domain.plans.plans.loading,
  )
  const accessToken = LocalStorage.accessToken
  const user = useAppSelector((state) => state.ui.app.authInfo)

  useEffect(() => {
    if ((!user || (user && !user.state_transitions)) && accessToken) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
    }
  }, [user])
  // const orderSubscription = () => {
  //   if (firstPlan?.id) {
  //     LocalStorage.setActivePlan(firstPlan?.id)
  //     LocalStorage.setPlanType(PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION)

  //     if (accessToken) {
  //       // const isVerifyOTP =
  //       //   (user &&
  //       //     some(
  //       //       user?.state_transitions,
  //       //       (i) => i.state === USER_STATE.OTP_VERIFIED,
  //       //     )) ||
  //       //   false
  //       // if (isVerifyOTP) {

  //       // } else {
  //       //   navigate(REGULAR_PATH.CONFIRM_DELIVERY)
  //       // }
  //       navigate(REGULAR_PATH.CONFIRM_DELIVERY)
  //     } else {
  //       navigate(REGULAR_PATH.PERSONAL_INFO)
  //     }
  //   }
  // }
  useEffect(() => {
    dispatch(getPlans({ is_shampoo_subscription: true }))
  }, [])

  const [activePlan, setActivePlan] = useState<PlanInterface>()

  useEffect(() => {
    const activePlanLocal = LocalStorage.activePlan
    const defaultActivePlan =
      plans && find(plans, (plan) => plan && plan.id === activePlanLocal)
    if (defaultActivePlan) {
      setActivePlan(defaultActivePlan)
    }
  }, [plans])

  const selectPlan = (plan) => {
    if (plan) {
      LocalStorage.setActivePlan(plan?.id)
      LocalStorage.setPlanType(PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION)
      setActivePlan(plan)
    }
  }

  if (!plans) {
    return <div></div>
  }

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto">
        {plansLoading ? (
          <Skeleton />
        ) : (
          <ProposalDiv className="proposal proposal-subs">
            <div className="proposal__content-badge">
              <div className="proposal__content-badge-header">
                <p className="proposal__content-badge-title">定期購入プラン</p>
                <p></p>
                <p>
                  こちらは診察不要の商品を定期購入できるプランとなっております。
                </p>
              </div>
              <PlanInfoComponent
                plans={plans}
                activePlan={activePlan}
                selectPlan={selectPlan}
                planTypeBefore={PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION}
              />
            </div>
          </ProposalDiv>
        )}
      </div>
    </>
  )
}

export default ShampooSubscriptionContainer

import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import { PATH } from 'src/pages/video/Schedule'
import LPHeader from 'src/components/layout/Header/LPHeader'
import ButtonCustom from 'src/components/form/Button'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import MenuNameComponent from 'src/components/common/MenuNameComponent'

const Container: React.FC = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview">
        <HeaderUnderlined name="診察のご案内" />
        <div className="video-interview">
          <div className="page-title mt-50 fs-20 fw-500">
            オンラインカウンセリングの案内
          </div>
          <div className="video-interview__content">
            <MenuNameComponent className="mb-15" />
            <span>担当医が対応可能な日時が選択できます。</span>
            <p>ご都合の良い日時を選択して予約してください。</p>
          </div>
          <ButtonCustom
            className="btn-default btn-chestnut-rose mt-70"
            loading={false}
            onClick={() => navigate(PATH)}
            name="診察の予約をする"
            showIconRight
          />
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .video-interview {
    &__content {
      margin: 20px 0;
      font-size: 13px;
      font-weight: 300;
      background-color: #f1efe7;
      padding: 20px;

      span {
        color: var(--color-chestnut-rose);
        font-weight: 300;
      }
    }
  }
`

export default Container

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { OrderInterface } from 'src/interfaces/order'

type State = {
  loading: boolean
  entities: OrderInterface[] | []
}

const initialState: State = {
  loading: false,
  entities: [],
}

const { actions, reducer } = createSlice({
  name: 'order_list',
  initialState,
  reducers: {
    updateEntities: (state, action: PayloadAction<OrderInterface[]>) => {
      state.entities = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

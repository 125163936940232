import React from 'react'

import PrescriptionProposalContainer from 'src/containers/KartePlan/PrescriptionProposal'

const PrescriptionProposalPage: React.FC = () => {
  return <PrescriptionProposalContainer />
}

export const PATH = '/karte-plan/prescription-proposal/:id' as const
export default PrescriptionProposalPage

import React from 'react'

import { PATH } from 'src/pages/video'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'
import CheckUploadedFileLayout from '../components/CheckUploadedFileLayout'

const VideoRoute: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.VIDEO_INCOMING,
        element: loadable(() => import('src/pages/video/Incoming')),
      },
      {
        path: PATH.VIDEO_INTERVIEW,
        element: loadable(() => import('src/pages/video/Interview')),
      },
      {
        element: <CheckUploadedFileLayout />,
        children: [
          {
            path: PATH.VIDEO_SCHEDULE,
            element: loadable(() => import('src/pages/video/Schedule')),
          },
        ],
      },
      {
        path: PATH.TEMPORARY_RESERVE_CONFIRM,
        element: loadable(
          () => import('src/pages/video/TemporaryReserveConfirm'),
        ),
      },
      {
        path: PATH.COMPLETE_TEMPORARY_RESERVE,
        element: loadable(
          () => import('src/pages/video/CompleteTemporaryReserve'),
        ),
      },
      {
        path: PATH.NOT_ALLOWED,
        element: loadable(() => import('src/pages/video/NotAllowed')),
      },
      {
        path: PATH.CALLING,
        element: loadable(() => import('src/pages/video/Calling')),
      },
      {
        path: PATH.THANK,
        element: loadable(() => import('src/pages/video/Thank')),
      },
    ],
  },
]

export default VideoRoute

export const PLANS = {
  plan_1: {
    name: 'plan_1',
    title: '育毛効果成分配合のシャンプー',
    header: 'エメルテクリームシャンプー',
    description:
      'キャピキシル配合のクリームシャンプーで、頭皮を髪の毛が「育ちやすい」環境に整えます。',
    note: '',
  },
  plan_2: {
    name: 'plan_2',
    title: '内服薬',
    header: 'ミノキシジル',
    description:
      '毛乳頭細胞に働きかけることで、ヘアサイクル（毛周期）を延長させたり、発毛を促します。',
    note: '1日 1回 夜に服用してください',
  },
  plan_3: {
    name: 'plan_3',
    title: '内服薬',
    header: 'フィナステリド',
    description:
      '脱毛の原因になるジヒドロテストステロンの産生を阻害することで、脱毛を抑制します。',
    note: '1日 1回 夜に服用してください',
  },
}

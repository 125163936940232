import React, { useEffect } from 'react'
import { Form } from 'antd'
import _ from 'lodash'

import { FormValues, useSchedule } from './useSchedule'
import { DATE_TIME, DATE_TIME_FORMAT } from 'src/constants/app'
import DatePickerField from 'src/components/form/DatePickerField'
import { useAppDispatch, useAppSelector } from 'src/store'
import SelectField from 'src/components/form/SelectField'
import { busyTime, setBusyTime } from 'src/useCase/video'
import ButtonCustom from 'src/components/form/Button'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const { form, onSubmit } = useSchedule()
  const { control, handleSubmit, setValue } = form

  const dataWorkingDates = useAppSelector(
    (state) => state.domain.video.workingDate,
  )
  const dataDoctorFreeTime = useAppSelector(
    (state) => state.domain.video.doctorFreeTime,
  )

  useEffect(() => {
    dispatch(setBusyTime([]))
  }, [])

  useEffect(() => {
    const defaultOption = _.get(dataDoctorFreeTime, 'entity[0].value', null)
    setValue('time', defaultOption)
  }, [dataDoctorFreeTime])

  const disabledDate = (current) => {
    return !_.includes(
      _.map(dataWorkingDates.entity, 'date'),
      current.format(DATE_TIME),
    )
  }

  const onChangeDate = (event) => {
    const params = { date: event.target.value }
    dispatch(busyTime({ params }))
  }

  return (
    <>
      <Form onFinish={handleSubmit(onSubmit)}>
        <div>
          <div className="mt-20 d-flex justify-content-between border-bottom-lucky mb-30 pb-5">
            <span className="fs-15">ご希望の日</span>
            <span className="text-stiletto fs-11">
              ※5日後から2週間後まで選択可能です
            </span>
          </div>
          <DatePickerField<FormValues>
            controller={{
              name: 'date',
              control,
              rules: {
                onChange: onChangeDate,
              },
            }}
            datePickerProps={{
              format: DATE_TIME_FORMAT,
              disabledDate: disabledDate,
              allowClear: false,
            }}
          />
          <SelectField<FormValues>
            className="border-top-lucky pt-15"
            label="ご希望の時間"
            selectProps={{
              options: dataDoctorFreeTime.entity,
              loading: dataDoctorFreeTime.loading,
              allowClear: false,
              placeholder: '選択してください',
            }}
            controller={{ name: 'time', control }}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          />
        </div>
        <ButtonCustom
          className="btn-default btn-chestnut-rose fs-20 mt-60 w-100"
          loading={false}
          onClick={handleSubmit(onSubmit)}
          htmlType="submit"
          showIconRight
          name="オンラインカウンセリング予約の確認画面へ"
        />
      </Form>
    </>
  )
}

export default Container

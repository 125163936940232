import { UpdateCurrentFlowRequestInterface } from 'src/interfaces/user.d'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from 'lodash'

import { UserRequestInterface } from 'src/interfaces/user'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import { HTTP_CODE } from 'src/constants/app'
import LocalStorage from 'src/utils/LocalStorage'
import userRequest from 'src/api/requests/user'
import history from 'src/libs/history'
import appActions from 'src/store/ui/app'
import notificationAction from 'src/store/domain/notification/countUnreadNotification'
// import userDetail from 'src/store/domain/user/detail'
import { PATH as ERROR_PATH } from 'src/pages/error'
export interface PostRegister {
  credentials: UserRequestInterface
  meta: UseFormReturn<UserRequestInterface, any>
  redirectPath: string
}

export interface GetUserInfo {
  with?: string
}

export const register = createAsyncThunk<any, PostRegister>(
  'register',
  async (
    { credentials, meta, redirectPath },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const res = await userRequest.register({
        ...credentials,
        ...{ clinic_uuid: LocalStorage.getClinicUuid() },
      })
      const token = get(res, 'data.access_token')

      LocalStorage.setToken(token)
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
      history.push(redirectPath)
    } catch (err: any) {
      rejectWithValue('register_failed')
      const status = err.response.status
      const errors = err.response.data.errors

      if (status === HTTP_CODE.UNPROCESSABLE_ENTITY) {
        if (get(errors, 'clinic_uuid.0')) {
          history.push(ERROR_PATH.STATUS_ERROR)
        }
        const paramsError = {
          family_name: get(errors, 'family_name.0'),
          given_name: get(errors, 'given_name.0'),
          family_name_kana: get(errors, 'family_name_kana.0'),
          given_name_kana: get(errors, 'given_name_kana.0'),
          date_of_birth: get(errors, 'date_of_birth.0'),
          gender_id: get(errors, 'gender_id.0'),
          email: get(errors, 'email.0'),
        }
        Object.entries(paramsError).map((value: any) => {
          if (value[1]) {
            meta.setError(value[0], { type: 'api', message: value[1] })
          }
        })
      }
    }
  },
)

export const getInfo = createAsyncThunk<any, GetUserInfo>(
  'user_info',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      const res = await userRequest.userInfo(params)

      dispatch(appActions.updateAuthInfo(res.data))
      dispatch(notificationAction.updateEntity(res.data?.notice_unread_count))
    } catch (err: any) {
      rejectWithValue('user_info_failed')
      const status = err.response.status

      if (status === HTTP_CODE.UNAUTHORIZED) {
        history.push('login')
      }
    }
  },
)

export const updateCurrentFlow = createAsyncThunk<
  any,
  UpdateCurrentFlowRequestInterface
>('update_current_flow', async (params, { dispatch, rejectWithValue }) => {
  await userRequest.updateCurrentFlow(params)
})

// export const getDetailInfo = createAsyncThunk<any, GetUserInfo>(
//   'user_detail_info',
//   async (params, { dispatch }) => {
//     try {
//       dispatch(userDetail.updateLoading(true))
//       const res = await userRequest.userInfo(params)

//       dispatch(userDetail.updateEntity(res.data))
//       dispatch(userDetail.updateLoading(false))
//     } catch (err: any) {
//       dispatch(userDetail.updateLoading(false))
//     }
//   },
// )

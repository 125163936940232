import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { cloneDeep, filter } from 'lodash'
import { Skeleton } from 'antd'
import * as qs from 'query-string'

import { Wrapper } from './styled'
import { PATH } from 'src/pages/aga'
import { useAppDispatch, useAppSelector } from 'src/store'
import uiActions from 'src/store/ui/notification'
import LocalStorage from 'src/utils/LocalStorage'
import { getDiagnosisQuestions } from 'src/useCase/aga'
import { Question } from 'src/entities/question'
import agaRequest from 'src/api/requests/aga'
import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'
import MenuHeader from 'src/components/layout/Header/MenuHeader'
import landingStepFirst from 'src/assets/images/landing/step-one.png'
import landingStepTwo from 'src/assets/images/landing/step-two.png'
import landingStepThree from 'src/assets/images/landing/step-three.png'
import { STORAGE_KEY } from 'src/constants/app'

const AGAQuestionContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  let questions = useAppSelector(
    (state) => state.domain.aga.questions.entity,
  ).map((item) => new Question().deserialize(item))

  const isLoading = useAppSelector(
    (state) => state.domain.aga.questions.loading,
  )
  const [activeAnswer, setActiveAnswer] = useState('')
  const [activeOption, setActiveOption] = useState<string[]>([])
  const [questionNumber, setQuestionNumber] = useState(1)

  const normalAnswer = {
    YES: 'はい',
    NO: 'いいえ',
  }
  const location = qs.parse(useLocation().search) || 0
  let questionsFilter: Question[] = []

  useEffect(() => {
    if (!questions.length) {
      dispatch(getDiagnosisQuestions())
    }
  }, [])

  useEffect(() => {
    const locationQUestion = location.question ? Number(location.question) : 1
    setQuestionNumber(locationQUestion)

    if (localStorageAnswer()) {
      setActiveAnswer(localStorageAnswer()[`q${questionNumber}`])
    }
  }, [location])

  const localStorageAnswer = () => {
    return LocalStorage.getLocalStorage(STORAGE_KEY.AGA_ANSWER)
  }
  const currentQuestion = () => {
    if (!questions.length) {
      return new Question()
    }
    const response = cloneDeep(questions)
    response.map((item, index) => {
      if (item.type === 'checkbox') {
        response[index - 1].sub_question = item
      }
    })

    questionsFilter = filter(response, (data) => data.type !== 'checkbox')
    return questionsFilter[questionNumber - 1]
  }

  const getImage = () => {
    return require(`src/assets/images/landing/image-${questionNumber}.png`)
  }

  const getOptionImage = (index) => {
    return require(`src/assets/images/landing/image-6-${index + 1}.png`)
  }

  const renderStep = () => {
    switch (questionNumber) {
      case 1:
        return (
          <img className="landing__content-img-first" src={landingStepFirst} />
        )
      case questions.length:
        return (
          <img className="landing__content-img-third" src={landingStepThree} />
        )
      default:
        return (
          <img
            className="landing__content-img landing__content-img-second"
            src={landingStepTwo}
          />
        )
    }
  }

  const nextStep = (answer?: string) => {
    let data = {
      ...localStorageAnswer(),
    }

    if (answer) {
      setActiveAnswer(answer)
      data = {
        ...data,
        [`q${questionNumber}`]: answer,
      }
      LocalStorage.setLocalStorage(STORAGE_KEY.AGA_ANSWER, data)
    }

    if (currentQuestion().sub_question.options.length) {
      data = {
        ...data,
        [`q${questionNumber}-1`]: activeOption,
      }
      LocalStorage.setLocalStorage(STORAGE_KEY.AGA_ANSWER, data)
    }

    if (questionNumber === questionsFilter.length) {
      setActiveAnswer('')
      setActiveOption([])
      submitAnswer()
      return
    }

    if (!currentQuestion().sub_question.options.length || !answer) {
      setQuestionNumber(questionNumber + 1)
      setActiveAnswer('')
      setActiveOption([])
      navigate(`${PATH.AGA}?question=${questionNumber + 1}`)
    }
  }

  const handleChooseOption = (value) => {
    if (activeOption.includes(value)) {
      setActiveOption(filter(activeOption, (data) => data !== value))
    } else {
      setActiveOption([...activeOption, value])
    }
  }

  const submitAnswer = () => {
    let formatData = {}
    Object.entries(localStorageAnswer()).map((value, index) => {
      formatData[`q${index + 1}`] = value[1]
    })
    agaRequest
      .rateAnswers(formatData)
      .then((res: any) => {
        LocalStorage.removeLocalStorage(STORAGE_KEY.AGA_ANSWER)
        LocalStorage.setLocalStorage(STORAGE_KEY.AGA_TYPE, res.data.type)
        navigate(PATH.NICKNAME)
      })
      .catch((error) => {
        const message = error?.response?.data.message
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message,
          }),
        )
      })
  }

  return (
    <Wrapper>
      <LPHeader />
      <MenuHeader name="簡単AGA診断"></MenuHeader>
      {isLoading ? (
        <div className="mx--20">
          <Skeleton />
        </div>
      ) : (
        <div className="landing">
          <div className="landing__content">
            <p className="landing__content-step mb-0">{questionNumber} / 10</p>
            <div>
              {renderStep()}
              <div className="landing__content-text">
                {currentQuestion().question}
              </div>

              <div>
                <img src={getImage()} className="landing__content-img" />
              </div>
              <div className="landing__content-btn">
                <button
                  className={`landing__content-btn-yes ${
                    activeAnswer === normalAnswer.YES ? 'active' : ''
                  }`}
                  onClick={() => nextStep(normalAnswer.YES)}
                >
                  {normalAnswer.YES}
                </button>
                <button
                  className={`landing__content-btn-yes ${
                    activeAnswer === normalAnswer.NO ? 'active' : ''
                  }`}
                  onClick={() => nextStep(normalAnswer.NO)}
                >
                  {normalAnswer.NO}
                </button>
              </div>
            </div>
            <div className="px--20">
              {currentQuestion().sub_question.options.length > 0 &&
                activeAnswer === normalAnswer.YES && (
                  <div className="landing__content-option">
                    <div>{currentQuestion().sub_question.question}</div>
                    <div>
                      {currentQuestion().sub_question.options.map(
                        (option, index) => {
                          return (
                            <div
                              key={index}
                              className={`landing__content-btn-yes ${
                                activeOption.includes(option) ? 'active' : ''
                              }`}
                            >
                              <div
                                className="landing__content-option-content"
                                onClick={() => handleChooseOption(option)}
                              >
                                <img
                                  src={getOptionImage(index)}
                                  alt=""
                                  className="landing__content-option-img"
                                />
                                <span className="landing__content-option-title">
                                  {option}
                                </span>
                              </div>
                            </div>
                          )
                        },
                      )}
                    </div>
                  </div>
                )}
              {currentQuestion().sub_question.options.length > 0 && (
                <ButtonCustom
                  name="次へ"
                  id="btn-next"
                  className="btn-default btn-black"
                  loading={false}
                  onClick={() => nextStep()}
                  disabled={
                    (activeAnswer === normalAnswer.YES &&
                      !activeOption.length) ||
                    !activeAnswer
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default AGAQuestionContainer

import React from 'react'

import ShampooSubscriptionAddressContainer from 'src/containers/Regular/RegularSubscriptionAddress'

const ShampooSubscriptionAddressPage: React.FC = () => {
  return <ShampooSubscriptionAddressContainer />
}

export const PATH = '/regular/regular-subscription-address' as const
export default ShampooSubscriptionAddressPage

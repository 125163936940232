import React from 'react'

import LPHeader from 'src/components/layout/Header/LPHeader'
import MedicalSpecialtyForm from './components/MedicalSpecialtyForm'

const MedicalSpecialtyContainer: React.FC = () => {
  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form not-padding-p">
        <MedicalSpecialtyForm />
      </div>
    </>
  )
}

export default MedicalSpecialtyContainer

import styled from 'styled-components'

export const HeaderStyle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.13em;
  color: #000;
  padding: 1.25rem 0;
`
export const LabelRequire = styled.div`
  padding-bottom: 5px;
  span {
    border: 1px solid var(--color-mandys-pink);
    color: var(--color-white);
    background-color: var(--color-mandys-pink);
    padding: 3px 7px 0;
    font-size: 11px;
    font-weight: 700;
    display: flex;
    align-items: center;
  }
`
export const InputLabel = styled.span`
  font-size: 13px;
  display: block;
  margin-bottom: 10px;
`

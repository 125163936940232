import React, { useRef, useState } from 'react'
import { Form } from 'antd'
import styled from 'styled-components'

import card4 from 'src/assets/images/members/card4.png'
import card3 from 'src/assets/images/members/card3.png'
import card2 from 'src/assets/images/members/card2.png'
import card5 from 'src/assets/images/members/card5.png'
import cardX from 'src/assets/images/members/cardX.svg'
import card6 from 'src/assets/images/members/card6.png'
import warningIcon from 'src/assets/icons/warning.svg'
import registerImg from 'src/assets/images/members/image-register.png'
import cardBack from 'src/assets/images/members/card-back.png'
import cardFront from 'src/assets/images/members/card-front.png'
import ConfirmIdentificationModal from 'src/components/Modal/ConfirmIdentificationModal'
import { useIdentification } from './useIdentification'
import { resizeFile } from 'src/utils/helper'
import ButtonCustom from 'src/components/form/Button'

const Container: React.FC = () => {
  const inputElFront = useRef<HTMLInputElement>(null)
  const inputElBack = useRef<HTMLInputElement>(null)
  const formEl = useRef<any>(null)
  const [selectedFileFront, setSelectedFileFront] = useState<File | null>(null)
  const [selectedFileBack, setSelectedFileBack] = useState<File | null>(null)
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false)
  const { form, onSubmit } = useIdentification()
  const { register, handleSubmit, setValue } = form

  return (
    <Wrapper>
      <Form ref={formEl} onFinish={handleSubmit(onSubmit)}>
        <div>
          <div>
            <h2 className="mt-5 text-xl font-bold page-title">
              本人確認書類の提出
            </h2>
            <div className="subtitle-box">
              ご利用には必ず本人確認書類の提出が必要です。
            </div>
          </div>
          <div className="upload-card-badge">利用可能な書類</div>
          <div className="upload-card-first">
            <div>
              <img src={card4} width="105px" alt="" />
              <p className="text-center mb-0">保険証</p>
            </div>
            <div>
              <img src={card3} width="105px" alt="" />
              <p className="text-center mb-0">免許証</p>
            </div>
            <div>
              <img src={card2} width="105px" alt="" />
              <p className="text-center mb-0">マイナンバー</p>
            </div>
          </div>
          <div className="upload-card-badge">利用不可能な書類</div>
          <div className="upload-card-first second-badge">
            <div className="card-left">
              <img src={card5} alt="" />
              <img className="cardx" src={cardX} alt="" />
              <p className="text-center mb-0">学生証</p>
            </div>
            <div className="card-left">
              <img src={card6} alt="" />
              <img className="cardx" src={cardX} alt="" />
              <p className="text-center mb-0">通知カード</p>
            </div>
          </div>
          <div className="upload-card-note upload-card-note--border-radius">
            <div className="upload-card-note-top">
              <div className="upload-card-note-text">
                <p className="mb-0">
                  <img className="" src={warningIcon} alt="" />
                  ご注意ください
                </p>
                <p className="mb-0">
                  文字がはっきりと映るように撮影してください。
                </p>
              </div>
              <div className="upload-card-note-img">
                <img src={registerImg} alt="" />
              </div>
            </div>
            <br />
            <div className="">
              <div className="">
                <p>
                  ※健康保険証の場合は、プライバシー保護の観点により、「記号」「番号」「保険者番号」が判断できないように、マスキングテープや付箋などで対象個所を隠すようお願いいたします。顔の確認のために、顔と一緒に写真撮ってください。
                </p>
              </div>
            </div>
          </div>
          <div className="upload-card-badge">本人確認書類の提出</div>

          <div className="upload-card-input">
            <div className="text-17_5px text-center upload-card-title">
              <b>表面</b>
            </div>
            <div>
              <input
                accept="image/jpeg,image/png,image/jpg"
                type="file"
                ref={inputElFront}
                style={{ display: 'none' }}
                onChange={async (event) => {
                  if (!event.target.files) return
                  const file = event.target.files[0]
                  const image = await resizeFile(file)
                  setSelectedFileFront(image)
                  setValue('front_side_card', image)
                }}
              />
              {selectedFileFront ? (
                <>
                  <div className="image_upload">
                    <img
                      style={{ width: '170px' }}
                      src={URL.createObjectURL(selectedFileFront)}
                    />
                  </div>
                  <div className="update-image">
                    <p
                      className="upload-new"
                      onClick={() => {
                        inputElFront.current?.click()
                      }}
                    >
                      写真を変更する
                    </p>
                  </div>
                </>
              ) : (
                <div className="flex">
                  <div className="upload-photo-register">
                    <button
                      type="button"
                      onClick={() => {
                        inputElFront.current?.click()
                      }}
                      className="upload-photo-button"
                    >
                      画像を選択する
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="upload-card-input">
            <div className="text-17_5px text-center upload-card-title">
              <b>裏面</b>
            </div>
            <div>
              <input
                {...register('back_side_card')}
                type="file"
                ref={inputElBack}
                accept="image/jpeg,image/png,image/jpg"
                style={{ display: 'none' }}
                onChange={async (event) => {
                  if (!event.target.files) return
                  const file = event.target.files[0]
                  const image = await resizeFile(file)
                  setSelectedFileBack(image)
                  setValue('back_side_card', image)
                }}
              />
              {selectedFileBack ? (
                <>
                  <div className="image_upload">
                    <img
                      style={{ width: '170px' }}
                      src={URL.createObjectURL(selectedFileBack)}
                    />
                  </div>
                  <div className="update-image">
                    <p
                      className="upload-new"
                      onClick={() => {
                        inputElBack.current?.click()
                      }}
                    >
                      写真を変更する
                    </p>
                  </div>
                </>
              ) : (
                <div className="flex">
                  <div className="upload-photo-register back-image">
                    <div className="text-center upload-photo-button">
                      <p className="text-13px text-center mt-2">
                        ※マイナンバーカードの場合は裏面を送信しないでください
                      </p>
                      <button
                        type="button"
                        onClick={() => {
                          inputElBack.current?.click()
                        }}
                      >
                        画像を選択する
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="upload-card-note">
            <p className="margin-t-13">お客様の情報は厳重に管理しています</p>
            <br />
            <p>
              提出いただいた証明書の画像は本人確認にのみ使用し、それ以外の目的で使用することはありません。
            </p>
          </div>
          <div className="p-20">
            {/* <Button
              disabled={!selectedFileFront}
              className="btn-default btn-black fs-20 mt-10 mb-30 w-100"
              onClick={() => setIsOpenConfirm(true)}
            >
              送信する
            </Button> */}
            <ButtonCustom
              disabled={!selectedFileFront}
              loading={false}
              name="送信する"
              className="btn-default btn-chestnut-rose fs-20 mt-10 mb-30 w-100"
              showIconRight
              htmlType="button"
              onClick={() => setIsOpenConfirm(true)}
            />
          </div>
          <ConfirmIdentificationModal
            isOpen={isOpenConfirm}
            onCancel={setIsOpenConfirm}
            onSubmit={() => {
              setIsOpenConfirm(false)
              formEl.current?.submit()
            }}
          />
        </div>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 70px;
  padding-left: 20px;
  padding-right: 20px;

  .id-register__title {
    font-size: 25px;
    font-weight: bold;
  }
  .id-register__warning {
    width: 30px;
    height: 30px;
  }
  .upload__image {
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    background: white;
  }
  .upload_title {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    border-bottom: solid 1px #2157a6;
    padding-bottom: 7px;
    margin-bottom: 20px;
  }

  .btn-upload-sp {
    width: 100%;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    background: white;
  }
  .button_upload {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2157a6;
    font-size: 14px;
    padding: 10px 20px;
    color: #fff;
  }
  .button_upload .button_upload_sp {
    padding: 2em 0;
    width: 50%;
    height: 100%;
    text-align: center;
    cursor: pointer;
  }
  .button_upload_area_sp {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
  }
  .button_upload_area_sp .button_upload_sp {
    width: 50%;
    height: 100%;
    text-align: center;
    cursor: pointer;
  }
  .button_upload_area_sp .button_upload_sp.upload-photo {
    border-left: 3px solid #e0e0e0;
  }
  .button_upload_area_sp .button_upload_sp img.take-photo {
    width: 40px;
    height: 38px;
    margin: 0 auto 8px;
  }
  .button_upload_area_sp .button_upload_sp img.upload-photo {
    width: 40px;
    height: 38px;
    margin: 0 auto 8px;
  }

  .button_submit {
    background-color: #41cfd9;
    color: white;
    border-radius: 5px;
  }

  .image_upload {
    background: #ededed;
    padding: 20px;
    margin-bottom: 20px;
    text-align: center;
    min-height: 174px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image_upload-item {
    width: auto;
    height: auto;
    max-height: 270px;
    margin: 0 auto;
  }
  .bottom-hint-box {
    text-align: left;
    border-radius: 7.5px;
    border: solid 1px #3f3c38;
    padding: 14.5px 11px 17.5px;

    p {
      line-height: 1.9;
      font-size: 11.5px;
    }

    ._title {
      text-align: center;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .border-gray-custom {
    border-bottom: 2px solid #c87864;
  }
  .layout-webview__title {
    font-size: 17px;
    text-align: center;
  }
  .subtitle-box {
    font-size: 13.7px;
    margin-top: 15px;
    padding-bottom: 10px;
  }
  .upload-photo-register {
    height: 230px;
    width: 100%;
    background-image: ${() => `url(${cardFront})`};
    background-size: 80% 80%;
    background-position: 50%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: end;
    align-self: center;
    padding-bottom: 20px;

    &.back-image {
      background-image: ${() => `url(${cardBack})`};
    }
    p {
      color: #ce5848;
      font-size: 12px;
      margin-bottom: 20px;
    }
    button {
      border-radius: 90px;
      color: #fff;
      background-color: #0e0d0a;
      font-weight: bold;
      text-align: center;
      padding: 15px 40px;
      border: unset;
    }
    img {
      height: 55px;
      width: 45px;
    }
  }
  .update-image {
    display: flex;
    justify-content: center;
    padding: 6px;
  }
  .back {
    font-size: 15px;
    color: #998800;
    cursor: pointer;
    width: 50px;
    margin: 0 auto 45px;
    text-align: center;
    text-decoration: underline;
  }
  .upload-new {
    font-size: 15px;
    color: #998800;
    cursor: pointer;
    font-weight: bold;
  }
  .mt-0 {
    margin-top: 0 !important;
  }

  .upload-card {
    &-badge {
      /* background-color: #f1efe7; */
      border-bottom: 1px solid #0e0d0a;
      color: #0e0d0a;
      padding-top: 20px;
      padding-bottom: 10px;
      font-weight: bold;
    }

    &-first {
      display: flex;
      justify-content: space-between;
      align-self: center;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;

      &.second-badge {
        justify-content: space-around;
      }
      .card-left {
        position: relative;

        .cardx {
          position: absolute;
          top: 10px;
          left: 25%;
        }
      }
      img {
        padding: 5px 0 10px 0;
      }
    }

    &-note {
      border: 1px solid #ce5848;
      color: #ce5848;
      padding: 15px;
      position: relative;
      font-size: 12px;
      font-weight: 500;
      line-height: 1.4;
      width: 100%;
      margin-bottom: 30px;

      &-top {
        /* display: flex;
        justify-content: space-between;
        align-items: flex-start; */
      }

      &--border-radius {
        border-radius: 13px;
      }

      &-img {
        right: 15px;
        position: absolute;
        top: -15px;

        img {
          width: 90px;
          height: auto;
        }
      }

      &-text {
        width: 82%;
      }
    }

    &-input {
      margin: 20px 0px;
      border: 1px solid #525542;
    }

    &-title {
      background-color: #f5f3e9;
      padding: 10px;
    }

    .btn-new-aimerte {
      margin-top: 40px;
    }
  }
`

export default Container

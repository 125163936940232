import HTTPRequest from 'src/api/index'
import {
  PayloadUpdatePassword,
  PayloadOrderTracking,
  countOrdersType,
} from 'src/useCase/myPage'
import { OrderInterface } from 'src/interfaces/order'
import { TrackingInterface } from 'src/interfaces/tracking'

const apiPath = 'api/users'
const apiPathV2 = 'api/v2/users'

type ResponsePaidOrder = {
  data: OrderInterface[]
}

type ResponseTracking = {
  data: TrackingInterface[]
}

class MyPage extends HTTPRequest {
  paidOrder(params, headers) {
    return this.get<ResponsePaidOrder>(`${apiPath}/mypage/paid-orders`, {
      params,
      headers,
    })
  }

  detailPaidOrder(id: string, params) {
    return this.get<OrderInterface>(`${apiPath}/mypage/paid-orders/${id}`, {
      params,
    })
  }

  orderTracking(params: PayloadOrderTracking) {
    return this.get<ResponseTracking>(
      `${apiPath}/mypage/paid-orders/tracking`,
      {
        params,
      },
    )
  }

  updatePassword(params: PayloadUpdatePassword) {
    return this.put<any>(`${apiPathV2}/pwd/update`, params)
  }

  myPageCount(params: countOrdersType) {
    return this.get(`${apiPathV2}/my-page/count`, {
      params,
    })
  }
}

export default new MyPage()

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { useNavigate } from 'react-router-dom'

import MenuHeader from 'src/components/layout/Header/MenuHeader'
import LPHeader from 'src/components/layout/Header/LPHeader'
import LocalStorage from 'src/utils/LocalStorage'
import { STORAGE_KEY } from 'src/constants/app'
import animationData from 'src/assets/json/lottiefiles.json'
import agaLoading from 'src/assets/images/landing/loading.svg'
import { PATH } from 'src/pages/aga'

const LandingPageLoadingContainer: React.FC = () => {
  const nickName = LocalStorage.getLocalStorage(STORAGE_KEY.AGA_NICK_NAME)
  const [valueLoading, setValueLoading] = useState(0)
  const navigate = useNavigate()

  const max = 100
  let number = 0
  let interval: number
  const animationOptions = {
    animationData: animationData,
  }

  useEffect(() => {
    interval = window.setInterval(() => {
      randomValue()
    }, 400)
  }, [])

  const randomValue = () => {
    number += 10
    setValueLoading(number)
    if (number === max) {
      clearInterval(interval)
      navigate(PATH.RESULT)
    }
  }
  return (
    <Wrapper className="landing loading">
      <LPHeader />
      <MenuHeader name="簡単AGA診断"></MenuHeader>
      <div className="loading-content" id="Lottie">
        <Lottie options={animationOptions} />
        <div className="loading-name mt-30">
          <span>{nickName}さんの結果を判定中..</span>
        </div>
        <div className="loading-bar">
          <div className="loading-bar-text">{valueLoading}%</div>
          <div className="loading-bar-content">
            <div
              className="loading-bar-value"
              style={{ width: valueLoading + '%' }}
            ></div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .loading {
    font-family: Futura, sans-serif;

    &-content {
      position: relative;
      background-image: ${() => `url(${agaLoading})`};
      height: calc(100vh - 122px);
      background-size: cover;
    }

    &-name {
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1em;
      color: #000000;
      position: absolute;
      text-align: center;
      top: 40px;
      width: 80%;
      left: 10%;
    }

    &-bar {
      position: absolute;
      bottom: 60px;
      width: 80%;
      left: 10%;

      &-text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: bold;
      }

      &-content {
        background: #f9f9f9;
        border-radius: 5px;
        height: 10px;
        position: relative;
      }

      &-value {
        background: #998800;
        border-radius: 5px;
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .mt-70 {
    .step-header-content {
      margin-top: 70px;
    }
  }
`

export default LandingPageLoadingContainer

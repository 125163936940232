import React from 'react'
import styled from 'styled-components'

import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'src/pages/auth'

const Container: React.FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <LPHeader />
      <Content>
        <div className="page-title">
          <h5>パスワード再設定完了</h5>
        </div>

        <div className="warraper">
          <p>
            パスワードが更新されました。
            <br />
            変更後のパスワードでログインして引き続き
            <br />
            ご利用ください。
          </p>
        </div>
        <ButtonCustom
          className="btn-default btn-gurkha mt-100 btn-submit"
          loading={false}
          htmlType="submit"
          name="ログインページへ"
          showIconRight
          onClick={() => navigate(PATH.SIGN_IN)}
        />
      </Content>
    </>
  )
}

const Content = styled.div`
  padding: 0 20px;

  & .page-title {
    margin-top: 75px;
    text-align: center;

    & h5 {
      margin-bottom: 40px;
    }
    & p {
      line-height: 24px;
      font-weight: 500;
      font-size: 13px;
      color: var(--color-kelp);
      margin-bottom: 70px;
    }
  }

  & .btn-submit {
    font-size: 18px;
  }
  & .warraper {
    padding: 0 20px;
    color: var(--color-kelp);
    text-align: center;

    & p {
      line-height: 24px;
    }
  }
`
export default Container

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'query-string'
import lodashOmit from 'lodash/omit'

// store
import { useAppDispatch, useAppSelector } from 'src/store'

// enums
import { USER_STATE } from 'src/enum/userStatus'
import { USER_TREATMENT_STATE } from 'src/enum/userTreatmentState'

// components
import CommonModal from 'src/components/Modal/CommonModal'
import LPHeader from 'src/components/layout/Header/LPHeader'

// use case
import { handleRedirectByState } from 'src/useCase/keepFlow'
import { getUserTreatmentList } from 'src/useCase/userTreatment'

// images
import newRight from 'src/assets/icons/new-right.svg'

export type paramsType = {
  with: string
  not_state?: string
}
const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const urlParams = qs.parse(location.search)
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const userTreatments = useAppSelector(
    (state) => state.domain.myPage.userTreatment.entities,
  )

  const [openModal, setOpenModal] = useState(false)
  const [treatmentState, setTreatmentState] = useState('')

  useEffect(() => {
    const notState = [
      USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_OK,
      USER_TREATMENT_STATE.SUBSCRIPTION_CONTRACT,
    ]

    let params: paramsType = {
      with: 'menu,reservations,lastDoctorFeedback,lastOrder.plan,userTreatmentTransitions',
      not_state: notState.join(','),
    }

    if (urlParams.from === 'chat') {
      params = lodashOmit(params, 'not_state')
    }

    dispatch(getUserTreatmentList(params))
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      <div className="other-menu">
        <div className="w-full justify-center mt-20 mx-auto layout-webview">
          {userTreatments &&
            userTreatments.map((userTreatment, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    const link = handleRedirectByState(
                      userTreatment,
                      user,
                      urlParams,
                    )
                    if (link === 'popup') {
                      setTreatmentState(userTreatment.state)
                      return setOpenModal(true)
                    }
                    navigate(link)
                  }}
                  className="menu-item"
                >
                  <span className="d-flex justify-content-between pb-10">
                    <div className="flex-auto flex fw-bold">
                      <span className="menu-name cl-black">
                        {userTreatment?.menu?.name ||
                          userTreatment.last_order?.plan?.name}
                      </span>
                    </div>
                    <div className="d-flex justify-content-end align-items-center">
                      <img
                        style={{ width: '15px', height: '20px' }}
                        src={newRight}
                      />
                    </div>
                  </span>
                </div>
              )
            })}
        </div>
      </div>

      <CommonModal isOpen={openModal} onClose={() => setOpenModal(false)}>
        <div className="p-15 text-center mt-20">
          {treatmentState === USER_TREATMENT_STATE.CHOICE_INTERVIEW_METHOD ? (
            <>
              <b className="fs-20">医師の診察が必要です</b>
              <br />
              <br />
              <span className="text-center">
                問診の結果、お客様につきましては医師の診察が必要となります
              </span>
              <br />
              <span className="text-center">
                後ほど医師からご連絡をお待ちください。
              </span>
            </>
          ) : user?.new_state === USER_STATE.IDENTIFICATION_OK ||
            treatmentState ===
              USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_CHECK ? (
            <>
              <b className="fs-20">医師の最終処方確認中です</b>
              <br />
              <br />
              <span className="text-center pt-25">
                本人確認書類が完了しましたので、医師によって処方の最終確認を行っております。
              </span>
              <br />
              <span className="text-center">しばらくお待ち下さい。</span>
            </>
          ) : (
            ''
          )}
        </div>
      </CommonModal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #f1efe7;
  min-height: 100vh;
  .other-menu {
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #000000;
      margin-top: 10px;
    }
    .menu-name {
      font-size: 15px;
      line-height: 32px;
    }
  }

  .menu-item {
    color: #000000;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
    line-height: 40px;
  }

  .visibility-hidden {
    visibility: hidden;
  }
`

export default Container

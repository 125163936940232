import { Deserializable } from 'src/interfaces/deserializable'
import { QuestionInterface } from 'src/interfaces/question'

export class SubQuestion
  implements Deserializable<SubQuestion>, QuestionInterface
{
  id: number = 0
  name: string = ''
  question: string = ''
  type: string = ''
  options: string[] = []

  deserialize(input: Partial<QuestionInterface>): SubQuestion {
    return this
  }
}

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useAppDispatch } from 'src/store'
import { lintLine } from 'src/useCase/auth'

import history from 'src/libs/history'
import { PATH as PATH_AUTH } from 'src/pages/auth'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'
import LoadingSuspense from 'src/components/application/LoadingSuspense'

const LinkLineContainer: React.FC = (props: any) => {
  const location = useLocation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const params = qs.parse(location.search)
    const url = window.location.origin
    const urlServer =
      url.substr(url.length - 1) === '/' ? url.slice(0, -1) : url
    if (params.code) {
      dispatch(
        lintLine({
          data: {
            code: String(params.code),
            url: `${urlServer}${PATH_AUTH.LINK_LINE}`,
          },
        }),
      )
    } else {
      history.push(PATH_MY_PAGE.OTHER_MENU)
    }
  }, [])
  return (
    <>
      <LoadingSuspense />
    </>
  )
}

export default LinkLineContainer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  draffLoading: boolean
  entity: Response.Menu[] | []
}

const initialState: State = {
  loading: false,
  entity: [],
  draffLoading: false,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'questions',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Response.Menu[]>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateDraffLoading: (state, action: PayloadAction<boolean>) => {
      state.draffLoading = action.payload
    },
  },
})

export { reducer }
export default actions

import React from 'react'

import IncomingContainer from 'src/containers/Video/Calling/Incoming'

const VideoIncoming: React.FC = () => {
  return <IncomingContainer />
}

export const PATH = '/video/incoming/:token' as const
export default VideoIncoming

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserTreatmentInterface } from 'src/interfaces/userTreatment'

type State = {
  loading: boolean
  draffLoading: boolean
  entity: UserTreatmentInterface | null
}

const initialState: State = {
  loading: false,
  entity: null,
  draffLoading: false,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'detail_user_treatments',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<UserTreatmentInterface>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

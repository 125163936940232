import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Skeleton } from 'antd'
import { some } from 'lodash'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import AddressEntryForm from 'src/components/common/AddressForm/AddressEntryForm'
import { useAppSelector } from 'src/store'
import { USER_STATE } from 'src/enum/userStatus'
import { PATH as PAYMENT_PATH } from 'src/pages/payment'

const AddressEntryContainer: React.FC = (props: any) => {
  const navigate = useNavigate()

  const userLoading = useAppSelector((state) => state.ui.app.loading)
  const user = useAppSelector((state) => state.ui.app.authInfo)

  const isVerifyOTP =
    (user &&
      some(
        user?.state_transitions,
        (i) => i.state === USER_STATE.OTP_VERIFIED,
      )) ||
    false

  useEffect(() => {
    if (isVerifyOTP) {
      navigate(PAYMENT_PATH.PAYMENT_SUCCESS)
    }
  }, [isVerifyOTP])

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form">
        <StepsHeader
          step={4}
          stepActive={3}
          stepActiveName="会員登録"
          className="top-70"
        />
        <HeaderStyle>会員登録情報入力</HeaderStyle>
        <AddressEntryForm />
        {userLoading && (
          <div className="mx--20">
            <Skeleton />
          </div>
        )}
      </div>
    </>
  )
}

export default AddressEntryContainer

import React from 'react'

import PurchaseAdditional from 'src/containers/AdditionalShampoo/confirmDelivery'

const PurchaseAdditionalPage: React.FC = () => {
  return <PurchaseAdditional />
}

export const PATH = '/additional-shampoo/confirm-delivery' as const
export default PurchaseAdditionalPage

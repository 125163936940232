import React, { useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'

type Props = {
  time: string
}

const BreakChatMessage = (props: Props) => {
  const { time } = props
  const timeFormatted: string = useMemo(() => {
    moment.locale('ja')
    if (moment().diff(moment(time).startOf('day'), 'days') == 0) {
      return '今日'
    }
    return moment(time).format('MM/DD（ddd）')
  }, [])

  return (
    <Wrapper>
      <span>{timeFormatted}</span>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
`

export default BreakChatMessage

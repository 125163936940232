import { UpdateCurrentFlowRequestInterface } from 'src/interfaces/user.d'
import { UserInfoRequestInterface, UserInterface } from 'src/interfaces/user'
import { UserRequestInterface } from 'src/interfaces/user'
import HTTPRequest from '../index'

const apiPathRegister = '/api/v2/users/register-info'
const apiPathInfo = 'api/users/info'
const apiUpdateCurrentFlow = 'api/v2/users/update-current-flow'

class Auth extends HTTPRequest {
  register(value: UserRequestInterface) {
    return this.post<UserRequestInterface>(`${apiPathRegister}`, { ...value })
  }
  userInfo(params: UserInfoRequestInterface) {
    return this.get<UserInterface>(`${apiPathInfo}`, {
      params,
      headers: { 'skip-loading': true },
    })
  }

  updateCurrentFlow(params: UpdateCurrentFlowRequestInterface) {
    return this.put<UserInterface>(`${apiUpdateCurrentFlow}`, params)
  }
}

export default new Auth()

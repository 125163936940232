import React from 'react'
import styled from 'styled-components'
import LPHeader from 'src/components/layout/Header/LPHeader'
import { Result } from 'antd'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate } from 'react-router-dom'
import { PATH as PATH_HOME } from 'src/pages/homeFlow'

const HTTPInternalErrorContainer: React.FC = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <LPHeader />
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={
          <ButtonCustom
            className={'mt-5 btn-black'}
            loading={false}
            onClick={() => navigate(PATH_HOME.FLOW)}
            name={'Back home'}
          />
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default HTTPInternalErrorContainer

import HTTPRequest from '../index'

const apiPathChannel = 'api/v2/users/channel'

class Channel extends HTTPRequest {
  async getChannel(params: { with: string }) {
    const result = await this.get(`${apiPathChannel}`, { params })

    return result.data
  }
}

export default new Channel()

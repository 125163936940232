import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { useAppDispatch } from 'src/store'
import { updateIdentityCard } from 'src/useCase/automaticPlan'

export type FormValues = {
  front_side_card: File
  back_side_card: File
}

export const useIdentification = () => {
  const dispatch = useAppDispatch()

  const validationSchema = Yup.object().shape({})

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    dispatch(updateIdentityCard({ data }))
  }

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

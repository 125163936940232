import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const TermsContainer: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const back = () => {
    navigate(-1)
  }

  return (
    <Template>
      <div className="policy">
        <Wrapper>
          <h2 className="policy-top">オンライン診療支援システム利用規約</h2>
          <p>
            オンライン診療支援システム利用規約(以下「本規約」といいます。)には、SheepMedical
            Technologies
            株式会社(以下「当社」といいます。)が運営するオンライン又は対面による診療等を支援するシステムのうち患者向けに提供されるもの
            (以下「本システム」といいます。)の利用条件その他当社と本システムを利用される患者(以下「お客様」といいます。)との間における権利義務関係が定められています。お客様が本システムを利用するためには、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
          </p>
          <div className="policy-content">
            <h3 className="term-header">第1条(適用)</h3>
            <div className="term-content">
              1.
              本規約は、本システムの提供条件その他当社とお客様との間の権利義務関係を定めることを目的とし、当社とお客様との間における本システムの提供に関わる一切の関係に適用されます。
              <br />
              2.
              当社がお客様に対し、別途提供する規約その他注意事項等が存在する場合には、当該注意事項等は本規約の一部を構成します。
              <br />
              3.
              当社は、お客様が本システムを利用することにより本規約に同意したものとみなし、お客様は異議を申し立てることはできません。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第2条(本規約の変更)</h3>
            <div className="term-content">
              1.
              当社は以下の場合に、当社の裁量により、本規約を変更することができます。
              <br />
              2. (1)本規約の変更が、お客様の一般の利益に適合するとき。
              <br />
              3.
              (2)本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。
              <br />
              4.
              当社は前項による本規約の変更にあたり、変更後の本規約の効力発生日の相当期間前までに、本規約を変更する旨及び変更後の本規約の内容とその効力発生日をウェブサイトに掲示し、またはお客様に電子メールで通知します。
              <br />
              5.
              変更後の本規約の効力発生日以降にお客様が本システムを利用したときは、お客様は、本規約の変更に同意したものとみなします。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第3条(予約機能)</h3>
            <div className="term-content">
              1.
              当社は、本システムを通じて、本システムに掲載された医療機関(以下「掲載医療機関」といいます。)におけるオンライン又は対面による診療の予約を申し込む機能(予約の取消し、予約の変更、問診票の入力等の機能を含みます。)を提供します。お客様が本システム上で予約を完了し、掲載医療機関に対してお客様によって入力された情報が通知された時点で、お客様と掲載医療機関との間で診療に関する契約が締結されたものとします。
              <br />
              2.
              お客様が本システムを通じて掲載医療機関に対して診療の申込みをした場合であっても、当社はお客様と掲載医療機関との取引(お客様からの申込みに対する掲載医療機関の応諾の保証を含む)には関与せず、診療契約の契約当事者にはなりません。
              <br />
              3.
              当社は、お客様と掲載医療機関との間のトラブルについて一切の責任を負いません。
              <br />
              4.
              お客様は、掲載医療機関の都合によって診療の予約ができない場合があること、及び、本システムに登録したお客様の情報(氏名、性別、生年月日、職業、住所、電話番号、メールアドレス、問診票の内容等)について当社が掲載医療機関に対して予約の申込み内容を通知する目的で使用・提供することを予め承諾するものとします。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第4条(料金)</h3>
            <div className="term-content">
              1.
              お客様は、本システムを通じて診察の予約をすることにより、掲載医療機関に対して、掲載医療機関が設定した料金を支払う義務を負うものとします。料金の金額は各掲載医療機関が設定したものとし、本システム上に表示されるところに従うものとします。
              <br />
              2.
              料金の支払いは、お客様が登録したクレジットカードその他電子決済方法によってなされるものとします。お客様の電子決済方法による決済ができなかった場合、当社は、お客様による本システムの利用を停止させることができるものとします。
              <br />
              3.
              料金の本システムにおける決済が完了した時点で、お客様の掲載医療機関に対する診療等の対価に係る支払義務は消滅するものとします。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第5条(予約番号等の管理)</h3>
            <div className="term-content">
              1.
              当社は、本システムの利用に際して必要な予約番号等(以下「予約番号等」といいます。)をお客様に対して発行します。
              <br />
              2.
              お客様は、予約番号等を善良な管理者の注意をもって適切に管理し、これらが他に漏れないように注意を尽くす義務を負います。
              <br />
              3.
              お客様は、予約番号等を第三者に譲渡、または使用させることはできません。
              <br />
              4.
              当社は、本システムを利用しようとする者に対して予約番号等の入力を求めることによってその者のアクセスの権限の有無を確かめる場合、予約した者の予約番号等と入力された文字列が一致するときは、当該文字列を入力した者にアクセスの権限があるものとして取り扱うことができ、当該取扱いによって生じた結果及びそれに伴う一切の責任はお客様に帰属するものとします。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第6条(知的財産権)</h3>
            <div className="term-content">
              1.
              本システムに関する特許権、著作権、商標権等の知的財産権、その他一切の権利は、当社に帰属します。
              <br />
              2.
              お客様は、本システムに関する知的財産権について一切の権利を取得しないものとし、本システムに関するコンテンツを無断で複製、編集、改変、掲載、転載、公衆送信、上映、展示、提供、販売、譲渡、貸与、翻訳、翻案、二次利用等することはできません。
              <br />
              3.
              お客様が本システムに関するコンテンツを利用して利益を得た場合には、当社はお客様に対してその利益相当額の金額を請求できる権利を有するものとします。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第7条(禁止行為)</h3>
            <div className="term-content">
              1.
              お客様は、本予約システムを利用して、次の各号に掲げる行為を行い、又は第三者に行わせてはいけません。
              <br />
              2. (1)法令又は公序良俗に反する行為又は反するおそれのある行為
              <br />
              3. (2)犯罪行為又は犯罪行為に結びつくおそれのある行為
              <br />
              4. (3)本規約に反する行為又は反するおそれのある行為
              <br />
              5.
              (4)当社若しくは第三者の特許権、著作権、商標権等の知的財産権、その他一切の権利若しくは利益を侵害する行為又は侵害するおそれのある行為
              <br />
              6.
              (5)スパムメールの発信の禁止等、インターネットの参加者の間において確立している慣習に反する行為
              <br />
              7. (6)事実に反する情報又はそのおそれのある情報を提供する行為
              <br />
              8. (7)当社又は第三者の設備に過大な負荷を与える行為
              <br />
              9.
              (8)来院予約したにもかかわらず、予約日の前日若しくは予約日当日における来院予約の取消しを繰り返す行為、又は来院予約の取消しを行うことなく予約日に来院しない行為
              <br />
              10. (9)架空の名義又は第三者の名義を用いて来院予約を行う行為
              <br />
              11. (10)前各号に掲げるほか当社が不適切と判断する行為
              <br />
              12.
              当社は、お客様が前項の禁止行為を行い、又は第三者に行わせているときは、直ちに無催告で本システムの利用を停止することができます。当社は、利用の停止によってお客様に発生した損害又は不利益について、一切の責任を負いません。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第8条(本システムの提供停止等)</h3>
            <div className="term-content">
              当社は、次の各号に掲げる事項に該当する場合には、お客様に事前に通知することなく、本システムの全部又は一部の機能の提供を一時停止、中止、変更することができるものとします。
              <div className="mt-20 ml-20">
                1.
                (1)本予約システムにかかるシステムの保守、点検、修理、変更等を行う場合
                <br />
                2.
                (2)本予約システムにかかるシステムが障害、破壊、ネットワーク障害等の事故により停止した場合
                <br />
                3. (3)不可抗力により本予約システムの運営ができない場合
                <br />
                4. (4)その他、当社が一時停止、中止又は変更を必要と判断した場合
              </div>
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第9条(地位等の処分の禁止)</h3>
            <div className="term-content">
              お客様は、当社の承諾がない限り、本規約にもとづくお客様の地位、権利又は義務について、これを第三者に譲渡し、転貸し、又は担保に供することができません。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">
              第10条(お客様と第三者との間における紛争)
            </h3>
            <div className="term-content">
              お客様は、本システムの利用に際して第三者(掲載医療機関を含む。)との間において生じた名誉毀損、プライバシーの侵害、その他一切の紛争について、お客様自身の責任で誠実にこれを解決しなければなりません。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第11条(不可抗力)</h3>
            <div className="term-content">
              当社は、天災、疫病の蔓延、悪意の第三者による妨害行為又は本システムの提供に際して当社が利用する第三者のソフトウェアの瑕疵や機器の故障等、当社に責任のない事由により、お客様が本システムを利用することができなくなった場合であっても、これによりお客様に生じた損害について、一切の責任を負いません。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第12条(免責)</h3>
            <div className="term-content">
              1.
              当社は、本システムに事実上又は法律上の瑕疵(安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティ等に関する欠陥、エラー、バグ、権利侵害等を含む。)がないことを明示的にも黙示的にも保証しておりません。当社は、お客様に対して、かかる瑕疵を除去して本システムを提供する義務を負いません。
              <br />
              2.
              消費者契約法その他適用により当社に責任が生じる場合を除き、当社は、本システムの内容の変更、提供の停止、中断若しくは廃止その他本システムに関連又は付随して生じる損害若しくは不利益について、損害の賠償その他一切の責任を負いません。
              <br />
              3.
              お客様は、自己の判断と責任において、本システムを利用するものとします。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第13条(準拠法及び裁判管轄)</h3>
            <div className="term-content">
              1. 本規約の準拠法は、日本法とします。
              <br />
              2.
              本規約に関する訴えについては、東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
            </div>
            <hr className="policy-hr" />
            <h3 className="term-header">第14条(連絡先)</h3>
            <div className="term-content">
              本システムに関する当社の連絡先は、下記に掲げるとおりです。
              <div className="mt-20">
                メール:{' '}
                <a
                  href="mailto:support@dd-dentalclinic.jp"
                  style={{ color: 'blue' }}
                >
                  support@dd-dentalclinic.jp
                </a>
                <br />
                受付:平日10:00〜18:00土日祝:休み
              </div>
            </div>

            <p className="mt-20 text-right">2023年2月21日施行</p>
          </div>
        </Wrapper>
        <div className="policy-footer" onClick={back}>
          <span className="mr-10">＜</span>戻る
        </div>
      </div>
    </Template>
  )
}

const Wrapper = styled.div`
  padding: 30px 20px 20px 20px;
  .term {
    &-header {
      font-size: 18px;
      line-height: 27px;
      font-weight: 600;
    }

    &-content {
      & p {
        font-size: 13px;
        line-height: 24px;
        margin: 0;
      }
    }
  }
`

const Template = styled.div``

export default TermsContainer

import styled from 'styled-components'

export const ModalStyle = styled.div`
  .modal-content {
    line-height: 1.5em;
    @apply bg-white;
    @apply mx-auto;
    @apply rounded;
    width: 90%;
    border-radius: 0.75rem;
    text-align: center;
    padding: 35px 10px 0;
  }
  .top-icon {
    position: relative;
    top: 40px;
    display: flex;
    justify-content: center;
    .img {
      width: 80px;
      height: 80px;
    }
  }
  .modal-header {
    text-align: center;
  }

  .modal-content >>> pre {
    word-wrap: break-word;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    padding: 0 1em;
  }

  .modal-footer {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .modal-body {
    line-height: 1.5em;
    width: 100%;
    font-size: 14px;
    /* text-align: center; */
    color: #444444;
    @apply py-4;
    p {
      margin-bottom: 3px;
    }
  }

  button {
    border: none;
    color: #fd7d6f;
    font-size: 14px;
    background: transparent;
    cursor: pointer;
  }
  button + button {
    border-left: 1px solid #cccccc;
  }
  button.btn-close {
    color: #444444;
  }
  @screen sm {
    .modal-content {
      width: 100%;
    }
  }
  .styleCustomModal {
    border-radius: 12px;
    width: 308px;
    .modal-body {
      padding: 24px 0;
      font-size: 15px;
    }
    .modal-footer {
      height: 49px;
      button {
        font-size: 15px;
      }
    }
  }

  .btn-ok {
    color: #000;
    font-weight: 700;
    font-size: 18px;
  }
  .modal-header {
    font-weight: bold;
    padding: 10px 0;
  }
`
export const TopIcon = styled.div`
  position: absolute;
  top: -50px;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 50%;
  background-color: var(--color-white);

  .img {
    width: 60px;
    height: 60px;
  }
`

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ClinicInterface } from '../../../interfaces/clinic'

type State = {
  loading: boolean
  entity: ClinicInterface | null
}

const initialState: State = {
  loading: false,
  entity: null,
}

const { actions, reducer } = createSlice({
  name: 'get_clinic',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<ClinicInterface>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

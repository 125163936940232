import RegularSubscription, {
  PATH as REGULAR_SUBSCRIPTION,
} from './RegularSubscription'
import RegularSubscriptionAddress, {
  PATH as REGULAR_SUBSCRIPTION_ADDRESS,
} from './RegularSubscriptionAddress'
import PersonalInfo, { PATH as PERSONAL_INFO } from './PersonalInfo'
import ConfirmDelivery, { PATH as CONFIRM_DELIVERY } from './ConfirmDelivery'

export default {
  RegularSubscription,
  RegularSubscriptionAddress,
  PersonalInfo,
  ConfirmDelivery,
}
export const PATH = {
  REGULAR_SUBSCRIPTION,
  REGULAR_SUBSCRIPTION_ADDRESS,
  PERSONAL_INFO,
  CONFIRM_DELIVERY,
}

import React, { useEffect } from 'react'
import { notification } from 'antd'

type Props = {
  handleCloseNotification: () => void
  isNotice: boolean
  type?: 'success' | 'error' | 'warning' | 'info'
  description?: string
  message?: string
}

const Notification = (props: Props) => {
  const {
    handleCloseNotification,
    type = 'open',
    description,
    message,
    isNotice,
  } = props

  useEffect(() => {
    if (isNotice) {
      notification[type]({
        message: message,
        description,
      })
      handleCloseNotification()
    }
  }, [isNotice])

  return <React.Fragment></React.Fragment>
}

export default Notification

import { createAsyncThunk } from '@reduxjs/toolkit'
import agaRequest from 'src/api/requests/aga'
import { FormValues } from 'src/containers/Aga/useAga'
import rateAnswersActions from 'src/store/domain/aga/rateAnswers'
import questionsActions from 'src/store/domain/aga/questions'

export interface PostRateAnswers {
  data: FormValues
}

export const aga = createAsyncThunk<any, PostRateAnswers>(
  'set_aga_rate_answers',
  async ({ data }, { dispatch }) => {
    try {
      dispatch(rateAnswersActions.updateLoading(true))
      const res = await agaRequest.rateAnswers(data)
      if (res.data) {
        dispatch(rateAnswersActions.updateEntity(res.data))
      }
      dispatch(rateAnswersActions.updateLoading(false))
    } catch (error) {
      dispatch(rateAnswersActions.updateLoading(false))
    }
  },
)

export const getDiagnosisQuestions = createAsyncThunk<any>(
  'get_diagnosis_questions',
  async (payload, { dispatch }) => {
    try {
      dispatch(questionsActions.updateLoading(true))
      const res = await agaRequest.getDiagnosisQuestions(payload)
      if (res.data && res.data.data) {
        dispatch(questionsActions.updateEntity(res.data.data))
      }
      dispatch(questionsActions.updateLoading(false))
    } catch (error) {
      dispatch(questionsActions.updateLoading(false))
    }
  },
)

import React from 'react'
import { includes } from 'lodash'
import { useNavigate } from 'react-router-dom'

import { Wrapper } from './styled'
import dataResult from './result.json'
import { PLANS } from './plan'

import LPHeader from 'src/components/layout/Header/LPHeader'
import LocalStorage from 'src/utils/LocalStorage'
import { STORAGE_KEY } from 'src/constants/app'

import doctorImage from 'src/assets/images/landing/doctor.png'
import doctorAvatar from 'src/assets/images/landing/doctor-avatar.png'

const LandingPageLoadingContainer: React.FC = () => {
  const navigate = useNavigate()
  const nickName = LocalStorage.getLocalStorage(STORAGE_KEY.AGA_NICK_NAME)
  const type = LocalStorage.getLocalStorage(STORAGE_KEY.AGA_TYPE)
  const typeData = dataResult[type]
  const plans = PLANS

  const cardTitle = () => {
    let prefixTitlte = ''

    if (includes(['B', 'C', 'D'], type)) {
      prefixTitlte = '軽症'
    }

    if (includes(['E', 'F', 'G'], type)) {
      prefixTitlte = '中等症'
    }

    if (includes(['H', 'I'], type)) {
      prefixTitlte = '重症'
    }

    return `${type ? type : 'A'}タイプ ${prefixTitlte}`
  }

  const getImageUrl = (folderName: string) => {
    const imageName = `${type ? type : 'A'}.png`
    return require(`src/static/img/checking/${folderName}/${imageName}`)
  }

  const getPlanImage = (name: string) => {
    return require(`src/static/img/checking/plan/${name}.png`)
  }

  // const accessToken = LocalStorage.accessToken

  // const handleToLine = () => {
  //   window.open('https://line.me/R/ti/p/@767rwhgn?oat__id=1440735')
  // }

  const handleToWeb = () => {
    navigate('/')
  }

  return (
    <Wrapper className="landing">
      <LPHeader />
      <div className="landing-result">
        <h2 className="landing-result-title test">
          {nickName}さんの簡単AGA診断結果
        </h2>

        <div className="landing-result__content">
          <div className="landing-result__item"></div>

          <div className="content">
            <div className="card">
              <div className="card__title">{cardTitle()}</div>
              <div className="card__content">
                <img src={getImageUrl('header')} alt="" />
                <p className="text-left ">{typeData.type_description}</p>
              </div>
            </div>

            <div className="landing-result__info">
              <div className="card__doctor">
                <img className="card__doctor-img" src={doctorImage} alt="" />
                <div className="card__doctor-content">
                  <p className="card__doctor-name fonts-16">
                    <span className="card__doctor-title">院長</span>
                    <span className="ml-15 mr-15">梅原</span>
                    <span>淳</span>
                  </p>
                  <p className="card__doctor-name">
                    柏井メディカルクリニック監修
                  </p>
                  <p className="card__doctor-name card__doctor-sub">
                    （柏井メディカルクリニック
                  </p>
                  <p className="card__doctor-name card__doctor-sub">
                    ：医療法人柏真会）
                  </p>
                </div>
                <div className="card__doctor-content-name">
                  <div className="card__doctor-content-name-x">
                    {nickName}さんのAGA予防対策
                  </div>
                </div>
              </div>

              <div className="card__measures">
                <span className="card__measures-bold">
                  {typeData.preventive_measures_bold}
                </span>
                <span className="card__measures-normal">
                  {typeData.preventive_measures_normal_1}
                </span>
                <span className="card__measures-color">
                  {typeData.preventive_measures_color}
                </span>
                <span className="card__measures-normal">
                  {typeData.preventive_measures_normal_2}
                </span>
              </div>

              <div className="landing-result__plan">
                <div className="landing-result__plan-title">
                  <div className="landing-result__plan-header">
                    {nickName}さんにおすすめの治療内容
                  </div>
                </div>
                <div className="landing-result__plan-treatment">
                  {nickName}
                  {typeData.content_of_treatment}
                </div>
                <div className="landing-result__plans">
                  {typeData.plan_suggestion.map((planSuggest, index) => {
                    return (
                      <div key={index}>
                        {planSuggest === 'plan_2' &&
                        typeData.plan_suggestion.length === 3 ? (
                          <hr />
                        ) : (
                          <div className="landing-result__plans-title">
                            {plans[planSuggest].title}
                          </div>
                        )}
                        <div className="landing-result__plans-content">
                          <div className="landing-result__plans-left">
                            <img
                              src={getPlanImage(planSuggest)}
                              alt={planSuggest}
                            />
                          </div>
                          <div className="landing-result__plans-right">
                            <div className="landing-result__plans-header">
                              {plans[planSuggest].header}
                            </div>
                            <div className="landing-result__plans-des">
                              {plans[planSuggest].description}
                            </div>
                            <div className="landing-result__plans-note">
                              {plans[planSuggest].note}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                {/* <div className="font-weight-bold">
                  決済までの内容及びフローは変わりません。
                  <span className="cl-web">WEB</span>か
                  <span className="cl-line">LINE</span>でするかの違いです。
                </div> */}
                {/* <div className="d-flex justify-content-space-between">
                  <div className="line-button-div line-button-inline position-set mr-1">
                    <div className="line-button-text">＼Webで完結／</div>
                    <Link
                      className="line-button btn-inline btn-inline-web"
                      to={
                        accessToken ? MY_PAGE_PATH.OTHER_MENU : FLOW_PATH.FLOW
                      }
                    >
                      <span>WEB診察・相談開始</span>
                    </Link>
                  </div>

                  <div className="line-button-div line-button-inline position-set mr-1">
                    <div className="line-button-text">＼LINEで確認／</div>
                    <div
                      className="line-button fonts-17 justify-content-space-around btn-inline"
                      onClick={() => handleToLine()}
                    >
                      <span>LINEで診察・相談開始</span>
                    </div>
                  </div>
                </div> */}

                <div className="line-button-div position-set">
                  <div className="line-button-text">＼診察料は今だけ無料／</div>
                  <div
                    className="line-button fonts-17"
                    onClick={() => handleToWeb()}
                  >
                    <span>今すぐ医師に相談、診察を開始する！</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content landing-result__explain">
            <div className="content__title">AGA予防をおすすめする理由</div>
            <p className="landing-result__explain-header">
              AGAは進行性がある男性型脱毛症
            </p>
            <p className="landing-result__explain-sub">
              AGA
              は、男性ホルモンの影響による脱毛で、思春期以降に発症し中年から高年期にかけて徐々に進行する症状です。
            </p>
            <div className="landing-result__explain-badge">
              AGA予防を継続することで <br />
              症状は改善されます
            </div>
            <div>
              <img
                src={getImageUrl('future-one')}
                alt="future-one"
                className="landing__content-img"
              />
            </div>
            <div className="landing-result__explain-badge">
              予防しないとAGAは <br />
              どんどん進行してしまいます！
            </div>
            <div>
              <img
                className="landing__content-img"
                src={getImageUrl('progress')}
                alt=""
              />
            </div>
            <div className="landing-result__explain-content">
              {[1, 2, 3, 4].map((value) => {
                if (typeData[`explain_${value}`]) {
                  return (
                    <p className="mb-15" key={value}>
                      {typeData[`explain_${value}`]}
                    </p>
                  )
                }
              })}
            </div>

            <div className="landing-result__explain-footer">
              <p className="card__doctor-name">
                <span className="card__doctor-title">院長</span>
                <span className="ml-20 mr-20">梅原</span>
                <span>淳</span>
              </p>
              <img
                className="landing-result__explain-img"
                src={doctorAvatar}
                alt=""
              />
            </div>
            <div className="line-button-div">
              <div className="line-button-div line-button-div-footer">
                {/* <div className="d-flex justify-content-space-between">
                  <div className="line-button-div line-button-inline position-set mr-1">
                    <div className="line-button-text">＼Webで完結／</div>
                    <Link
                      className="line-button btn-inline btn-inline-web"
                      to={
                        accessToken ? MY_PAGE_PATH.OTHER_MENU : FLOW_PATH.FLOW
                      }
                    >
                      <span>WEB診察・相談開始</span>
                    </Link>
                  </div>

                  <div className="line-button-div line-button-inline position-set mr-1">
                    <div className="line-button-text">＼LINEで確認／</div>
                    <div
                      className="line-button fonts-17 justify-content-space-around btn-inline"
                      onClick={() => handleToLine()}
                    >
                      <span>LINEで診察・相談開始</span>
                    </div>
                  </div>
                </div> */}

                <div className="line-button-div position-set">
                  <div className="line-button-text">＼診察料は今だけ無料／</div>
                  <div
                    className="line-button fonts-17"
                    onClick={() => handleToWeb()}
                  >
                    <span>今すぐ医師に相談、診察を開始する！</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default LandingPageLoadingContainer

import React from 'react'

import PaymentSuccessContainer from 'src/containers/Payment/Success'

const PaymentSuccessPage: React.FC = () => {
  return <PaymentSuccessContainer />
}

export const PATH = '/payment/success' as const
export default PaymentSuccessPage

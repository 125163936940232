import HTTPRequest from '../index'

export type Credentials = {
  email?: string
  password?: string
}

const apiPath = '/api/v2/users/web-login'
const sendMailResetPassApiPath = '/api/v2/users/send-email-reset'
const resetPassApiPath = '/api/v2/users/reset-password'
const apiLinkLine = '/api/v2/users/link-line'

class Auth extends HTTPRequest {
  login(value: Credentials) {
    return this.post(`${apiPath}`, { ...value })
  }

  sendMailResetPassword(value: MailResetPassType) {
    return this.post<Response.SendMessageSuccessType>(
      `${sendMailResetPassApiPath}`,
      {
        ...value,
      },
    )
  }

  resetPassword(value: ResetPasswordType) {
    return this.post<Response.SendMessageSuccessType>(`${resetPassApiPath}`, {
      ...value,
    })
  }

  linkLine(data) {
    return this.put(`${apiLinkLine}`, data)
  }
}

export type MailResetPassType = {
  email?: string
}

export type ResetPasswordType = {
  password: string
  password_confirmation: string
  email: string
  token: string
}

export default new Auth()

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import uiActions from 'src/store/ui/notification'
import { FILE_SIZE_1 } from './const'
import { useAppDispatch } from 'src/store'
import heic2any from 'heic2any'

type PropsType = {
  title: string
  setMedia: Function
  defaultImage: string
}

export const ImageUpload: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch()

  const inputElFront = useRef<HTMLInputElement>(null)
  const [selectedFile, setSelectedFile] = useState<string>('')

  const onDrop = (e) => {
    const newFile = e.dataTransfer.files?.[0]

    if (newFile) {
      const fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (
        fileExtension !== 'jpg' &&
        fileExtension !== 'png' &&
        fileExtension !== 'heic' &&
        fileExtension !== 'jpeg'
      ) {
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: 'JPG、JPEG、PNG、HEICファイルのみアップロード可能です。',
          }),
        )
        return
      }

      if (newFile.size > FILE_SIZE_1) {
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: '10MB以内のファイルのみアップロード可能です。',
          }),
        )
        return
      }

      if (fileExtension === 'heic') {
        heic2any({ blob: newFile, toType: 'image/jpeg' }).then(
          (convertedBlobs) => {
            const convertedBlob = convertedBlobs
            setSelectedFile(URL.createObjectURL(convertedBlob as Blob))
            props.setMedia(convertedBlob)
          },
        )
      }
      setSelectedFile(URL.createObjectURL(newFile))
      props.setMedia(newFile)
    }
  }
  useEffect(() => {
    const handler = (e) => {
      e.preventDefault()
    }

    window.addEventListener('dragover', handler)
    window.addEventListener('drop', handler)

    return () => {
      window.removeEventListener('dragover', handler)
      window.removeEventListener('drop', handler)
    }
  }, [])

  return (
    <Wrapper>
      <div className="upload-card-input" onDrop={onDrop}>
        <p className="upload-card-input__text-header">{props.title}</p>
        <div>
          {selectedFile ? (
            <div className="image_upload d-flex justify-content-center">
              <img style={{ maxWidth: '100%' }} src={selectedFile} alt="" />
            </div>
          ) : (
            <div className="upload-image">
              <p className="upload-image__title">参考画像</p>
              <img
                className="upload-image__demo"
                src={props.defaultImage}
                alt=""
              />
              <p className="upload-card-input__image-demo">
                ※JPG、JPEG、HEICまたはPNGファイルを選択してください
              </p>
            </div>
          )}
          <div className="update-image">
            <p
              className="upload-new"
              onClick={() => {
                inputElFront.current?.click()
              }}
            >
              画像を選択する
            </p>
          </div>
        </div>
      </div>
      <input
        accept=".jpg, .png, .heic, .jpeg"
        type="file"
        ref={inputElFront}
        style={{ display: 'none' }}
        onChange={(event) => {
          if (!event.target.files) return
          const selectedFile = event.target.files[0]
          const fileExtension = selectedFile.name
            .split('.')
            .pop()
            ?.toLowerCase()

          if (
            fileExtension !== 'jpg' &&
            fileExtension !== 'png' &&
            fileExtension !== 'heic' &&
            fileExtension !== 'jpeg'
          ) {
            dispatch(
              uiActions.onNotification({
                type: 'error',
                message:
                  'JPG、JPEG、PNG、HEICファイルのみアップロード可能です。',
              }),
            )
            return
          }

          if (selectedFile.size > FILE_SIZE_1) {
            dispatch(
              uiActions.onNotification({
                type: 'error',
                message: '10MB以内のファイルのみアップロード可能です。',
              }),
            )
            return
          }

          if (fileExtension === 'heic') {
            heic2any({ blob: selectedFile, toType: 'image/jpeg' }).then(
              (convertedBlobs) => {
                const convertedBlob = convertedBlobs
                setSelectedFile(URL.createObjectURL(convertedBlob as Blob))
                props.setMedia(convertedBlob)
              },
            )
          }

          setSelectedFile(URL.createObjectURL(selectedFile))
          props.setMedia(selectedFile)
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .upload-image {
    text-align: center;
    &__title {
      font-size: 16px;
    }
    &__demo {
      width: 100%;
      max-width: 350px;
    }
  }
  .upload-card-input__image-demo {
    margin-top: 20px;
    margin-bottom: 5px;
    display: block;
  }
`

import { get } from 'lodash'
import { createAsyncThunk } from '@reduxjs/toolkit'

import planRequest from 'src/api/requests/plan'
import planActions from 'src/store/domain/plan/plan'
import temporaryOrderActions from 'src/store/domain/plan/temporaryPlan'
import planDetailActions from 'src/store/domain/plan/planDetail'
import history from 'src/libs/history'
import { PlansRequestInterface } from 'src/interfaces/plan'
import userRequest from 'src/api/requests/user'
import appActions from 'src/store/ui/app'
import { PLAN_SHAMPOO_ADDITIONAL_ID } from 'src/constants/order'
import squarePayReq from 'src/api/requests/squarePayment'
import { PATH as PATH_PAYMENT } from 'src/pages/payment'
import _ from 'lodash'
import squareAction from 'src/store/domain/payment/square'

export interface OrderRequest {
  plan_id: number
  plan_type: string
  quantity?: number
}

export const getPlans = createAsyncThunk<any, PlansRequestInterface>(
  'get_plans',
  async (params, { dispatch }) => {
    try {
      dispatch(planActions.updateLoading(true))
      const res = await planRequest.getPlans(params)
      if (res.data) {
        dispatch(planActions.updateEntity(get(res, 'data.data', [])))
      }
      dispatch(planActions.updateLoading(false))
    } catch (error) {
      dispatch(planActions.updateLoading(false))
    }
  },
)

export interface PlanRequestInterface {
  plan_id: number
}
export const getTemporaryOrder = createAsyncThunk<any, PlanRequestInterface>(
  'get_temporary_order',
  async ({ plan_id }, { dispatch }) => {
    try {
      dispatch(temporaryOrderActions.updateLoading(true))
      const res = await planRequest.getTemporaryOrder(plan_id)
      if (res.data) {
        dispatch(temporaryOrderActions.updateEntity(res.data))
      }
      dispatch(temporaryOrderActions.updateLoading(false))
    } catch (error) {
      dispatch(temporaryOrderActions.updateLoading(false))
    }
  },
)

export interface PlanDetailRequestInterface {
  plan_id: number
  with: string
}

export const getPlanDetail = createAsyncThunk<any, PlanDetailRequestInterface>(
  'get_plan_detail',
  async (params, { dispatch }) => {
    try {
      dispatch(planDetailActions.updateLoading(true))
      const res = await planRequest.getPlanDetail(params.plan_id, {
        with: params.with,
      })
      if (res.data) {
        dispatch(planDetailActions.updateEntity(res.data))
      }
      dispatch(planDetailActions.updateLoading(false))
    } catch (error) {
      dispatch(planDetailActions.updateLoading(false))
    }
  },
)

export interface PostOrderInterface {
  plan_id: number
  plan_type: string
  redirect_path?: string
  doctor_feedback_id?: number
}

export const orderPlan = createAsyncThunk<any, PostOrderInterface>(
  'order',
  async (params, { dispatch }) => {
    try {
      dispatch(planActions.updateLoading(true))
      const res = await planRequest.order(params)

      // history.push(params.redirect_path)
      if (res.data) {
        dispatch(planActions.updateEntity(res.data))
      }

      const resUser = await userRequest.userInfo({
        with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
      })

      dispatch(appActions.updateAuthInfo(resUser.data))

      dispatch(planActions.updateLoading(false))
    } catch (error) {
      dispatch(planActions.updateLoading(false))
    }
  },
)

export interface PostAdditionalShampooInterface {
  data: {
    plan_id: number
    plan_type: string
    quantity: number
  }
}
export const orderAdditionShampoo = createAsyncThunk<
  any,
  PostAdditionalShampooInterface
>('order_addition_shampoo', async ({ data }, { dispatch }) => {
  try {
    dispatch(planActions.updateLoading(true))
    const res = await planRequest.order(data)
    if (data.plan_id === PLAN_SHAMPOO_ADDITIONAL_ID) {
      const dataPayment = {
        order_number: res.data.order_number,
      }

      await squarePayReq
        .chargeAdditional(dataPayment)
        .then(() => {
          history.push(PATH_PAYMENT.PAYMENT_SUCCESS)
        })
        .catch((error) => {
          const msg = _.get(error, 'response.data.message', '')
          const code = _.get(error, 'response.data.code', '')
          dispatch(squareAction.updateLoading(false))
          dispatch(squareAction.updateErrorMsg(msg))
          dispatch(squareAction.updateErrorCode(code))
          history.push(PATH_PAYMENT.PAYMENT_ERROR)
        })
    } else {
      history.push(PATH_PAYMENT.PAYMENT_INFO)
    }

    dispatch(planActions.updateLoading(false))
  } catch (error) {
    dispatch(planActions.updateLoading(false))
  }
})

import PurchaseAdditional, {
  PATH as PURCHASE_ADDITIONAL_PATH,
} from './AdditionalShampoo'
import ConfirmDelivery, {
  PATH as CONFIRM_DELIVERY_PATH,
} from './ConfirmDelivery'

export default {
  PurchaseAdditional,
  ConfirmDelivery,
}
export const PATH = {
  PURCHASE_ADDITIONAL_PATH,
  CONFIRM_DELIVERY_PATH,
}

import { createAsyncThunk } from '@reduxjs/toolkit'

import doctorRequest from 'src/api/requests/doctor'
import doctorActions from 'src/store/domain/doctor/firstDoctor'

export const getFirstDoctor = createAsyncThunk<any>(
  'get_first_doctor',
  async (params, { dispatch }) => {
    try {
      dispatch(doctorActions.updateLoading(true))
      const res = await doctorRequest.getFirstDoctor()
      if (res.data) {
        dispatch(doctorActions.updateEntity(res.data))
      }
      dispatch(doctorActions.updateLoading(false))
    } catch (error) {
      dispatch(doctorActions.updateLoading(false))
    }
  },
)

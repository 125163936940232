import React from 'react'

import PaymentContainer from 'src/containers/Payment/Info'

const PaymentInfoPage: React.FC = () => {
  return <PaymentContainer />
}

export const PATH = '/payment' as const
export default PaymentInfoPage

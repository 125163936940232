import React from 'react'

import AddressEntryContainer from 'src/containers/AutomaticPlan/AddressEntry'

const AddressEntryPage: React.FC = () => {
  return <AddressEntryContainer />
}

export const PATH = '/automatic-plan/address-entry' as const
export default AddressEntryPage

import React from 'react'
import Modal from './BasicModalConfirm'

import agaPopupIcon from 'src/assets/icons/sheep.svg'
import { TopIcon } from './ModalStyle'

type Props = {
  isOpen: boolean
  title?: string
  onSubmit: () => void
  onCancel: (submit: boolean) => void
  labelSubmit?: string
  labelCancel?: string
  classSubmit?: string
}

const ConfirmIdentificationModal: React.FC<Props> = (props) => {
  const handleCancel = () => {
    props.onCancel(false)
  }

  const handleSubmit = () => {
    props.onSubmit()
  }

  return (
    <Modal isOpen={props.isOpen} onClose={handleCancel} onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center">
        <TopIcon>
          <img className="img" src={agaPopupIcon} alt="" />
        </TopIcon>
      </div>
      <div className="modal-content styleCustomModal">
        <div className="modal-body">
          <p>本人確認書類を提出しますか？</p>
          <p>※画像の読み込みにお時間がかかります</p>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmIdentificationModal

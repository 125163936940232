export const MESSAGE_TYPE = {
  TEXT: 1,
  IMAGE: 2,
  LINK: 3,
  BUTTON: 4,
  LABEL: 5,
  CLINIC_PATTERN: 6,
  FOR_DOCTOR: 7,
  CANCEL_COURSE: 8,
  PAYING: 9,
  MOUTH: 10,
}

export const SENDER_BY = {
  USER: 'user',
  MANAGER: 'manager',
}

export const SOCKET_EVENT = {
  MESSAGE_CREATED: 'App\\Events\\MessageCreated',
  MESSAGE_DELETED: 'App\\Events\\MessageDeleted',
  DOCTOR_FINISH_CHAT: 'App\\Events\\DoctorFinishChat',
  MESSAGE_READ: 'App\\Events\\MessageRead',
  NOTIFICATION: 'App\\Events\\NotificationSocket',
}

import { LocalStorage } from 'src/utils/LocalStorage'
import { createAsyncThunk } from '@reduxjs/toolkit'
import TagManager from 'react-gtm-module'

import clinicRequest from 'src/api/requests/clinic'
import clinicDetailAction from 'src/store/domain/clinic/detail'

export interface ClinicDetailRequest {
  uuid: string
}

export const getClinicDetail = createAsyncThunk<any, ClinicDetailRequest>(
  'get_plan_detail',
  async (params, { dispatch }) => {
    try {
      dispatch(clinicDetailAction.updateLoading(true))
      const res = await clinicRequest.getClinicDetail(params.uuid)
      if (res.data) {
        const gtmId = res.data.gtm_id
        const currentGmtId = LocalStorage.getGtmId()
        LocalStorage.setGtmId(gtmId)
        if (currentGmtId !== gtmId) {
          TagManager.initialize({
            gtmId: gtmId,
          })
        }
        dispatch(clinicDetailAction.updateEntity(res.data))
      }
      dispatch(clinicDetailAction.updateLoading(false))
    } catch (error) {
      dispatch(clinicDetailAction.updateLoading(false))
    }
  },
)

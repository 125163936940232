import { Question } from 'src/interfaces/question'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  draffLoading: boolean
  entity: Question[] | null
}

const initialState: State = {
  loading: false,
  entity: null,
  draffLoading: false,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'questions',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Question[]>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateDraffLoading: (state, action: PayloadAction<boolean>) => {
      state.draffLoading = action.payload
    },
  },
})

export { reducer }
export default actions

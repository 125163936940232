import React from 'react'

import Identification from 'src/containers/AutomaticPlan/Identification'

const IdentificationPage: React.FC = () => {
  return <Identification />
}

export const PATH = '/automatic-plan/identification' as const
export default IdentificationPage

import { useState } from 'react'
import { find, get, isEmpty } from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { useAppSelector } from 'src/store/index'
import { answerInterView } from 'src/useCase/question'
import { useAppDispatch } from 'src/store'
import LocalStorage from 'src/utils/LocalStorage'

export type AnswerInterface = {
  answer: any
  question_id: number
  question_type: string
}

export type FormValues = {
  answers: AnswerInterface[]
  reservation_id: string
  menu_id: string | number
}

export type QForm = {
  '1': null
  '2': null
  '3': null
  '4': []
  '5': null
  '6': null
  '7': null
  '8': null
  '9': null
  '10': null
  '11': null
  '12': null
  '13': null
  '14': null
}

export const useQuestionForm = () => {
  const dispatch = useAppDispatch()
  const questions = useAppSelector(
    (state) => state.domain.automaticPlan.questions.entity,
  )
  const [errorData, setErrorsData] = useState({})

  let answersList = {} as any
  questions &&
    questions.length > 0 &&
    questions.map((question) => {
      const dataAnswer = get(question, 'draftAnswer.0')
      const questionType = question.type
      if (dataAnswer) {
        answersList = {
          ...answersList,
          [`${question.id}`]:
            questionType === 'radio'
              ? get(dataAnswer, 'answers.0', null)
              : dataAnswer.answers,
        }
      }
      return question
    })

  const defaultValues: QForm = answersList

  const validationSchema = Yup.object().shape({})

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    let errorDataValue = {} as any
    if (questions) {
      questions.map((question) => {
        const questionValue = find(
          data.answers,
          (data) => data.question_id === question.id,
        )

        const idName: any = question.id.toString()
        const questionBeforeValue = find(
          data.answers,
          (data) => data.question_id === Number(idName) - 1,
        )

        if (question.type === 'textarea') {
          if (!questionValue) {
            if (
              get(questionBeforeValue, 'answer.0.value') !== 'なし' &&
              question.required
            ) {
              form.setError(idName, {
                type: 'custom',
                message: '入力してください',
              })
              errorDataValue = { ...errorDataValue, [idName]: idName }
            } else {
              if (
                get(questionBeforeValue, 'answer.0.value') !== 'なし' &&
                question.required
              ) {
                form.setError(idName, {})
                errorDataValue = { ...errorDataValue, [idName]: idName }
              }
            }
          }
        } else {
          if (!questionValue && question.required) {
            form.setError(idName, {
              type: 'custom',
              message: '選択してください。',
            })
            errorDataValue = { ...errorDataValue, [idName]: idName }
          }
        }

        return question
      })
      setErrorsData(errorDataValue)
    }
    if (isEmpty(errorDataValue)) {
      const userTreatmentId = LocalStorage.getUserTreatmentId()
      dispatch(
        answerInterView({
          answers: data.answers,
          reservation_id: data.reservation_id,
          user_treatment_id: Number(userTreatmentId),
          menu_id: data.menu_id,
        }),
      )
    }
  }

  const form = useForm<QForm>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
    errorData: errorData,
  }
}

import React from 'react'

import RegisterContainer from 'src/containers/Auth/Register'

const RegisterPage: React.FC = () => {
  return <RegisterContainer />
}

export const PATH = '/register' as const
export default RegisterPage

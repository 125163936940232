import { createAsyncThunk } from '@reduxjs/toolkit'

import orderTrackingReq from 'src/api/requests/orderTracking'
import uiActions from 'src/store/ui/notification'
import { getDetailPaidOrder } from 'src/useCase/myPage'

export type PayloadSkipOrderTracking = {
  orders_tracking_id: number
  orders_id: number
}

export const skipOrderTracking = createAsyncThunk<
  any,
  PayloadSkipOrderTracking
>('order_tracking_skip', async (params, { dispatch }) => {
  try {
    await orderTrackingReq.skipOrderTracking(params)
    dispatch(
      getDetailPaidOrder({
        id: params.orders_id.toString(),
        params: { with: 'plan,tracking' },
      }),
    )
  } catch (err: any) {
    dispatch(
      uiActions.onNotification({
        type: 'error',
        message: err.response.data.message,
      }),
    )
  }
})

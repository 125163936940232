import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { updatePassword } from 'src/useCase/myPage'
import { useAppDispatch } from 'src/store'
import { PASSWORD_REGEX } from 'src/utils/regex'

export type FormValues = {
  password: string
  password_confirmation: string
}

export const useUpdatePassword = () => {
  const dispatch = useAppDispatch()

  const defaultValues: FormValues = {
    password: '',
    password_confirmation: '',
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワードは 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      ),
    password_confirmation: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワードは 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      )
      .oneOf([Yup.ref('password'), null], 'パスワードが一致しません。'),
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await dispatch(updatePassword(data))
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

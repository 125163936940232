import HTTPRequest from '../index'

const apiPathNotification = 'api/v2/users/notification'

class Notification extends HTTPRequest {
  getListNotification(params) {
    return this.get(`${apiPathNotification}`, {
      params,
      headers: { 'skip-loading': true },
    })
  }

  getDetailNotification(id) {
    return this.get(`${apiPathNotification}/${id}`, {
      headers: { 'skip-loading': true },
    })
  }
}

export default new Notification()

import React, { useEffect } from 'react'
import { Skeleton } from 'antd'
import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from 'src/store'

import { getMenus } from 'src/useCase/question'

import { useMedicalSpecialtyForm } from 'src/containers/MedicalSpecialty/components/useMedicalSpecialtyForm'

const MedicalSpecialtyForm = () => {
  const dispatch = useAppDispatch()

  const { onSubmit } = useMedicalSpecialtyForm()

  const menus = useAppSelector(
    (state) => state.domain.automaticPlan.menus.entity,
  )
  const loadingMenu =
    useAppSelector((state) => state.domain.automaticPlan.menus.loading) || false

  useEffect(() => {
    if (!loadingMenu && typeof menus[0] !== 'undefined') {
      onSubmit({ menu_id: menus[0].id })
    }
  }, [loadingMenu, menus])

  useEffect(() => {
    const params = {}
    dispatch(getMenus(params))
  }, [])

  return (
    <Wrapper>
      <Skeleton />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: var(--color-pampas);
    border: 1px solid var(--color-pampas);
    border-radius: 10px;
  }

  .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`

export default MedicalSpecialtyForm

import React from 'react'

import AllDeliverySchedule from 'src/containers/MyPage/AllDeliverySchedule'

const AllDeliverySchedulePage: React.FC = () => {
  return <AllDeliverySchedule />
}

export const PATH = '/my-page/delivery-schedule/all' as const
export default AllDeliverySchedulePage

import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import LPHeader from 'src/components/layout/Header/LPHeader'
import LocalStorage from 'src/utils/LocalStorage'
import { createReservationDate } from 'src/useCase/video'
import history from 'src/libs/history'
import { PATH } from 'src/pages/video/CompleteTemporaryReserve'
import { PATH as PATH_SCHEDULE } from 'src/pages/video/Schedule'
import ButtonCustom from 'src/components/form/Button'
import ReservationInfo from 'src/components/common/ReservationInfo'
import { useAppDispatch } from 'src/store'
import { getInfo } from 'src/useCase/user'
import uiActions from 'src/store/ui/notification'

const Container: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const schedule = LocalStorage.videoSchedule
  const [loading, setLoading] = useState(false)

  const submitSchedule = async () => {
    setLoading(true)
    const data = await createReservationDate({
      date: `${schedule.date} ${schedule.time}:00`,
    })
    if (data) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
      history.push(PATH)
    } else {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: '予約時間を過去したので、正しい時間を選択ください。',
        }),
      )
      history.push(PATH_SCHEDULE)
    }
    setLoading(false)
  }

  return (
    <Wrapper>
      <LPHeader />
      <div className="shampoo w-full justify-center mx-auto layout-webview mt-20">
        <StepsHeader
          step={4}
          stepActive={4}
          stepActiveName="オンラインカウンセリング予約"
          className="top-70"
        />
        <div className="page-title fs-20 fw-500 mt-30">
          オンラインカウンセリング予約
        </div>
        <div className="shampoo__content">
          <p>下記でオンラインカウンセリングの予約を確定します</p>
          <ReservationInfo />
        </div>
        <ButtonCustom
          className="btn-default btn-chestnut-rose my--30"
          onClick={() => submitSchedule()}
          showIconRight
          loading={loading}
          name="予約を確定する"
        />
        <ButtonCustom
          className="btn-default btn-white my--30"
          loading={false}
          onClick={() => navigate(PATH_SCHEDULE)}
          name="戻る"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .shampoo {
    &__content {
      margin: 20px 0;
      font-size: 14px;
    }
  }

  .top-70 {
    position: relative;
    top: 0px !important;
  }
`

export default Container

import React from 'react'

import { PATH } from 'src/pages/payment'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'

const AuthRoute: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.PAYMENT_CONFIRM,
        element: loadable(() => import('src/pages/payment/Confirm')),
      },
      {
        path: PATH.PAYMENT_INFO,
        element: loadable(() => import('src/pages/payment/Info')),
      },
      {
        path: PATH.PAYMENT_ERROR,
        element: loadable(() => import('src/pages/payment/Error')),
      },
      {
        path: PATH.PAYMENT_SUCCESS,
        element: loadable(() => import('src/pages/payment/Success')),
      },
    ],
  },
]

export default AuthRoute

import React from 'react'
import styled from 'styled-components'
import { Col, Form, Row } from 'antd'

import { FormValues, useResetPassword } from './useResetPassword'
import { InputField } from 'src/components/form'
import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'

const Container: React.FC = () => {
  const { form, onSubmit } = useResetPassword()
  const { control, handleSubmit, formState } = form

  return (
    <Wrapper>
      <LPHeader />
      <div className="page-title">
        <h5>パスワード再設定</h5>
        <p>
          新しいパスワードを入力し、設定ボタンを <br />
          押してください。
        </p>
      </div>
      <StyleForm onSubmit={handleSubmit(onSubmit)}>
        <Row className="pt-20">
          <Col xs={24}>
            <label>新しいパスワード</label>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'password',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'password',
              }}
            />
          </Col>
        </Row>
        <Row className="pt-20">
          <Col xs={24}>
            <label>新しいパスワード(確認用)</label>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'password_confirmation',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'password',
              }}
            />
          </Col>
        </Row>
        <ButtonCustom
          className="btn-default btn-gurkha mt-5 btn-submit"
          loading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
          htmlType="submit"
          showIconRight
          name="設定する"
        />
      </StyleForm>
      {/* <Notification></Notification> */}
    </Wrapper>
  )
}

const StyleForm = styled(Form)`
  padding: 0 20px;

  & input {
    padding: 15px !important;
  }
  & .form-description {
    color: #323232;
    line-height: 24px;
    font-weight: 600;
    font-size: 13px;
  }
  & .btn-submit {
    font-size: 18px;
  }

  & label {
    font-size: 15px !important;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }
`
const Wrapper = styled.div`
  & .page-title {
    margin-top: 75px;
    text-align: center;

    & h5 {
      margin-bottom: 40px;
    }
    & p {
      line-height: 24px;
      font-weight: 500;
      font-size: 13px;
      color: var(--color-kelp);
      margin-bottom: 70px;
    }
  }
`

export default Container

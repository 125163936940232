import { PATH as AUTOMATIC_PLAN_PATH } from 'src/pages/automaticPlan'
import { PATH as PAYMENT_PATH } from 'src/pages/payment'
import { PLAN_TYPE } from 'src/constants/app'

export const MAP_KEYS = {
  about_medicine: '説明',
  detail: '詳細',
  precautions_for_use: '使用上の注意',
  side_effects: '副作用',
  prohibition_of_concomitant_use: '併用禁止',
}

export const getRedirectPathByPlanType = (
  planType: string,
  isVerifyOTP: boolean,
) => {
  if (planType === PLAN_TYPE.EXAMINATION) {
    if (isVerifyOTP) {
      return PAYMENT_PATH.PAYMENT_INFO
    } else {
      return AUTOMATIC_PLAN_PATH.ADDRESS_ENTRY
    }
  }
  if (planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION) {
    return PAYMENT_PATH.PAYMENT_INFO
  }
  if (planType === PLAN_TYPE.PRESCRIPTION) {
    return PAYMENT_PATH.PAYMENT_INFO
  }

  return AUTOMATIC_PLAN_PATH.ADDRESS_ENTRY
}

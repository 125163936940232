export const DELIVERY_STATE = {
  NOT_PAID: 'not_paid',
  PAID: 'paid',
  PREPARING_FOR_DELIVERY: 'preparing_for_delivery',
  STATUS_CONFIRM_PAYMENT: 'confirm_payment',
  DELIVERED: 'delivered',
  DELIVERY_COMPLETE: 'delivery_complete',
  DELIVERY_RETURN: 'delivery_return',
  CANCEL: 'cancel',
  READY_FOR_DELIVERY: 'ready_for_delivery',
  ERROR_PAID: 'error',
  SKIPPED: 'skipped',
}

export const DELIVERY_STATE_NAME = {
  [DELIVERY_STATE.NOT_PAID]: '未決済',
  [DELIVERY_STATE.PAID]: '決済済み',
  [DELIVERY_STATE.PREPARING_FOR_DELIVERY]: '発送予定',
  [DELIVERY_STATE.DELIVERED]: '発送済',
  [DELIVERY_STATE.DELIVERY_COMPLETE]: '配達済',
  [DELIVERY_STATE.DELIVERY_RETURN]: '配送戻り',
  [DELIVERY_STATE.CANCEL]: 'キャンセル',
  [DELIVERY_STATE.READY_FOR_DELIVERY]: '発送予定',
  [DELIVERY_STATE.ERROR_PAID]: '決済エラー',
  [DELIVERY_STATE.SKIPPED]: 'スキップ済',
}

import React, { useEffect } from 'react'
import { Skeleton } from 'antd'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import AddressEntryForm from 'src/components/common/AddressForm/AddressEntryForm'
import { useAppSelector } from 'src/store'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'
import { USER_STATE } from 'src/enum/userStatus'
import some from 'lodash/some'
import { useNavigate } from 'react-router-dom'
import { PATH as PAYMENT_PATH } from 'src/pages/payment'

const ShampooSubscriptionAddressContainer: React.FC = (props: any) => {
  const navigate = useNavigate()
  const userLoading = useAppSelector((state) => state.ui.app.loading)
  const user = useAppSelector((state) => state.ui.app.authInfo)

  const isVerifyOTP =
    (user &&
      some(
        user?.state_transitions,
        (i) => i.state === USER_STATE.OTP_VERIFIED,
      )) ||
    false

  useEffect(() => {
    if (isVerifyOTP) {
      navigate(PAYMENT_PATH.PAYMENT_SUCCESS)
    }
  }, [isVerifyOTP])

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview register-form pb-30">
        <HeaderUnderlined name="定期購入手続き" />
        <HeaderStyle>ご住所入力</HeaderStyle>
        <p>こちらに入力されたご住所に商品を配送いたします。</p>
        <AddressEntryForm />
        {userLoading && (
          <div className="mx--20">
            <Skeleton />
          </div>
        )}
      </div>
    </>
  )
}

export default ShampooSubscriptionAddressContainer

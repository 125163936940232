import React from 'react'
import styled from 'styled-components'

import { PlanInterface } from 'src/interfaces/plan'
import basePriceImg from 'src/assets/images/members/mypage/base_price.png'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate } from 'react-router-dom'
import { formatNumPrice } from 'src/utils/helper'
import iconRectangle from 'src/assets/images/members/sheep/proposal-rectangle.svg'
import LocalStorage from 'src/utils/LocalStorage'
import { PLAN_TYPE } from 'src/constants/app'
import { PLAN_PAYMENT_TYPE } from 'src/constants/plan'
import LazyLoadImage from 'src/components/common/LazyLoadImage'

type PlanInfoInterface = {
  plans?: PlanInterface[]
  activePlan?: PlanInterface
  selectPlan: Function
  planTypeBefore: string
  feedbackId?: number
}

const PlanInfoComponent = (props: PlanInfoInterface) => {
  const {
    plans = [],
    activePlan,
    selectPlan,
    planTypeBefore,
    feedbackId,
  } = props
  const navigate = useNavigate()

  const handleShowDetail = (id) => {
    LocalStorage.setActivePlan(id)
    LocalStorage.setPlanType(planTypeBefore)
    if (feedbackId && planTypeBefore === PLAN_TYPE.PRESCRIPTION) {
      navigate(`/plans/${id}?feedbackId=${feedbackId}`)
    } else {
      navigate(`/plans/${id}`)
    }
  }

  return (
    <Wrapper>
      <div className="info-plan" id="choosing">
        <div
          className={`info-plan__choosing ${
            planTypeBefore === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION
              ? 'pt-150'
              : ''
          }`}
        >
          {plans &&
            plans.length > 0 &&
            plans.map((plan) => {
              return (
                <div key={plan.id} className="info-plan__choosing-plan">
                  <div
                    className={`info-plan__choosing-content ${
                      activePlan && plan.id === activePlan.id ? 'active' : ''
                    }`}
                    onClick={() => selectPlan(plan)}
                  >
                    <div className="info-plan__choosing-header">
                      <div className="info-plan__choosing-title">
                        <p>{plan.name}</p>
                      </div>
                    </div>
                    <div className="info-plan__img mb-10">
                      <LazyLoadImage imgSrc={plan.image_url} />
                    </div>

                    {plan.payment_type === PLAN_PAYMENT_TYPE.ONCE ? (
                      <div className="info-plan__choosing-header-right fs-14">
                        <span className="info-plan__choosing-header-price">
                          {plan.price_first
                            ? formatNumPrice(plan.price_first)
                            : 0}
                        </span>
                        円(税込) /回
                      </div>
                    ) : plan.payment_type === PLAN_PAYMENT_TYPE.REGULAR &&
                      plan.price_first === plan.price_after ? (
                      <div className="info-plan__choosing-header-right fs-14">
                        <span className="info-plan__choosing-header-price">
                          {plan.price_first
                            ? formatNumPrice(plan.price_first)
                            : 0}
                        </span>
                        円(税込) /
                        <span className="word-break-keep-all">{`${plan.cycle}日`}</span>
                      </div>
                    ) : (
                      <div className="info-plan__choosing-header-right fs-14 choosing-more p-0">
                        <div className="info-plan__choosing-left position-relative">
                          <img
                            src={iconRectangle}
                            alt=""
                            className="info-plan__choosing-img"
                          />
                          <div className="info-plan__choosing-text">
                            <p className="mb-0">初回限定！</p>
                            <p className="mb-0">割引キャンペーン</p>
                          </div>
                        </div>
                        <div className="info-plan__choosing-right">
                          <p className="mb-0 work-bread-all">
                            <span className="info-plan__choosing-header-price">
                              {plan.price_first
                                ? formatNumPrice(plan.price_first)
                                : 0}
                            </span>
                            円(税込) /
                            <span className="word-break-keep-all">{`${plan.cycle}日`}</span>
                          </p>
                          <p className="info-plan__choosing-header-after">
                            <span className="fs-12">２回目以降 &nbsp;</span>
                            <span className="fs-15">
                              {plan.price_after
                                ? formatNumPrice(plan.price_after)
                                : 0}
                            </span>
                            円
                          </p>
                        </div>
                      </div>
                    )}

                    <ButtonCustom
                      className="btn-default btn-chestnut-rose mt-20 w-50 mgc fw-700"
                      loading={false}
                      disabled={false}
                      onClick={() => handleShowDetail(plan.id)}
                      htmlType="button"
                      name="このプランにする"
                    />
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .info-plan {
    .info-plan__choosing {
      /* display: flex;
      justify-content: space-between;
      align-items: stretch;
      align-self: center;
      overflow: scroll; */
      /* margin: auto; */
      color: #000;
      padding-bottom: 30px;
      margin: 20px 20px 0;
      padding-top: 80px;

      &.pt-150 {
        padding-top: 115px;
      }

      &-content {
        /* min-width: calc(100vw - 45px); */
        /* width: calc(100vw - 60px); */
        width: 100%;
        overflow: hidden;
        /* margin-right: 10px; */
        position: relative;
        font-size: 15px;
        background-color: #fff;
        margin-top: 15px;
        padding-bottom: 20px;

        /* @media (min-width: 767px) {
          width: 360px;
        } */
        &.active {
          border: 4px solid var(--color-chestnut-rose);
        }
      }

      &-title {
        padding: 10px;
      }

      &-header {
        /* min-height: 60px; */
        font-weight: bold;
        display: flex;
        align-items: stretch;
        align-self: center;
        /* justify-content: center; */

        &-right {
          /* background-color: #998800; */
          color: var(--color-rangoon-green);
          padding: 5px;
          font-size: 13px;
          /* white-space: nowrap; */
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: baseline;
          /* position: absolute; */
          bottom: 0;
          width: 100%;
        }

        &-after {
          color: var(--color-rangoon-green);
        }

        &-price {
          font-size: 22px;
          font-weight: 500;
        }
      }

      &-footer {
        background-color: #f1efe7;
        color: #000;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        padding: 5px 1px;
        margin-top: 5px;
        position: absolute;
        bottom: 0;
        width: 100%;

        span {
          font-size: 12px;
          font-weight: normal;
        }
      }

      &-text {
        padding: 5px;
      }

      &-plan {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        flex-wrap: nowrap;
        align-self: stretch;
      }

      &-left {
        /* background-color: #cfc392; */
        /* padding: 5px; */
        color: #000;
        font-weight: bold;
        /* width: 30%; */
        height: 100%;
      }

      &-right {
        /* width: 100%; */
        text-align: center;
        color: #e01616;
        text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff,
          1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff;
        background-image: url(${basePriceImg});
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: 50% 10px;
      }

      .choosing-more {
        padding: 0;
        justify-content: center;
        align-items: center;
        gap: 10px;
      }

      &-text {
        color: var(--color-chestnut-rose);
        right: 25px;
        top: 50%;
        transform: translate(0, -50%);
        position: absolute;
      }

      &-img {
        height: 60px;
        position: relative;
      }
    }

    &__img {
      /* min-height: 65%; */
      /* margin-bottom: 100px; */
      width: 100%;

      img {
        width: 100%;
      }
    }

    .word-break-keep-all {
      word-break: keep-all;
    }
  }
`

export default PlanInfoComponent

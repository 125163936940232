import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ParamInterface } from 'src/interfaces/paramUrl'

type State = {
  loading: boolean
  entity: ParamInterface | null
}

const initialState: State = {
  loading: false,
  entity: null,
}

const { actions, reducer } = createSlice({
  name: 'get_param_url',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<ParamInterface>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

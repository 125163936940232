import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuNameComponent from 'src/components/common/MenuNameComponent'
import uiActions from 'src/store/ui/notification'
import ButtonCustom from 'src/components/form/Button'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { PATH } from 'src/pages/automaticPlan'
import { useAppDispatch } from 'src/store'
import {
  getPresignedUrl,
  uploadByPresignedUrl,
  uploadUserTreatmentImage,
} from 'src/useCase/userTreatment'
import styled from 'styled-components'
import { ImageUpload } from './Components/ImageUpload'
import { VideoUpload } from './Components/VideoUpload'
import imageDemo1 from 'src/static/img/image-demo-1.svg'
import imageDemo2 from 'src/static/img/image-demo-2.svg'
import imageDemo3 from 'src/static/img/image-demo-3.svg'
import imageDemo4 from 'src/static/img/image-demo-4.svg'
import imageDemo5 from 'src/static/img/image-demo-5.svg'
import imageDemo6 from 'src/static/img/image-demo-6.svg'
import imageDemo7 from 'src/static/img/image-demo-7.svg'
import imageDemo8 from 'src/static/img/image-demo-8.svg'
import imageDemo9 from 'src/static/img/image-demo-9.svg'

export const MediaUploadContainer: React.FC = (props: any) => {
  const [image1, setImage1] = useState<File>()
  const [image2, setImage2] = useState<File>()
  const [image3, setImage3] = useState<File>()
  const [image4, setImage4] = useState<File>()
  const [image5, setImage5] = useState<File>()
  const [image6, setImage6] = useState<File>()
  const [image7, setImage7] = useState<File>()
  const [image8, setImage8] = useState<File>()
  const [image9, setImage9] = useState<File>()

  const [video, setVideo] = useState<File>()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const uploadMedia = async () => {
    try {
      if (!video) return

      const { payload } = await dispatch(getPresignedUrl(video.name))

      await dispatch(
        uploadByPresignedUrl({ presigned_url: payload.presigned_url, video }),
      )

      await dispatch(
        uploadUserTreatmentImage({
          images: [
            image1,
            image2,
            image3,
            image4,
            image5,
            image6,
            image7,
            image8,
            image9,
          ],
          video_path: payload.file_path,
        }),
      ).unwrap()

      navigate(PATH.ADDRESS_ENTRY)
    } catch (error) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: '画像・動画アップロードが失敗しました。',
        }),
      )
      return
    }
  }
  const back = () => {
    navigate(-1)
  }

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form">
        <StepsHeader
          step={4}
          stepActive={2}
          stepActiveName={'問診・注意事項確認'}
          className="top-70"
        />
        <HeaderStyle>画像・動画アップロード</HeaderStyle>
        <MenuNameComponent className="mb-15 fw-500" />

        <p>
          口内確認のため、画像を9枚と動画を１つアップロードしてください。画像は参考画像のように撮影をしてください。
        </p>

        <p>
          口腔内の撮り方について､
          <br />
          以下のページを参考にして撮影をお願いします｡
        </p>

        <a
          href="https://flannel-handstand-89b.notion.site/6a4b717c71834bf8a84080f3de740d59"
          target="_blank"
          rel="noreferrer"
          className="btn-guide"
        >
          口腔内写真の撮影ガイド
        </a>

        <ImageUpload
          title="画像１"
          setMedia={setImage1}
          defaultImage={imageDemo1}
        />
        <ImageUpload
          title="画像２"
          setMedia={setImage2}
          defaultImage={imageDemo2}
        />
        <ImageUpload
          title="画像３"
          setMedia={setImage3}
          defaultImage={imageDemo3}
        />
        <ImageUpload
          title="画像４"
          setMedia={setImage4}
          defaultImage={imageDemo4}
        />
        <ImageUpload
          title="画像５"
          setMedia={setImage5}
          defaultImage={imageDemo5}
        />
        <ImageUpload
          title="画像６"
          setMedia={setImage6}
          defaultImage={imageDemo6}
        />
        <ImageUpload
          title="画像７"
          setMedia={setImage7}
          defaultImage={imageDemo7}
        />
        <ImageUpload
          title="画像８"
          setMedia={setImage8}
          defaultImage={imageDemo8}
        />
        <ImageUpload
          title="画像９"
          setMedia={setImage9}
          defaultImage={imageDemo9}
        />

        <VideoUpload title="動画" setMedia={setVideo} />

        <ButtonCustom
          className="btn-default btn-white mb-30"
          loading={false}
          onClick={uploadMedia}
          disabled={
            !image1 ||
            !image2 ||
            !image3 ||
            !image4 ||
            !image5 ||
            !image6 ||
            !image7 ||
            !image8 ||
            !image9 ||
            !video
          }
          name="送信する"
        />
        <ButtonCustom
          className="btn-default btn-white mb-30"
          loading={false}
          onClick={back}
          name="戻る"
        />
      </div>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  .upload-card-input {
    margin: 10px 0;
    margin-bottom: 40px;
    border: 1px solid #998800;
    &__text-header {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      padding: 10px;
      background-color: #f5f3e9;
    }
    &__image-demo {
      display: flex;
      justify-content: center;
      color: red;
      &__col {
        width: 50%;
        img {
          margin: auto;
        }
      }
    }
    .update-image {
      display: flex;
      justify-content: center;
      .upload-new {
        border-radius: 100px;
        font-size: 15px;
        color: white;
        cursor: pointer;
        font-weight: bold;
        background: black;
        padding: 15px 40px;
        margin: 15px;
      }
    }
  }
  .btn-skip-aimerte {
    background: white;
    color: black;
    border: solid 1px black;
    margin-top: 30px;
    border-radius: 0;
  }
  .image_upload {
    width: 100%;
    height: 250px;
    &-item {
      width: 100%;
      height: 250px;
      object-fit: contain;
    }
    &-item[src=''] {
      display: none;
    }
  }
  .btn-guide {
    text-align: center;
    display: block;
    color: #7893af;
    font-size: 20px;
    text-decoration: underline;
    line-height: 30px;
    margin-bottom: 40px;
    margin-top: 30px;
  }
`

import React, { Fragment } from 'react'
import { createPortal } from 'react-dom'
import { Modal } from 'antd'
import { ModalStyle } from './ModalStyle'

const BasicModal = (props) => {
  const handleClose = () => {
    props.onClose && props.onClose()
  }

  const handleOnSubmit = () => {
    props.onSubmit && props.onSubmit()
  }

  const portalDiv = document.getElementById('root')!
  const labelSubmit = props.labelSubmit ? props.labelSubmit : '提出する'

  return (
    props.isOpen &&
    document.getElementById('root') &&
    createPortal(
      <Modal
        open={props.isOpen}
        centered
        onOk={handleOnSubmit}
        onCancel={handleClose}
        closable={false}
        footer={[
          <Fragment key={Math.random()}>
            <button
              className="btn-close btn-text-aimerte border-none bg-white btn-modal border-right"
              onClick={handleClose}
            >
              キャンセル
            </button>
            <button
              className="btn-ok btn-text-aimerte border-none bg-white btn-modal font-weight-bold border-left"
              onClick={handleOnSubmit}
            >
              {labelSubmit}
            </button>
          </Fragment>,
        ]}
        className="basic-modal modal-confirm-btn"
      >
        <ModalStyle>{props.children}</ModalStyle>
      </Modal>,
      portalDiv,
    )
  )
}

export default BasicModal

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { find, get } from 'lodash'
import { Skeleton } from 'antd'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import PlanInfoComponent from '../components/Plan/PlanInfo'
import { getPlans } from 'src/useCase/plan'
import { getFirstDoctor } from 'src/useCase/doctor'
import { PlanInterface } from 'src/interfaces/plan'
import LocalStorage from 'src/utils/LocalStorage'
import { Wrapper } from 'src/components/layout/Wrapper'
import { PLAN_TYPE } from 'src/constants/app'
import { EMAIL_SUPPORT } from 'src/constants/env-constant'
import MenuNameComponent from 'src/components/common/MenuNameComponent'

const ProposalContainer: React.FC = (props: any) => {
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const userTreatmentId = LocalStorage.getUserTreatmentId()
    dispatch(
      getPlans({
        auto_suggestion_status: true,
        user_treatment_id: Number(userTreatmentId),
      }),
    )
    dispatch(getFirstDoctor())
  }, [])
  const plans = useAppSelector((state) => state.domain.plans.plans.entity)
  const plansLoading = useAppSelector(
    (state) => state.domain.plans.plans.loading,
  )
  // const orderLoading = useAppSelector(
  //   (state) => state.domain.plans.plans.loading,
  // )
  const firstDoctor = useAppSelector(
    (state) => state.domain.doctor.firstDoctor.entity,
  )
  const user = useAppSelector((state) => state.ui.app.authInfo)

  const [activePlan, setActivePlan] = useState<PlanInterface>()

  useEffect(() => {
    const activePlanLocal = LocalStorage.activePlan
    const defaultActivePlan =
      plans && find(plans, (plan) => plan && plan.id === activePlanLocal)
    if (defaultActivePlan) {
      setActivePlan(defaultActivePlan)
    }
  }, [plans])

  const selectPlan = (plan) => {
    if (plan) {
      setActivePlan(plan)
    }
  }

  // const goToDetail = (id: number) => {
  //   LocalStorage.setActivePlan(id)
  //   LocalStorage.setPlanType(PLAN_TYPE.EXAMINATION)
  //   navigate(`/plans/${id}`)
  // }
  // const handleSubmit = () => {
  //   if (activePlan) {
  //     LocalStorage.setActivePlan(activePlan.id)
  //     LocalStorage.setPlanType(PLAN_TYPE.EXAMINATION)
  //     // navigate(PAYMENT_PATH.PAYMENT_CONFIRM)

  //     dispatch(
  //       orderPlan({
  //         plan_id: activePlan.id,
  //         plan_type: PLAN_TYPE.EXAMINATION,
  //         redirect_path: PAYMENT_PATH.PAYMENT_INFO,
  //       }),
  //     )
  //   }
  // }

  if (!plans) {
    return <div></div>
  }

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto proposal">
        <StepsHeader
          step={10}
          stepActive={4}
          stepActiveName={'処方のご提案'}
          className="top-70"
        />
        {plansLoading ? (
          <Wrapper className="mx--20">
            <Skeleton />
          </Wrapper>
        ) : (
          <ProposalDiv className="proposal">
            <div className="proposal-content">
              <HeaderStyle>処方提案</HeaderStyle>
              <MenuNameComponent />
              <div className="proposal__content">
                <p>
                  {user && get(user, 'clinics.0.name')}
                  担当医師の{firstDoctor?.name}です。
                </p>
                <br />
                <p>問診内容を踏まえて、以下の処方プランをご提案いたします。</p>

                {/* <br />
                <p>
                  AGA治療は効果が出るまでに、少なくても3~6ヶ月かかるため、診察の手間を最小限に抑えて治療を継続しやすい定期便プランにてお届けいたします。
                </p> */}
              </div>
            </div>
            <div className="proposal__content-badge">
              <div className="proposal__content-badge-header">
                <p className="proposal__content-badge-title">
                  ご提案の処方プラン
                </p>
                <p></p>
                <p>以下の処方プランからお選びください</p>
              </div>
              <PlanInfoComponent
                plans={plans}
                activePlan={activePlan}
                selectPlan={selectPlan}
                planTypeBefore={PLAN_TYPE.EXAMINATION}
              />

              <div className="pt-0 p-20">
                <p>定期便は定期の期間が終了した後に再問診が必要となります。</p>
                <p>
                  お体に合わない等、ご不明な点がございましたらメールにてカスタマーサポートへご相談ください。
                </p>
                <p>{EMAIL_SUPPORT}</p>
              </div>
            </div>
            {/* <div className="proposal-content mt-4">
              <p>定期便は12ヶ月毎に再問診が必要となります。</p>
              <p>
                お体に合わない等、ご不明な点がございましたらチャットにてカスタマーサポートへご相談ください。
              </p>

              {activePlan && activePlan.id && (
                <div className="proposal__content-noti">
                  <p className="proposal__content-noti-header">
                    {activePlan.name}
                  </p>
                  <p>の処方プランを選択中です。</p>
                  <div
                    className="proposal__btn"
                    onClick={() => goToDetail(activePlan.id)}
                  >
                    プランの詳細はこちら
                  </div>
                </div>
              )}

              <ButtonCustom
                className="btn-default btn-chestnut-rose my--30"
                loading={orderLoading}
                disabled={!activePlan}
                onClick={handleSubmit}
                name="決済へ"
              />
            </div> */}
          </ProposalDiv>
        )}
      </div>
    </>
  )
}

export const ProposalDiv = styled.div`
  padding: 0 !important;
  margin-top: 60px;

  &.proposal-subs {
    margin-top: -20px;
  }

  .proposal-content {
    padding: 1px 20px 0 20px;
  }

  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    letter-spacing: 0.13em;
    color: var(--color-rangoon-green);
    margin-top: 1.25rem;
  }

  /* submit */
  .proposal-submit {
    margin-top: 60px;
    width: 100%;
    padding: 19px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.13em;
    background: var(--color-rangoon-green);
    margin-bottom: 55px;
    margin-top: 40px;
  }

  .proposal__content {
    padding: 20px 0;

    &-badge {
      background-color: var(--color-spring-wood);
      color: var(--color-rangoon-green);
      position: relative;
      min-height: 200px;

      &-header {
        background-color: var(--color-stark-white);
        padding: 20px 20px 65px 20px;
        position: absolute;
        width: 100%;
        font-size: 13px;
      }

      &-title {
        font-weight: bold;
        font-size: 18px;
      }
    }

    &-noti {
      background-color: #f1efe7;
      border: 1px solid #cfc392;
      text-align: center;
      padding: 15px;
      margin: 20px 0;
      font-size: 15px;

      &-header {
        font-weight: bold;
        color: #998800;
      }
    }
  }

  .proposal-submit:disabled {
    opacity: 0.5;
    cursor: default;
  }

  .proposal__btn {
    text-align: center;
    background-color: #998800;
    color: #fff;
    padding: 6px;
    margin-top: 10px;
    width: 60%;
    margin: 10px auto;
    font-weight: bold;
  }
`

export default ProposalContainer

import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { OTP_REGEX } from 'src/utils/regex'
import { verifyOTPRequestInterface } from 'src/interfaces/address'
import { verifyOTP } from 'src/useCase/address'
import { useAppDispatch } from 'src/store'

export const useSMSForm = (planType) => {
  const dispatch = useAppDispatch()

  const defaultValues: verifyOTPRequestInterface = {
    otp: '',
  }

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required('入力してください。')
      .matches(OTP_REGEX, 'コードは半角数字6桁で入力してください'),
  })

  const onSubmit = async (data: verifyOTPRequestInterface) => {
    await dispatch(
      verifyOTP({ credentials: data, meta: form, planType: planType }),
    )
  }

  const form = useForm<verifyOTPRequestInterface>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

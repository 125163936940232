import axios from 'axios'
import { FormValues } from 'src/containers/MedicalSpecialty/components/useMedicalSpecialtyForm'
import { store } from 'src/store'
import HTTPRequest from '../index'

const apiPath = '/api/v2/users/user-treatments'

class UserTreatment extends HTTPRequest {
  index(params) {
    return this.get(`${apiPath}`, { params })
  }

  create(value: FormValues) {
    return this.post(`${apiPath}`, { ...value })
  }

  show(id, params) {
    return this.get(`${apiPath}/${id}`, { params })
  }

  getPresignedUrl(filename: string) {
    return this.get(`api/v2/users/s3/get-presigned-url`, {
      params: { filename },
    })
  }

  uploadByPresignedUrl(presigned_url, video) {
    store.dispatch({ type: 'app/updateLoading', payload: true })

    return axios.put(presigned_url, video, {
      headers: {
        'Content-Type': video.type,
        'Content-Disposition': `attachment`,
      },
    })
  }

  uploadUserTreatmentImage(userTreatmentId: string, { images, video_path }) {
    const formData = new FormData()

    for (const image of images) {
      formData.append('images[]', image)
    }
    formData.append('video_path', video_path)

    return this.post(
      `api/v2/users/user-treatments/${userTreatmentId}/images`,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )
  }
}

export default new UserTreatment()

import { LocalStorage } from 'src/utils/LocalStorage'
import { defaultUserInfo } from './../interfaces/user.d'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from 'lodash'

import automaticPlanRequest from 'src/api/requests/automaticPlan'
import { FormValues as FormValuesIdentification } from 'src/containers/AutomaticPlan/Identification/Form/useIdentification'
import { FormValues as FormValuesUploadImage } from 'src/containers/AutomaticPlan/UploadImage/Form/useUploadImage'
import { PATH as PATH_COMPLETE_IDENTIFICATION } from 'src/pages/automaticPlan/CompleteIdentification'
import { PATH as PRECAUTION_PATH } from 'src/pages/automaticPlan/Precaution'
import uiActions from 'src/store/ui/notification'
import history from 'src/libs/history'
import appActions from 'src/store/ui/app'
import { UserInterface } from 'src/interfaces/user'
import userRequest from 'src/api/requests/user'
export interface payloadUpdateIdentityCard {
  data: FormValuesIdentification
}

export interface payloadUploadHeadImage {
  data: FormValuesUploadImage
}

export const updateIdentityCard = createAsyncThunk<
  any,
  payloadUpdateIdentityCard
>('update-identity-card', async ({ data }, { dispatch }) => {
  try {
    const userTreatmentId = LocalStorage.getUserTreatmentId()
    await automaticPlanRequest.updateIdentityCard({
      ...data,
      ...{ user_treatment_id: userTreatmentId },
    })
    const res = await userRequest.userInfo({
      with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
    })

    dispatch(appActions.updateAuthInfo(res.data))
    history.push(PATH_COMPLETE_IDENTIFICATION)
  } catch (e) {
    dispatch(
      uiActions.onNotification({
        type: 'error',
        message: '問題が発生したため、再度アップロードしてください。',
      }),
    )
  }
})
export const uploadHeadImage = createAsyncThunk<any, payloadUploadHeadImage>(
  'upload_head_image',
  async ({ data }, { dispatch, getState }) => {
    try {
      const res = await automaticPlanRequest.uploadHeadImage(data)
      const headImage = res && res.data?.head_image
      if (headImage) {
        const state = getState()

        const user: UserInterface = get(
          state,
          'ui.app.authInfo',
          defaultUserInfo,
        )
        const newUser: UserInterface = { ...user, head_image: headImage }
        if (newUser) {
          dispatch(appActions.updateAuthInfo(newUser))
        }
      }

      history.push(PRECAUTION_PATH)
    } catch (e) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: '問題が発生したため、再度アップロードしてください。',
        }),
      )
    }
  },
)

import React from 'react'

import OtherMenu from 'src/containers/MyPage/OtherMenu'

const OtherMenuPage: React.FC = () => {
  return <OtherMenu />
}

export const PATH = '/my-page/other-menu' as const
export default OtherMenuPage

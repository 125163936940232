import React from 'react'

import { MediaUploadContainer } from 'src/containers/AutomaticPlan/MediaUpload'

const MediaUploadPage: React.FC = () => {
  return <MediaUploadContainer />
}

export const PATH = '/automatic-plan/media-upload' as const
export default MediaUploadPage

import React from 'react'

import PurchaseHistory from 'src/containers/MyPage/PurchaseHistory'

const PurchaseHistoryPage: React.FC = () => {
  return <PurchaseHistory />
}

export const PATH = '/my-page/purchase-histories' as const
export default PurchaseHistoryPage

import HTTPRequest from 'src/api/index'
import { PayloadSkipOrderTracking } from 'src/useCase/orderTracking'

const basicURL = 'api/users'

class OrderTracking extends HTTPRequest {
  skipOrderTracking(params: PayloadSkipOrderTracking) {
    return this.post<any>(`${basicURL}/skip-delivery`, params)
  }
}

export default new OrderTracking()

import React from 'react'
import { useNavigate } from 'react-router-dom'
import ButtonCustom from 'src/components/form/Button'
import DescriptionHint from 'src/components/layout/Content/DescriptionHint'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { PATH } from 'src/pages/video'

const RegistrationCompleteContainer: React.FC = (props: any) => {
  const navigate = useNavigate()

  const handleMyPage = () => {
    navigate(PATH.VIDEO_SCHEDULE)
  }
  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form not-padding-p">
        <StepsHeader
          step={4}
          stepActive={4}
          stepActiveName="会員登録"
          className="top-70"
        />
        <HeaderStyle>会員登録完了</HeaderStyle>
        <DescriptionHint
          listText={[
            'ご利用ありがとうございます。',
            '<br />',
            '問診内容を医師が確認いたします｡　',
            'お待ちいただいてる間にオンラインカウンセリングのご予約へとお進みください｡　',
          ]}
        />

        <ButtonCustom
          className="btn-default btn-gurkha mt-5 btn-submit"
          loading={false}
          onClick={handleMyPage}
          name="オンラインカウンセリング予約へ"
        />

        {/* <LineBox /> */}
      </div>
    </>
  )
}

export default RegistrationCompleteContainer

import styled from 'styled-components'
import { Form } from 'antd'

export const StyleForm = styled(Form)`
  padding-bottom: 20px;
`

export const QuestionItem = styled.div`
  .q-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 15px;
    font-size: 14px;
    word-break: break-all;
    margin-bottom: 1.5rem;
    font-weight: 500;
    border-bottom: 1px solid var(--color-rangoon-green);
    padding-bottom: 5px;
  }

  .q-required {
    color: #ff0000;
    font-weight: 700;
    font-size: 13px;
  }

  .q-wrap {
    display: block;
    text-align: center;
    font-weight: 500;
    padding: 15px 10px 1.5rem 10px;
  }

  .btn-q {
    display: none;
  }

  .label-q {
    background-color: #fff;
    color: #000;
    font-size: 12px;
    width: 100%;
    text-align: center;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px 0;
    border-radius: 0.25rem;
    margin-bottom: 10px;

    &.inactive {
      pointer-events: none;
    }

    .inactive {
      background-color: #ccc;
      border-color: #999;
      color: #eee;
      opacity: 1;
      cursor: default;
      pointer-events: none;

      &:hover {
        background-color: #ccc !important;
      }
    }

    @media (min-width: 768px) {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  input.btn-q + span {
    background-color: var(--color-spring-wood);
    /* border: 1px solid #000; */
    color: #000;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin: 0 5px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px !important;
    border-radius: 0.25rem;
  }

  input.btn-q:checked + span {
    background-color: var(--color-yuma);
    color: #fff;
    border-color: #fff;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin: 0 5px;
    height: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px !important;
    border-radius: 0.25rem;
  }

  .question-input {
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 5px;

    &.inactive {
      background: #ccc;
      border: 1px solid #000;
      opacity: 1;
      cursor: none;

      &:hover {
        background-color: #ccc !important;
      }
    }
  }
`

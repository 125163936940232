import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Col, Form, Row } from 'antd'
import { Link, useLocation } from 'react-router-dom'

import { FormValues, useLogin } from './useLogin'
import { InputField } from 'src/components/form'
import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'
import logo from 'src/assets/images/landing/logo.svg'
import localStorage from 'src/utils/LocalStorage'
import history from 'src/libs/history'
import { PATH as SEND_EMAIL_RESET_PASSWORD_PATH } from 'src/pages/auth/SendMailResetPassword'
import { PATH as PATH_HOME } from 'src/pages/homeFlow'

const Container: React.FC = () => {
  const { form, onSubmit } = useLogin()
  const { control, handleSubmit, formState } = form
  const location = useLocation()

  useEffect(() => {
    if (localStorage.accessToken) {
      history.push(PATH_HOME.FLOW)
    }
  }, [location])

  return (
    <Wrapper>
      <LPHeader />
      <HeaderStyle className="logo page-title">
        <img src={logo} alt="" />
      </HeaderStyle>
      <StyleForm onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col xs={24}>
            <label>メールアドレス</label>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'email',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'email',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <label>パスワード</label>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'password',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'password',
              }}
            />
          </Col>
        </Row>
        {/* <StyleButton
          type="primary"
          htmlType="submit"
          block
          loading={formState.isSubmitting}

        >
          login
        </StyleButton> */}

        <ButtonCustom
          className="btn-default btn-gurkha mt-35"
          loading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
          htmlType="submit"
          showIconRight
          name="ログインする"
        />
        <Link className="link" to={SEND_EMAIL_RESET_PASSWORD_PATH}>
          パスワードを忘れた方はこちら
        </Link>
      </StyleForm>
      {/* <Notification></Notification> */}
    </Wrapper>
  )
}

const StyleForm = styled(Form)`
  padding: 0 20px 30px 20px;
  & input {
    padding: 15px !important;
  }
  & .link {
    display: block;
    width: 100%;
    color: #000000;
    text-align: center;
    text-decoration: underline;
    margin-top: 50px;
  }
  & .btn-submit {
    font-size: 18px;
  }
  & label {
    font-size: 15px !important;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }
`
const Wrapper = styled.div`
  .logo {
    display: block;
    margin: 80px auto;
    text-align: center;
  }
`

const HeaderStyle = styled.div`
  h5 {
    color: var(--color-kelp);
  }

  img {
    height: 45px;
  }
`

export default Container

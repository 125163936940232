import React from 'react'
import styled from 'styled-components'
import { range } from 'lodash'

type Props = {
  step: number
  stepActive: number
  stepActiveName: string
  className?: string
}

const StepsHeader = (props: Props) => {
  const { step, stepActive, stepActiveName, className } = props

  return (
    <Wrapper>
      <div className={`step-header-content ${className}`}>
        <div className="step-header-padding">
          <div className="d-flex justify-content-between step-header">
            <div className="step-header-active d-flex justify-content-between">
              <div className="step-header-active-item">
                <div className="active">{stepActive}</div>
              </div>
              <span className="step-name">{stepActiveName}</span>
            </div>
            <div className="step-header-inactive d-flex justify-content-between">
              <div className="round-wrap">
                <svg
                  width="18"
                  height="2"
                  viewBox="0 0 18 2"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="17" cy="1" r="1" fill="#D0CFCB" />
                  <circle cx="9" cy="1" r="1" fill="#D0CFCB" />
                  <circle cx="1" cy="1" r="1" fill="#D0CFCB" />
                </svg>
              </div>
              {step > 2 &&
                range(3, 5).map((stepData) => {
                  return (
                    <div key={stepData} className="round-step">
                      <div className="step-item">{stepData}</div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        <div id="myProgress">
          <div id="myBar" style={{ width: `${stepActive * 25}%` }}></div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .round-step {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: var(--color-white);
    z-index: 2;
    color: var(--color-quill-gray);
    margin-right: 5px;
    border: 1px solid var(--color-quill-gray);

    .step-item {
      color: var(--color-quill-gray);
      font-size: 14px;
      font-weight: 500;
    }
  }

  .round-wrap {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-right: 5px;

    .round-more {
      width: 4px;
      height: 4px;
      background-color: var(--color-quill-gray);
      border-radius: 50%;
      margin-right: 3px;
    }
  }

  .step-header-active-item {
    div {
      width: 25px;
      height: 25px;
      background-color: var(--color-limed-ash);
      border-radius: 25px;
      overflow: hidden;
      color: white;
      text-align: center;
      padding-top: 2px;
      font-size: 14px;
      font-weight: 700;
    }

    .inactive {
      margin-right: 5px;
      background-color: var(--color-yuma);
    }

    //.active {
    //width: 25px;
    //height: 25px;
    //background-color: black;
    //border-radius: 25px;
    //overflow: hidden;
    //color: white;
    //text-align: center;
    //padding-top: 2px;
    //}
  }

  .round-step-container {
    margin: 0 5px;
  }

  .round-step-container:after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    right: 0;
    height: 1em;
    border-top: 1px solid black;
    z-index: 1;
  }

  .step-header-content {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }

  .step-header-padding {
    padding: 10px;
  }

  .step-name {
    padding-top: 2px;
    padding-left: 10px;
    font-weight: 400;
    font-size: 16px;
  }

  #myProgress {
    width: 100%;
    background: var(--color-quill-gray);

    .step2 {
      width: 50%;
    }

    .step3 {
      width: 75%;
    }

    .step4 {
      width: 100%;
    }
  }

  #myBar {
    width: 25%;
    height: 1px;
    background-color: var(--color-lemon-grass);
  }

  .icon-close {
    height: 1rem;
    margin-top: 5px;
  }
`

export default StepsHeader

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'

import LPHeader from 'src/components/layout/Header/LPHeader'
import MenuHeader from 'src/components/layout/Header/MenuHeader'
import ButtonCustom from 'src/components/form/Button'
import { useAppDispatch, useAppSelector } from 'src/store'
import { PATH } from 'src/pages/additionalShampoo'
import {
  PLAN_ID_TO_TYPE,
  PLAN_SHAMPOO_ADDITIONAL_ID,
  PLAN_SHAMPOO_SUBSCRIPTION_ID,
} from 'src/constants/order'
import { orderAdditionShampoo } from 'src/useCase/plan'

const Container: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const order = useAppSelector(
    (state) => state.domain.additionalShampoo.purchase.entity,
  )

  useEffect(() => {
    if (!order) {
      navigate(PATH.PURCHASE_ADDITIONAL_PATH)
    }
  }, [location])

  const handleOrder = () => {
    const planId = order?.planId || 0
    const quantity = order?.quantity || 0
    // hiện tại chỉ shampoo lẻ chỉ có id = 4 còn tất cả các plan còn lại sẽ là shamppo định kỳ
    // vì ở page admin có thể thêm được plan định kỳ nên sẽ set id =! 4 sẽ có type là buy_shampoo_subscription
    const typeId =
      planId === PLAN_SHAMPOO_ADDITIONAL_ID
        ? planId
        : PLAN_SHAMPOO_SUBSCRIPTION_ID

    const planType = PLAN_ID_TO_TYPE[typeId]
    const data = { plan_id: planId, plan_type: planType, quantity: quantity }

    dispatch(orderAdditionShampoo({ data }))
  }

  return (
    <Wrapper>
      <LPHeader />
      <MenuHeader name="シャンプー追加購入手続き" />
      <div className="w-full justify-center layout-webview">
        <div className="delivery-confirm ">
          <div className="title">ご購入内容確認</div>
          <div className="section">
            <div className="sec-title">プラン内容</div>
            <div className="sec-body">
              <div className="d-flex justify-content-between content">
                <span>商品名</span>
                <strong>{order?.name || ''}</strong>
              </div>
              <div className="d-flex justify-content-between content">
                <span>金額</span>
                <strong>
                  {order?.price
                    ? (order.price * order.quantity).toLocaleString()
                    : 0}
                  円
                </strong>
              </div>
            </div>
          </div>
          <div className="section">
            <div className="sec-title">ご住所情報</div>
            <div className="sec-body">
              <div className="d-flex justify-content-between content">
                <span>氏名</span>
                <strong>{`${user?.family_name || ''} ${
                  user?.given_name || ''
                }`}</strong>
              </div>
              <div className="d-flex justify-content-between content">
                <span>郵便番号</span>
                <strong>{user?.postal_number || ''}</strong>
              </div>
              <div className="d-flex justify-content-between content">
                <span>都道府県</span>
                <strong>{user?.prefecture_id || ''}</strong>
              </div>
              <div className="d-flex justify-content-between content">
                <span>市区町村・番地</span>
                <strong>
                  {user?.city_id || ''} {user?.address || ''}
                </strong>
              </div>
              <div className="d-flex justify-content-between content">
                <span>建物名・部屋番号</span>
                <strong>{user?.building_name || ''}</strong>
              </div>
              <div className="d-flex justify-content-between content">
                <span>電話番号</span>
                <strong>{user?.phone || ''}</strong>
              </div>
              <div className="d-flex justify-content-between content">
                <span>メールアドレス</span>
                <strong>{user?.email || ''}</strong>
              </div>
            </div>
          </div>
        </div>
        <ButtonCustom
          loading={false}
          name="購入する"
          className="btn-default btn-black mt-30"
          onClick={handleOrder}
        />
        <ButtonCustom
          className="btn-default btn-white mt-3 mb-30"
          loading={false}
          onClick={() => navigate(PATH.PURCHASE_ADDITIONAL_PATH)}
          name="戻る"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .delivery-confirm {
    margin-left: -20px;
    margin-right: -20px;
    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.13em;
      color: #000000;
      margin-top: 1.25rem;
      padding-left: 20px;
      margin-bottom: 20px;
    }

    .content {
      font-style: normal;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 15px;
      border-bottom: 1px solid #f1efe7;
      padding-bottom: 15px;
    }

    /* submit */
    &-submit {
      width: 100%;
      padding: 19px;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #000000;
      margin-bottom: 55px;
    }

    &-cancel {
      margin-top: 40px;
      margin-bottom: 40px;
      width: 100%;
      padding: 19px;
      color: #000000;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #ffffff;
      border: 1px solid #000000;
    }

    &.shampoo {
      padding-left: 0;
      padding-right: 0;
    }

    .section {
      margin-top: 10px;

      .sec-title {
        background: #f1efe7;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        padding: 10px 20px;
      }

      .sec-body {
        margin-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
`

export default Container

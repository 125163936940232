import React from 'react'

import AgaResultContainer from 'src/containers/Aga/Result'

const AgaResult: React.FC = () => {
  return <AgaResultContainer />
}

export const PATH = '/aga/result' as const
export default AgaResult

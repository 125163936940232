import React from 'react'

import ProposalContainer from 'src/containers/AutomaticPlan/Proposal'

const ProposalPage: React.FC = () => {
  return <ProposalContainer />
}

export const PATH = '/automatic-plan/proposal' as const
export default ProposalPage

import SignIn, { PATH as SIGN_IN } from './SignIn'
import Register, { PATH as REGISTER } from './Register'
import SendMailResetPassword, {
  PATH as SEND_MAIL_RESET_PASSWORD,
} from './SendMailResetPassword'
import ResetPassword, { PATH as RESET_PASSWORD_PATH } from './ResetPassword'
import ResetPasswordComplete, {
  PATH as COMPLETE_PAGE,
} from './ResetPasswordComplete'
import RegistrationComplete, {
  PATH as REGISTRATION_COMPLETE,
} from './RegistrationComplete'
import LinkLine, { PATH as LINK_LINE } from './LinkLine'
import MedicalSpecialty, { PATH as MEDICAL_SPECIALTY } from './MedicalSpecialty'
import Registered, { PATH as REGISTERED } from './Registered'

export default {
  SignIn,
  Register,
  SendMailResetPassword,
  ResetPassword,
  ResetPasswordComplete,
  RegistrationComplete,
  LinkLine,
  MedicalSpecialty,
  Registered,
}
export const PATH = {
  SIGN_IN,
  REGISTER,
  SEND_MAIL_RESET_PASSWORD,
  RESET_PASSWORD_PATH,
  COMPLETE_PAGE,
  REGISTRATION_COMPLETE,
  LINK_LINE,
  MEDICAL_SPECIALTY,
  REGISTERED,
}

import React from 'react'

import ScheduleContainer from 'src/containers/Video/Schedule'

const schedule: React.FC = () => {
  return <ScheduleContainer />
}

export const PATH = '/video/schedule' as const
export default schedule

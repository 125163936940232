import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserTreatmentInterface } from 'src/interfaces/userTreatment'

type State = {
  loading: boolean
  draffLoading: boolean
  entities: UserTreatmentInterface[] | []
}

const initialState: State = {
  loading: false,
  entities: [],
  draffLoading: false,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'user_treatments',
  initialState,
  reducers: {
    updateEntities: (
      state,
      action: PayloadAction<UserTreatmentInterface[]>,
    ) => {
      state.entities = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

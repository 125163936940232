import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { STORAGE_KEY } from 'src/constants/app'
import history from 'src/libs/history'
import { PATH } from 'src/pages/video/TemporaryReserveConfirm'

export type FormValues = {
  date?: string
  time?: string | null
}

export const useSchedule = () => {
  const defaultValues: FormValues = {
    date: '',
    time: '',
  }

  const validationSchema = Yup.object().shape({
    date: Yup.string().required('日付を選択してください。'),
    time: Yup.string().required('時間を選択してください。').nullable(),
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    localStorage.setItem(STORAGE_KEY.VIDEO_SCHEDULE, JSON.stringify(data))
    history.push(PATH)
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

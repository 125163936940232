import React from 'react'

import FlowContainer from 'src/containers/HomeFlow'

const FlowPage: React.FC = () => {
  return <FlowContainer />
}

export const PATH = '/clinics/:clinic_uuid' as const
export default FlowPage

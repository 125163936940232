import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Form, Row } from 'antd'

import { useUpdatePassword, FormValues } from './useDeliveryDestinationInfo'
import { InputField } from 'src/components/form'
import ButtonCustom from 'src/components/form/Button'
import LPHeader from 'src/components/layout/Header/LPHeader'
import {
  InputLabel,
  LabelRequire,
} from 'src/components/layout/Header/HeaderStyle'
import { useAppDispatch, useAppSelector } from 'src/store'
import { getAddressByPostalCode } from 'src/useCase/address'
import { useNavigate } from 'react-router-dom'
import { PATH as USER_DETAIL_PATH } from 'src/pages/myPage/UserDetail'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'

const Container: React.FC = () => {
  const { form, onSubmit } = useUpdatePassword()
  const { control, handleSubmit, formState } = form
  const [isPostalCode, setPostalCode] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const postalCodeLoading = useAppSelector(
    (state) => state.domain.addressByPostalCode.addressByPostalCode.loading,
  )

  const addressByPostalCode = useAppSelector(
    (state) => state.domain.addressByPostalCode.addressByPostalCode.entity,
  )
  const user = useAppSelector((state) => state.ui.app.authInfo)

  const setFieldValue = (
    name:
      | 'postal_number'
      | 'prefecture_id'
      | 'city_id'
      | 'address'
      | 'building_name'
      | 'phone'
      | 'email',
    value: any,
  ) => {
    form.setValue(name, value)
    form.clearErrors(name)
  }

  const handleGetAddress = () => {
    setPostalCode(true)
    dispatch(
      getAddressByPostalCode({
        postal_code: form.watch('postal_number') || '',
      }),
    )
  }

  useEffect(() => {
    if (isPostalCode) {
      form.setValue('prefecture_id', addressByPostalCode?.address1 || '')
      form.clearErrors('prefecture_id')
      form.setValue('city_id', addressByPostalCode?.address2 || '')
      form.clearErrors('city_id')
      form.setValue('address', addressByPostalCode?.address3 || '')
      form.clearErrors('address')
    }
  }, [addressByPostalCode, isPostalCode])

  useEffect(() => {
    setFieldValue('postal_number', user?.postal_number || '')
    setFieldValue('prefecture_id', user?.prefecture_id || '')
    setFieldValue('city_id', user?.city_id || '')
    setFieldValue('address', user?.address || '')
    setFieldValue('building_name', user?.building_name || '')
    setFieldValue('phone', user?.phone || '')
    setFieldValue('email', user?.email || '')
  }, [user])

  return (
    <Wrapper>
      <LPHeader />

      <StyleForm>
        <HeaderUnderlined name="ご住所設定" />
        <Row className="pt-25">
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel className="font-weight-bold">郵便番号</InputLabel>
            <LabelRequire>
              <span>必須</span>
            </LabelRequire>
          </Col>
          <Row className="d-flex justify-content-between w-100">
            <Col xs={15}>
              <InputField<FormValues>
                controller={{
                  control,
                  name: 'postal_number',
                }}
                inputProps={{
                  placeholder: '入力してください',
                  minLength: 7,
                  maxLength: 7,
                }}
              />
            </Col>
            <Col xs={8}>
              <ButtonCustom
                className="btn-default btn-black btn-postal"
                loading={postalCodeLoading}
                disabled={!form.watch('postal_number')}
                onClick={handleGetAddress}
                htmlType="button"
                name="自動入力"
              />
            </Col>
          </Row>
        </Row>
        <Row>
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel className="font-weight-bold">都道府県</InputLabel>
            <LabelRequire>
              <span>必須</span>
            </LabelRequire>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'prefecture_id',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel className="font-weight-bold">市区町村・番地</InputLabel>
            <LabelRequire>
              <span>必須</span>
            </LabelRequire>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'city_id',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel className="font-weight-bold">番地以降</InputLabel>
            <LabelRequire>
              <span>必須</span>
            </LabelRequire>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'address',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel className="font-weight-bold">
              {'建物名・部屋番号'}
            </InputLabel>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'building_name',
              }}
              inputProps={{
                placeholder: '入力してください',
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel className="font-weight-bold">電話番号</InputLabel>
            <LabelRequire>
              <span>必須</span>
            </LabelRequire>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'phone',
              }}
              inputProps={{
                placeholder: '入力してください',
                minLength: 10,
                maxLength: 11,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={24} className="d-flex-row d-flex justify-content-between">
            <InputLabel className="font-weight-bold">メールアドレス</InputLabel>
            <LabelRequire>
              <span>必須</span>
            </LabelRequire>
          </Col>
          <Col xs={24}>
            <InputField<FormValues>
              controller={{
                control,
                name: 'email',
              }}
              inputProps={{
                placeholder: '入力してください',
                type: 'email',
              }}
            />
          </Col>
        </Row>

        <ButtonCustom
          className="btn-default btn-chestnut-rose mt-20"
          loading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit)}
          showIconRight
          htmlType="submit"
          name="保存する"
        />

        <ButtonCustom
          className="btn-default btn-white mt-30 mb-50"
          loading={formState.isSubmitting}
          onClick={() => navigate(USER_DETAIL_PATH)}
          name="キャンセル"
        />
      </StyleForm>
    </Wrapper>
  )
}

const StyleForm = styled(Form)`
  padding: 0 20px;

  & input {
    padding: 15px !important;
  }
  & .form-description {
    color: #323232;
    line-height: 24px;
    font-weight: 600;
    font-size: 13px;
  }
  & .btn-submit {
    font-size: 18px;
  }

  & label {
    font-size: 15px !important;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
  }
`
const Wrapper = styled.div``

export default Container

import React, { useEffect } from 'react'

import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { useAppDispatch } from 'src/store'
import { getInfo } from 'src/useCase/user'

import LocalStorage from 'src/utils/LocalStorage'
import ListOrderTracking from './components/ListOrderTracking'

const PaymentConfirmContainer: React.FC = (props: any) => {
  const dispatch = useAppDispatch()

  const planId = LocalStorage.activePlan

  useEffect(() => {
    dispatch(
      getInfo({
        with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
      }),
    )
  }, [])

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto">
        <StepsHeader
          step={10}
          stepActive={4}
          stepActiveName={'処方のご提案'}
          className="top-70"
        />
        <div className="layout-webview mt-70">
          <HeaderStyle>プラン内容確認</HeaderStyle>
        </div>
        <div className="payment-detail">
          <div className="w-full justify-center mx-auto page mb-30">
            <ListOrderTracking planId={Number(planId)} hasImage />
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentConfirmContainer

import React from 'react'
import styled from 'styled-components'

import LPHeader from 'src/components/layout/Header/LPHeader'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import Form from './Form'

const Container: React.FC = () => {
  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto upload-card">
        <StepsHeader
          step={10}
          stepActive={7}
          stepActiveName="本人確認書類アップロード"
          className="top-70"
        />
        <Form />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div``

export default Container

import React from 'react'
import { PlanInterface } from 'src/interfaces/plan'
import { formatNumPrice } from 'src/utils/helper'

type Props = {
  plan: PlanInterface
}

const PlanInfo = ({ plan }: Props) => {
  return (
    <div className="sec-title tracking-info">
      <div className="sec-title tracking">プラン内容</div>
      <div className="sec-body">
        <div className="d-flex justify-content-between content sec-body-border py--20">
          <span>商品名</span>
          <strong>{plan.name}</strong>
        </div>
        <div className="d-flex justify-content-between content py--20">
          <span>金額</span>
          <strong>{formatNumPrice(plan.price_first)}円</strong>
        </div>
      </div>
    </div>
  )
}

export default PlanInfo

import Echo from 'laravel-echo'
import io from 'socket.io-client'

// Utils
import LocalStorage from 'src/utils/LocalStorage'

declare global {
  // eslint-disable-next-line
  interface Window {
    io
    Echo: Echo
  }
}

window.io = io

export function createEchoConnection() {
  const echo = new Echo({
    transports: ['websocket'],
    broadcaster: 'socket.io',
    authModule: true,
    connectOnLogin: true,
    disconnectOnLogout: true,
    host: process.env.REACT_APP_ECHO_URL,
    path: '',
    namespace: '',
    auth: {
      headers: {
        Authorization: `Bearer ${LocalStorage.accessToken}`,
      },
    },
  })
  return echo
}

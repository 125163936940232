import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  entity: Response.optionDoctorFreeTime[]
}

const initialState: State = {
  loading: false,
  entity: [],
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'doctor_free_time',
  initialState,
  reducers: {
    updateEntity: (
      state,
      action: PayloadAction<Response.optionDoctorFreeTime[]>,
    ) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
// import Question from 'src/entities/question'
import { QuestionInterface } from 'src/interfaces/question'

type State = {
  loading: boolean
  entity: QuestionInterface[]
}

const initialState: State = {
  loading: false,
  entity: [],
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'aga_questions',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<QuestionInterface[]>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
})

export { reducer }
export default actions

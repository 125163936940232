import React, { useMemo } from 'react'
import styled from 'styled-components'

// Components
import BreakChatMessage from './BreakChatMessage'
import TheirMessage from './TheirMessage'
import MyMessage from './MyMessage'
// Constants
import { SENDER_BY } from 'src/containers/Chat/constants'

type Props = {
  item: {
    id: number
    type_message: number
    is_show: number
    message_time: string
    image_url: string
    message_content: string
    created_at: string
    sender_by: string
    is_new_date: boolean
    is_temporary: boolean
    attachment_image_url: string
  }
}

const MessageItem = (props: Props) => {
  const { item } = props
  const { sender_by, is_show, is_new_date, created_at } = item

  const isShow = useMemo(() => {
    return is_show !== 2
  }, [is_show])

  const isShowBreakChatMessage = useMemo(() => {
    return isShow && is_new_date
  }, [isShow, is_new_date])

  const isShowTheirMessage = useMemo(() => {
    return isShow && sender_by === SENDER_BY.MANAGER
  }, [isShow, sender_by])

  const isShowMyMessage = useMemo(() => {
    return isShow && sender_by === SENDER_BY.USER
  }, [isShow, sender_by])

  return (
    <Wrapper>
      <li className="message-item">
        {isShowBreakChatMessage && <BreakChatMessage time={created_at} />}
        {isShowTheirMessage && <TheirMessage item={item} />}
        {isShowMyMessage && <MyMessage item={item} />}
      </li>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .message-item {
    padding: 0;
    list-style: none;
  }
`

export default MessageItem

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import AgoraRTC, {
  IAgoraRTCClient,
  ILocalAudioTrack,
  ILocalVideoTrack,
} from 'agora-rtc-sdk-ng'
import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'src/store'
import micOff from 'src/assets/icons/mic_off.svg'
import micOn from 'src/assets/icons/mic_on.svg'
import camOn from 'src/assets/icons/cam_on.svg'
import camOff from 'src/assets/icons/cam_off.svg'
import callEnd from 'src/assets/icons/call_end.svg'
import videoActions from 'src/store/domain/video/videoAppointment'
import { endVideoCall, refreshVideoToken } from 'src/useCase/video'
import LocalStorage from 'src/utils/LocalStorage'
import { PATH as ERROR_PATH } from 'src/pages/error'

type IRtc = {
  localAudioTrack: ILocalAudioTrack | null
  localVideoTrack: ILocalVideoTrack | null
  client: IAgoraRTCClient | null
}
const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const loading = useAppSelector(
    (state) => state.domain.video.videoAppointment.loading,
  )
  const agoraChannel = useAppSelector(
    (state) => state.domain.video.videoAppointment.agoraChannel,
  )
  const videoAppointmentEntity = useAppSelector(
    (state) => state.domain.video.videoAppointment.entity,
  )

  const [micState, setMicState] = useState<Boolean>(true)
  const [camState, setCamState] = useState<Boolean>(true)
  const [remoteAudioState, setRemoteAudioState] = useState<Boolean>(true)
  const [rtc] = useState<IRtc>({
    localAudioTrack: null,
    localVideoTrack: null,
    client: null,
  })

  if (!agoraChannel?.token) {
    const videoToken = LocalStorage.getVideoToken()
    if (videoToken) {
      navigate(`/video/incoming/${videoToken}`)
    } else {
      navigate(ERROR_PATH.STATUS_ERROR)
    }
  }

  useEffect(() => {
    initial().then()
  }, [])

  const initial = async () => {
    let options = {
      // Pass your App ID here.
      appId: agoraChannel?.app_id as string,
      // Set the channel name.
      channel: agoraChannel?.channel_uuid as string,
      // Pass your temp token here.
      token: agoraChannel?.token as string,
      // Set the user ID.
      uid: parseInt(agoraChannel?.uid as string),
    }

    // Check camera and microphone
    const msgCamAndMicError =
      'マイクとカメラへアクセスできません。\n' +
      '端末の設定を確認してください。'
    AgoraRTC.getCameras()
      .then()
      .catch(() => {
        videoActions.updateErrorMsg(msgCamAndMicError)
      })
    AgoraRTC.getMicrophones()
      .then()
      .catch(() => {
        videoActions.updateErrorMsg(msgCamAndMicError)
      })

    // Create an AgoraRTCClient object.
    rtc.client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })

    // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
    rtc.client.on('user-published', async (user, mediaType) => {
      // Subscribe to the remote user when the SDK triggers the "user-published" event
      await rtc?.client?.subscribe(user, mediaType)

      // If the remote user publishes a video track.
      if (mediaType === 'video') {
        if (document.getElementById(String(user.uid))) return

        const rootRemoteContainer = document.getElementById('remote-user')
        const rootLocalContainer = document.getElementById('local-user')

        if (!rootRemoteContainer || !rootLocalContainer) return

        // Get the RemoteVideoTrack object in the AgoraRTCRemoteUser object.
        const remoteVideoTrack = user.videoTrack

        // Dynamically create a container in the form of a DIV element for playing the remote video track.
        // const remotePlayerContainer =
        const remotePlayerContainer: any = document.createElement('div')

        if (!remotePlayerContainer) return

        // Specify the ID of the DIV container. You can use the uid of the remote user.
        remotePlayerContainer.id = String(user.uid)
        remotePlayerContainer.style.width = '100%'

        if (rootRemoteContainer.children.length) {
          remotePlayerContainer.style.height = '20vh'
          rootLocalContainer.append(remotePlayerContainer)
        } else {
          remotePlayerContainer.style.height = '100%'
          rootRemoteContainer.append(remotePlayerContainer)
        }

        // TODO: refactor this code
        remotePlayerContainer.addEventListener('click', function () {
          if (remotePlayerContainer.parentNode.id === 'local-user') {
            const toBeDownsize: any =
              document?.getElementById('remote-user')?.children[0]
            toBeDownsize.style.height = '20vh'
            document?.getElementById('local-user')?.append(toBeDownsize)

            remotePlayerContainer.style.width = '100%'
            remotePlayerContainer.style.height = 'calc(100vh - 12vh)'
            document
              ?.getElementById('remote-user')
              ?.append(remotePlayerContainer)
          }
        })

        // Play the remote video track.
        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the remote video track.
        remoteVideoTrack?.play(remotePlayerContainer)
      }

      // If the remote user publishes an audio track.
      if (mediaType === 'audio') {
        // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
        const remoteAudioTrack = user.audioTrack
        // Play the remote audio track. No need to pass any DOM element.
        remoteAudioTrack?.play()
        setRemoteAudioState(true)
      }

      // Listen for the "user-unpublished" event
      rtc?.client?.on('user-unpublished', (user, mediaType) => {
        if (mediaType === 'audio') {
          setRemoteAudioState(false)
        }

        if (mediaType === 'video') {
          document.getElementById(String(user.uid))?.remove()
        }
      })
    })

    rtc.client.on('user-left', (user) => {
      document.getElementById(String(user.uid))?.remove()
      const rootRemoteContainer =
        document.getElementById('remote-user')?.childNodes
      const localUser: any = document.getElementById('local-user')?.children[1]
      if (rootRemoteContainer?.length === 0 && localUser) {
        const id = localUser.id
        localUser.style.width = '100%'
        localUser.style.height = 'calc(100vh - 12vh)'
        document.getElementById(id)?.remove()
        document.getElementById('remote-user')?.append(localUser)
      }
    })

    // Join an RTC channel.
    await rtc.client.join(
      options.appId,
      options.channel,
      options.token,
      options.uid,
    )
    // Create a local audio track from the audio sampled by a microphone.
    rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack()
    // Create a local video track from the video captured by a camera.
    rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack()
    // Publish the local audio and video tracks to the RTC channel.
    await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack])
    // Dynamically create a container in the form of a DIV element for playing the local video track.

    const rootLocalContainer = document.getElementById('local-user')
    const localPlayerContainer = document.getElementById('local-user-video')
    if (!localPlayerContainer || !rootLocalContainer) return
    // Specify the ID of the DIV container. You can use the uid of the local user.
    localPlayerContainer.id = String(options.uid)
    localPlayerContainer.style.width = '100%'
    localPlayerContainer.style.height = '20vh'
    rootLocalContainer.append(localPlayerContainer)

    // Play the local video track.
    // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
    // @ts-ignore
    rtc.localVideoTrack.play(localPlayerContainer)

    // When token-privilege-will-expire occurs, fetch a new token from the server and call renewToken to renew the token.
    rtc.client.on('token-privilege-will-expire', async function () {
      let token = await refreshVideoToken()
      await rtc.client?.renewToken(token)
    })
  }

  const toggleCamAction = async () => {
    const nextState = !camState
    setCamState(nextState)
    await rtc?.localVideoTrack?.setEnabled(nextState)
  }
  const toggleMicAction = async () => {
    const nextState = !micState
    setMicState(nextState)
    await rtc?.localAudioTrack?.setEnabled(nextState)
  }
  const endCall = () => {
    rtc?.client?.leave().then(() => {
      rtc?.localVideoTrack?.close()
      rtc?.localAudioTrack?.close()
      dispatch(endVideoCall())
    })
  }
  return (
    <Wrapper>
      {loading ? (
        <Skeleton />
      ) : (
        <div id="aga-video-calling" className="fixed-wrapper">
          <div id="local-user">
            <div id="local-user-video"></div>
          </div>
          <div id="remote-user"></div>
          <div className="info remote-user-info">
            <p>{videoAppointmentEntity?.doctor.name}</p>
          </div>
          <div id="remote-audio">
            {!remoteAudioState && <img src={micOff} alt="" />}
          </div>
          <div id="action-control">
            <div className="camera" onClick={() => toggleCamAction()}>
              <img src={camState ? camOn : camOff} alt="" />
            </div>
            <div className="leave" onClick={() => endCall()}>
              <img src={callEnd} alt="" />
            </div>
            <div className="mic" onClick={() => toggleMicAction()}>
              <img src={micState ? micOn : micOff} alt="" />
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  #aga-video-calling {
    width: 100%;
    height: 100vh;
    overflow-y: hidden;
    position: relative;
    background-color: rgb(32, 33, 36);

    #local-user {
      position: absolute;
      width: 100%;
      z-index: 2;
      right: 10px;
      bottom: 18vh;
      display: flex;
      justify-content: flex-end;

      > div {
        height: 20vh !important;
        max-width: 18vw;
        padding-left: 10px;
      }

      > div > div {
        background-color: rgb(32, 33, 36) !important;
        border-radius: 10px;
      }

      .agora_video_player {
        right: 0;
        border-radius: 10px;
        left: auto !important;
        overflow: hidden;
      }
    }

    #remote-user {
      width: 100%;
      height: 75vh;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: transparent;
      padding: 10px;
      margin-top: 8vh;

      > div {
        height: 100% !important;
      }

      > div > div {
        background-color: rgb(32, 33, 36) !important;
      }

      .agora_video_player {
        border-radius: 10px;
        height: auto !important;
        max-height: calc(55vh - 25px);
      }
    }

    #action-control {
      position: absolute;
      z-index: 3;
      display: flex;
      justify-content: space-around;
      width: 100%;
      padding: 0 10%;
      bottom: 4vh;

      .camera,
      .mic,
      .leave {
        height: 80px;
        display: flex;
        align-items: center;
      }
    }

    #remote-audio {
      z-index: 4;
      position: absolute;
      top: 15px;
      right: 15px;
    }

    .remote-user-info {
      position: fixed;
      z-index: 10000;
      margin-left: 10px;
      margin-top: 20px;
      font-size: 15px;
      color: #fff;
    }

    @media (max-width: 991px) {
      #local-user {
        width: 100%;
        right: 0px;
        bottom: auto;
        background-color: transparent;
        display: block;
        text-align: -webkit-right;

        > div {
          max-width: 18vw;
          pading-left: 0;
          padding-bottom: 10px;
        }
      }

      #remote-user {
        width: auto;
        height: 100vh;
        top: 0;
        left: 0;
        padding: 0px;
        margin-top: 0;

        > div > div {
          background-color: transparent !important;
        }

        .agora_video_player {
          max-height: 100%;
          height: 100% !important;
          border-radius: 10px;
          width: auto !important;
          margin: auto;
          right: 0;
        }
      }

      #action-control {
        padding: 0 5%;
        bottom: 0vh;

        .camera,
        .mic,
        .leave {
          height: 54px;
        }

        .leave img {
          max-width: 48px;
        }
      }
    }

    @media (max-width: 480px) {
      #local-user {
        bottom: auto;
        top: 10vh;
        display: flex;

        > div {
          height: 20vh !important;
          max-width: 30vw;
          padding-left: 10px;
        }
      }

      #remote-user {
        height: 80vh;
        top: 40vh;
        padding: 0px;

        .agora_video_player {
          height: auto !important;
          width: 100% !important;
        }
      }

      #action-control {
        padding: 0 5%;
        bottom: 4vh;

        .camera,
        .mic,
        .leave {
          height: 80px;
        }
      }
    }
  }
`

export default Container

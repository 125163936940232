import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  NotificationInterface,
  NotificationsInterface,
} from 'src/interfaces/notification'

type State = {
  loading: boolean
  entity: NotificationsInterface | null
}

const initialState: State = {
  loading: false,
  entity: null,
}

const { actions, reducer } = createSlice({
  // TODO: remember change the name of store
  name: 'list_notification',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<NotificationsInterface>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    pushNotificationToOldState: (
      state,
      action: PayloadAction<NotificationInterface>,
    ) => {
      if (state.entity) {
        state.entity = { ...state.entity, data: [action.payload] }
      }
    },
  },
})

export { reducer }
export default actions

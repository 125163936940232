import { createSelector } from '@reduxjs/toolkit'

export const selectChannel = createSelector(
  (state) => state.domain.chat.channel.channel,
  (channel) => channel,
)

export const selectIsFetchingChannel = createSelector(
  (state) => state.domain.chat.channel.isFetchingChannel,
  (isFetchingChannel) => isFetchingChannel,
)

import React from 'react'

import { PATH } from 'src/pages/aga'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import GuestLayout from 'src/routes/components/GuestLayout'

const AuthRoute: IRouter[] = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATH.AGA,
        element: loadable(() => import('src/pages/aga/RateAnswers')),
      },
      {
        path: PATH.NICKNAME,
        element: loadable(() => import('src/pages/aga/NickName')),
      },
      {
        path: PATH.LOADING,
        element: loadable(() => import('src/pages/aga/Loading')),
      },
      {
        path: PATH.RESULT,
        element: loadable(() => import('src/pages/aga/Result')),
      },
    ],
  },
]

export default AuthRoute

import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

const PolicyContainer: React.FC = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const back = () => {
    navigate(-1)
  }
  return (
    <Template>
      <div className="policy">
        <Wrapper>
          <h2 className="policy-top text-center">プライバシーポリシー</h2>
          <p>
            DDデンタルクリニックグループ（
            アルディバラン渋谷デンタルクリニック・新宿駅前矯正歯科クリニック・
            高田馬場DDデンタルクリニック・
            横浜DDデンタルクリニック・名古屋DDデンタルクリニック・
            心斎橋DDデンタルクリニックを指し、以下「当院」と総称します。）は、個人情報保護が重大な社会的責任であると認識し、お客様の個人情報について以下のとおりプライバシーポリシー（以下「本プライバシーポリシー」といいます。）を定めます。本ポリシーは、当院がどのような個人情報を取得し、どのように利用・共有するか、お客様がどのようにご自身の個人情報を管理できるかをご説明するものです。
          </p>
          <div className="policy-content">
            <div className="policy-header">1.法規制の遵守</div>
            <div>
              当院は、個人情報保護に関する関係諸法令、規約、契約、その他規範を厳正に遵守いたします。
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">2.個人情報の取得について</div>
            <div>
              当院は、各種サービスの提供、歯科医師・矯正歯科医師等の第三者との連携等の当院の活動に関連して、当院に来院した患者様及び当院が運営するWebサイト（以下「本サイト」といいます。）の利用者様（以下「利用者様」といいます。）から、以下の情報を適法、公正かつ適切な方法で収集しています。なお、当院が収集する情報の中には、個人情報ではないものもあり、その場合、個人情報保護に関する義務や責任を負うものではありません。
              <br />
              (1)お客様から取得した情報
              <div className="mt-20 ml-20">
                ①氏名、生年月日、性別、生年月日、職業、住所
                <br />
                ②電話番号、E-mailアドレス
                <br />
                ③予約日時、問診票の内容
                <br />
                ④お客様が電話、電子メール、その他の手段で当院にご意見、ご質問、アンケートへの回答などを提供された場合、その内容に関する情報
                <br />
                ⑤その他お客様から当院に提供されたすべての情報
              </div>
              <br />
              (2)お客様が受診した医療機関から取得した情報
              <div className="mt-20 ml-20">
                ①氏名、生年月日、性別、生年月日、職業、住所
                <br />
                ②電話番号、E-mailアドレス
                <br />
                ③来院日時
                <br />
                ④お客様の氏名、治療情報など、当院が医療機関を通じたお客様からの問合せに対応するために、お客様の受診した医療機関から取得する情報
                <br />
                ⑤その他お客様が受診した医療機関から当院に提供されたすべての情報
              </div>
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">3.個人情報の取得と利用目的</div>
            <div>
              (1)当院は、(a)お客様が個人情報の収集目的を通知され若しくは収集目的に書面で同意した後に、お客様が直接若しくはお客様から当院にお客様の個人情報を開示することを正式に許可された第三者を介して、お客様から自発的に当院に提供された場合、又は、(b)同意なしにお客様の個人情報を収集及び使用することが適用される法律で許可又は要求された場合を除き、お客様の個人情報を収集しません。当院は、追加の個人情報を収集する前、及びお客様に通知されていない目的でお客様の個人情報を使用する前に、お客様の同意を求めるものとします(法律で許可又は承認されている場合を除く)。
              <br />
              (2)当院の各種サービスにアクセスもしくは使用し、又は当院にお客様の個人情報を提供することにより、当院は、お客様が本プライバシーポリシーの条件を理解し、これに同意し、当院によるお客様の個人情報の収集、使用及び開示に同意したものとみなすことができます。これらの条件のいずれかに同意されない場合は、当院の各種サービスにアクセスしたり、使用したり、お客様の個人情報を当院に提供したりしないでください。
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">4.利用目的</div>
            <div>
              当院では、お問い合わせやお申し込みの際に、以下のような目的で個人情報を取得し、利用させていただくことがあります。
              <div className="mt-20 ml-20">
                ・本サイトからのご予約の受付のため
                <br />
                ・来院予約、来院予約の変更及び来院予約の取消しに必要な処理
                <br />
                ・お問合せへの対応のため
                <br />
                ・患者様及び利用者様に対する適切な医療サービスの提供のため
                <br />
                ・サービスの利用に伴う連絡、メールマガジン及び各種お知らせなどの配信及び送付のため
                <br />
                ・サービスに関するご意見・お問合せ内容の確認・回答のため
                <br />
                ・キャンペーンやアンケート、モニター及び取材等の実施のため
                <br />
                ・患者様および利用者様へのサービス向上を目的とした統計データ作成のため
                <br />
                ・患者様および利用者様へのサービス向上のための調査・アンケートの実施と集計のため
                <br />
                ・医療記録等の作成及び管理、会計及び経理業務、その他院内事務及び管理を適切に行うため
                <br />
                ・法令及び行政上業務への対応のため
                <br />
                ・個人情報の開示、内容の訂正、追加又は削除、利用の停止、消去及び第三者への提供の停止等意思確認のため
              </div>
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">5.委託先への提供・管理</div>
            <div>
              当院は、利用目的の達成に必要な範囲内で、個人情報の全部又は一部の管理を業務委託先に委託する場合があります。当院は、委託先の管理を極めて重要と考えており、業務を委託する際には、適用される法律で定められた個人情報保護水準を満たす事業者を選定し、秘密保持契約等により相互の義務を明確にしています。また、個人情報を適切に取り扱うよう関係事業者に要請するとともに、個人情報の安全管理のために必要な監督・監査を継続的に行います。
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">
              6.個人データの第三者提供について
            </div>
            <div>
              (1)当院は、以下の場合を除いて、お客様の同意なく個人情報を第三者に提供しません。但し、当院が、第三者との共同キャンペーン等に基づきお客様に対し各種特典を付与する場合、当該第三者からの照会に対し、真に当院サービスのお客様であることについて取得した個人情報の一部を回答、開示する場合があります。
              なお、個人情報を第三者に提供する場合には、適用される法令に基づき、適切に行います。
              <ul className="mt-20 ml-20">
                <li> 本人の同意を得た場合</li>
                <li> 当院に対して秘密保持義務を負う者に対して開示する場合</li>
                <li> 当院が行う業務の全部または一部を第三者に委託する場合</li>
                <li> 「7.個人情報の共同利用」の方法により提供される場合</li>
                <li>
                  合併、事業譲渡その他の事由による事業の承継に伴い、当該事業を承継する者に対して開示する場合
                </li>
                <li>
                  裁判所、検察庁、警察、税務署、弁護士会、その他これらに準じた権限を有する機関から、個人情報の開示を求められた場合
                </li>
                <li> 個人情報保護法その他の法令により認められた場合</li>
                <li> 当院の各サービスにおいて個別に定める場合</li>
                <li>
                  取得したお客様の情報をマーケティング活動及び商品開発のため、またはターゲティング広告の配信とその効果測定・分析のためにお客様を特定できない統計情報として（仮名加工情報）、広告配信事業者へ提供することがあります。
                </li>
              </ul>
              (2)当院は、お客様の事前の同意に基づき個人情報を第三者に提供した場合、以下の事項に関する記録を作成し、保管します。
              <ul className="mt-20 ml-20">
                <li>当該個人情報を提供した年月日</li>
                <li>
                  当該第三者の氏名又は名称その他の当該第三者を特定するに足りる事項
                </li>
                <li>
                  当該個人情報によって識別される者の氏名その他のその者を特定するに足りる情報
                </li>
                <li>当該個人情報の項目</li>
              </ul>
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">7.個人情報の共同利用</div>
            <div>
              当院並びに関連会社及び提携先企業間では、次に掲げるとおり、利用目的の達成に必要な範囲内で、個人情報を共同利用する場合があります。
              <br />
              なお、個人情報の管理についての責任は、当院が有します。
              <br />
              ①共同して利用される個人データの項目
              <br />
              「2.個人情報の取得について」のとおり
              <br />
              ②共同利用者の範囲
              <br />
              ・アルディバラン株式会社
              <br />
              ・SheepMedical 株式会社
              <br />
              ・SheepMedical 株式会社の子会社及び関連会社
              <br />
              ・株式会社ARETECO HOLDINGS
              <br />
              ③共同利用における利用目的
              <br />
              「4.利用目的」のとおり
              <br />
              ④個人データの管理について責任を有する者の名称
              <br />
              当院
              <br />
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">
              8.クッキー(Cookie)の利用について
            </div>
            <div>
              当院は、Googleによるウェブサイトアクセス解析ツール「Googleアナリティクス」を使用しています。「Googleアナリティクス」はデータの収集のためにCookieを使用し、Cookieから取得できる以下の情報を、お客様の利便性向上及び利用状況の分析等のため利用する場合があります。これらのデータは匿名で収集されており、個人を特定するものではありません。お客様は、Cookieを無効にすることで収集を拒否することが出来ます。お使いのブラウザの設定をご確認ください。その他詳細については、
              <a
                href="https://policies.google.com/privacy?hl=ja"
                target="_blank"
                rel="noreferrer"
              >
                Googleプライバシーポリシー
              </a>
              をご確認ください。
              <br />
              【Cookieから取得できる情報】
              <br />
              端末に関する情報、インターネットへの接続に関する情報、リファラー情報、IPアドレス情報、タイムスタンプ情報、広告識別子情報等
              <br />
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">
              9.開示、訂正・追加・削除、利用停止・消去、第三者提供の停止・第三者提供記録の開示
            </div>
            <div>
              お客様は、当院に対し、以下の場合を除き、当院が別途定めた手続に従って、お客様の個人情報の利用目的の通知及び個人情報の開示、訂正・追加・削除、利用停止・消去、第三者提供の停止・第三者提供記録の開示を請求することができます。この場合当院は、お客様ご本人からの請求であることを確認したうえで、遅滞なく調査を行い、その結果に基づき当該個人情報の訂正・追加・削除、利用停止・消去、第三者提供の停止・第三者提供記録の開示を行います。この場合、当院は、個人情報の保護に関する法律に従って適切に対応いたします。なお、個人情報の開示の請求にあたっては、当院が別途定めた手続に従って開示手数料を頂くことがあります。
              <div className="mt-20 ml-20">
                ①開示することで本人又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
                <br />
                ②開示することで当院の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                <br />
                ③開示することが法令に違反することとなる場合
                <br />
                ④開示の請求がご本人からであることが確認できない場合
              </div>
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">10.個人情報の安全管理</div>
            <div>
              (1)当院は、個人情報を厳重に管理し、個人情報保護のための適切な管理・物理・技術的施策の導入やシステムの開発・改善、従業員への教育・啓発活動、入退室管理、情報セキュリティ対策などを通じて、不正又は不適切なアクセス、収集、使用、開示、複写、変更、紛失、破壊、改ざん、漏洩などのリスクを防止するために、合理的なセキュリティ対策を講じます。
              <br />
              (2)当院は個人情報を収集した目的が達成されず、法律上又は業務上保有する必要がなくなったと考えるのが妥当である場合、又は適用される法律で要求される場合には、直ちに個人情報を含む文書の保有を中止し、又は個人情報とお客様との関連付けを解除します。また、保持していない個人情報は、適切な方法で廃棄、匿名化、及び/又は削除されます。
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">11.個人情報の安全管理</div>
            <div>
              本サイトに掲載されている情報の正確性には万全を期していますが、お客様が本サイトの情報を用いて行う全ての行為に関して、一切の責任を負わないものとします。
              当院は、お客様が本サイトを利用したことにより生じたお客様の損害及びお客様が第三者に与えた損害に関して、一切の責任を負わないものとします。
              <br />
              また、一時的に本サイト及び各種サービス等が停止、中止又は変更されたとしても、お客様が直接的又は間接的に被った一切の損害、損失、不利益等について、一切の責任も負わないものとします。
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">12.継続的改善</div>
            <div>
              当院は、ビジネスパートナーからの要請や社会情勢の変化に応じて、個人情報の適切な取扱いと保護を維持するために、本方針を含む個人情報保護マネジメントシステムを継続的に見直し、改善していきます。
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">13.プライバシーポリシーの更新</div>
            <div>
              当院は、お客様の個人情報の取り扱いについて、適宜見直し、改善してまいります。その場合、すべての改訂は本サイトでお知らせします。本プライバシーポリシーの変更は、当院が本サイトに掲載した時点から適用されるものとします。
            </div>
            <hr className="policy-hr" />
            <div className="policy-header">14.お問合せ連絡先</div>
            <div>
              本プライバシーポリシーに関して不明な点がある場合、各種サービスにおける個人情報の取扱いに関する質問等がある場合又は個人情報の開示、訂正・追加・削除、利用停止・消去、第三者提供の停止等の請求を行う場合は、以下よりご連絡ください。
            </div>

            <div className="mt-10">
              【お問い合わせ窓口】
              <br />
              DDデンタルクリニック 事務局
              <br />
              メールアドレス： houmu@sheepmedical-tech.com
            </div>
            <div className="mt-10 text-right">2023年6月30日施行</div>
          </div>
        </Wrapper>
        <div className="policy-footer" onClick={back}>
          <span className="mr-10">＜</span>戻る
        </div>
      </div>
    </Template>
  )
}
const Wrapper = styled.div`
  padding: 30px 20px 10px 20px;
  a {
    color: var(--color-bahama-blue);
    text-decoration: underline;
  }
`
const Template = styled.div``

export default PolicyContainer

import PaymentInfoPage, { PATH as PAYMENT_INFO } from './Info'
import PaymentErrorPage, { PATH as PAYMENT_ERROR } from './Error'
import PaymentSuccessPage, { PATH as PAYMENT_SUCCESS } from './Success'
import PaymentConfirm, { PATH as PAYMENT_CONFIRM } from './Confirm'

export default {
  PaymentConfirm,
  PaymentInfoPage,
  PaymentErrorPage,
  PaymentSuccessPage,
}
export const PATH = {
  PAYMENT_INFO,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
  PAYMENT_CONFIRM,
}

import React from 'react'

import { PATH } from 'src/pages/regular'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'
import GuestLayout from 'src/routes/components/GuestLayout'

const AuthRoute: IRouter[] = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: PATH.REGULAR_SUBSCRIPTION,
        element: loadable(
          () => import('src/pages/regular/RegularSubscription'),
        ),
      },
      {
        path: PATH.PERSONAL_INFO,
        element: loadable(() => import('src/pages/regular/PersonalInfo')),
      },
    ],
  },
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.REGULAR_SUBSCRIPTION_ADDRESS,
        element: loadable(
          () => import('src/pages/regular/RegularSubscriptionAddress'),
        ),
      },
      {
        path: PATH.CONFIRM_DELIVERY,
        element: loadable(() => import('src/pages/regular/ConfirmDelivery')),
      },
    ],
  },
]

export default AuthRoute

import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'

import { useAppDispatch } from 'src/store'
import { uploadHeadImage } from 'src/useCase/automaticPlan'
import uiActions from 'src/store/ui/notification'

export const FILE_SIZE = 1024 * 1024 * 3

export type FormValues = {
  image: File
}

export const useUploadImage = () => {
  const dispatch = useAppDispatch()

  const validationSchema = Yup.object().shape({})

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    if (data?.image?.size > FILE_SIZE) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: '6MB以下の画像をアップロードしてください。',
        }),
      )
    } else {
      dispatch(uploadHeadImage({ data }))
    }
  }

  const form = useForm<FormValues>({
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

import React, { useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'
import { get, some } from 'lodash'

import { useAppDispatch, useAppSelector } from 'src/store'
import { getTemporaryOrder } from 'src/useCase/plan'
import ButtonCustom from 'src/components/form/Button'
import { getRedirectPathByPlanType } from 'src/containers/AutomaticPlan/PlanDetail/constants'
import { PLAN_TYPE } from 'src/constants/app'
import { formatNumPrice, getUserPlanType } from 'src/utils/helper'
import UserInfo from 'src/components/common/PaymentConfirm/UserInfo'
import PlanInfo from 'src/components/common/PaymentConfirm/PlanInfo'
import { getInfo } from 'src/useCase/user'
import { USER_STATE } from 'src/enum/userStatus'
import LocalStorage from 'src/utils/LocalStorage'
import { PATH as AUTOMATIC_PLAN_PATH } from 'src/pages/automaticPlan'
import { PATH as AUTH_PATH } from 'src/pages/auth'

type Props = {
  planId: number
  hasImage?: boolean
  hasInfo?: boolean
  isHiddenUserInfo?: boolean
  isHiddenTemporaryOrder?: boolean
}

const ListOrderTracking = (props: Props) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    planId,
    hasImage,
    hasInfo,
    isHiddenUserInfo,
    isHiddenTemporaryOrder,
  } = props

  const temporaryPlan = useAppSelector(
    (state) => state.domain.plans.temporaryPlan,
  )
  const temporaryPlanLoading = useAppSelector(
    (state) => state.domain.plans.temporaryPlan.loading,
  )
  const temporaryOrder = temporaryPlan.entity.temporaryOrder
  const plan = temporaryPlan.entity.plan

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const planType =
    LocalStorage.planType || getUserPlanType(user?.state_transitions || [])
  const userLoading = useAppSelector((state) => state.ui.app.loading)

  const isHasText = (index, item) => {
    return (
      temporaryOrder &&
      index < temporaryOrder.length - 1 &&
      temporaryOrder[index + 1].amount !== item.amount
    )
  }

  useEffect(() => {
    if (user?.state_transitions !== undefined) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
    }
  }, [])

  useEffect(() => {
    dispatch(getTemporaryOrder({ plan_id: planId }))
  }, [])

  const submitOrderPlan = () => {
    const isVerifyOTP =
      (user &&
        some(
          user?.state_transitions,
          (i) => i.state === USER_STATE.OTP_VERIFIED,
        )) ||
      false

    const redirectPath = getRedirectPathByPlanType(planType, isVerifyOTP)
    navigate(redirectPath)

    // dispatch(
    //   orderPlan({
    //     plan_id: planId,
    //     plan_type: planType,
    //     redirect_path: redirectPath,
    //   }),
    // )
  }

  const back = () => {
    // navigate(-1)
    if (planType === PLAN_TYPE.EXAMINATION) {
      navigate(AUTOMATIC_PLAN_PATH.PROPOSAL)
    }
    if (planType === PLAN_TYPE.PRESCRIPTION) {
      const feedbackId = get(user, 'last_doctor_feedback.id', 0)
      if (feedbackId) {
        navigate(`/karte-plan/prescription-proposal/${feedbackId}`)
      } else {
        navigate(AUTH_PATH.REGISTRATION_COMPLETE)
      }
    }
  }

  if (!temporaryOrder || temporaryOrder.length === 0) {
    return null
  }
  if (temporaryPlanLoading) {
    return (
      <div className="mx--20">
        <Skeleton />
      </div>
    )
  }
  if (temporaryPlanLoading) {
    return (
      <div className="mx--20">
        <Skeleton />
      </div>
    )
  }

  return (
    <TrackingDiv>
      <div className="section">
        {hasImage && (
          <div className="sec-title tracking-info">
            <div className="sec-title tracking">プラン内容</div>
            <div className="tracking-body">
              <div className="tracking-content">
                <p>{plan.name}</p>
                <img src={plan.image_url} alt="" className="w-100" />
              </div>
            </div>
          </div>
        )}
        {hasInfo && (
          <>
            {plan && <PlanInfo plan={plan} />}
            {!isHiddenUserInfo && (
              <>
                {userLoading ? <Skeleton /> : user && <UserInfo user={user} />}
              </>
            )}
          </>
        )}
        {!isHiddenTemporaryOrder &&
          temporaryOrder &&
          temporaryOrder.length > 1 && (
            <div>
              <div className="sec-title">スケジュール</div>
              {temporaryOrder.length < 1 && (
                <div className="p-20">データはありません</div>
              )}

              <div className="sec-body">
                <div className="header-box">
                  <div>発送日</div>
                  <div>金額</div>
                </div>
                {temporaryOrder &&
                  temporaryOrder.length > 1 &&
                  temporaryOrder.map((item, index) => {
                    if (item.delivery_date) {
                      return (
                        <div
                          className="d-flex justify-content-between content align-self-center"
                          key={index}
                        >
                          <div>
                            {isHasText(index, item) && (
                              <p className="mb-0">&nbsp;</p>
                            )}
                            <p className="mb-0">
                              {moment(item.delivery_date).format(
                                'YYYY年MM月DD日',
                              )}
                            </p>
                          </div>
                          <div>
                            {isHasText(index, item) && (
                              <p className="text-price mb-0 text-center">
                                初回限定金額
                              </p>
                            )}
                            <p className="price-display mb-0">
                              {formatNumPrice(item.amount)}円(税込)
                            </p>
                          </div>
                        </div>
                      )
                    }
                    return null
                  })}
              </div>
              <div className="line-box">
                ※2回目以降の自動決済は、配送予定日の10日前に決済を行います。
              </div>
            </div>
          )}

        <div className="mx--20 mt-30">
          {/* {planType === PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION && (
            <>
              <div className="mb-30">
                <p>
                  SMS認証ボタンをクリック後、ご入力された電話番号にSMSに認証用コードが送信されます。
                </p>
                <p>受け取り後、コードを入力して認証を行ってください。</p>
                <p>認証後、会員登録となります。</p>
              </div>
            </>
          )} */}
          <ButtonCustom
            className="btn-default btn-chestnut-rose my--10"
            loading={false}
            onClick={submitOrderPlan}
            name="確認する"
            showIconRight
          />

          {/* {(planType === PLAN_TYPE.EXAMINATION ||
            planType === PLAN_TYPE.PRESCRIPTION) && (
            <> */}
          <ButtonCustom
            className="btn-default btn-white my--30"
            loading={false}
            onClick={back}
            name="内容を修正する"
          />
          {/* </>
          )} */}
        </div>
      </div>
    </TrackingDiv>
  )
}

const TrackingDiv = styled.div`
  .sec-body {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .btn-gray {
      color: #999999;
      border: 1px solid #999999;
      padding: 5px;
      min-width: 90px;
    }
    .btn-blue {
      color: #1e4c82;
      border: 1px solid #1e4c82;
      padding: 5px;
      min-width: 90px;
    }

    .text-price {
      color: #e01616;
      font-weight: 700;
      font-size: 10px;
      line-height: 15px;
    }

    .price-display {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
    }

    .header-box {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      height: 40px;
      background: #f9f9f9;
      border-width: 1px 0px;
      border-style: solid;
      border-color: #cccccc;
      color: #999999;
      margin-bottom: 15px;

      div {
        padding: 10px 25px;
      }
    }

    &-border {
      border-bottom: 1px solid var(--color-desert-storm);
    }
  }
  .content {
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 10px;
    /* border-bottom: 1px solid #f1efe7; */
    padding-bottom: 5px;

    span {
      align-self: center;
    }
  }
  .line-box {
    padding: 10px;
    border: 1px solid #9a2f2f;
    font-size: 12px;
    color: #9a2f2f;
    margin: 20px 20px 30px;
  }

  .sec-title {
    /* background: #f1efe7; */
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    padding: 10px 20px;
    border-bottom: 2px solid var(--color-star-dust);
  }

  .tracking {
    margin: 10px 0;

    &-content {
      margin: 20px 20px 30px;
      text-align: center;

      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: #ffffff;
        background-color: #998800;
        text-align: center;
        padding: 10px;
        margin-bottom: 0;
      }

      img {
        margin: auto;
      }
    }

    &-info {
      background-color: var(--color-spring-wood-2);
      border: none;
    }
  }
`

export default ListOrderTracking

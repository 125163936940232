import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import { useNavigate } from 'react-router-dom'

import LPHeader from 'src/components/layout/Header/LPHeader'
import MenuHeader from 'src/components/layout/Header/MenuHeader'
import { getAllOrderTracking } from 'src/useCase/myPage'
import { PATH } from 'src/pages/myPage/UserDetail'
import { useAppDispatch, useAppSelector } from 'src/store'
import ListDeliveries from 'src/components/common/ListDeliveries'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const tracking = useAppSelector(
    (state) => state.domain.myPage.orderTracking.entities,
  )
  const loading = useAppSelector(
    (state) => state.domain.myPage.orderTracking.loading,
  )

  useEffect(() => {
    const params = { order: 'delivery_date' }

    dispatch(getAllOrderTracking(params))
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      <MenuHeader name="配送スケジュール" />
      <div className="delivery-schedule">
        <div className="delivery-schedule__title p-20">一年間スケジュール</div>
        <div className="delivery-schedule__content">
          <div className="section">
            <div className="sec-title">一年間スケジュール</div>
            {!loading ? (
              <>
                {tracking.length > 0 ? (
                  <ListDeliveries tracking={tracking} />
                ) : (
                  <div className="pl-20 pr-20">データはありません</div>
                )}
                <div className="p-20 mb-30">
                  <button
                    type="button"
                    onClick={() => navigate(PATH)}
                    className="btn-default btn-black fs-20 w-100"
                  >
                    戻る
                  </button>
                </div>
              </>
            ) : (
              <Skeleton className="m-20" />
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .delivery-schedule {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 0.13em;
      color: #000000;
    }

    &__link {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-align: right;
      color: #1e4c82;
      margin-bottom: 15px;
      float: right;
    }

    &__submit {
      margin-bottom: 40px;
      width: 100%;
      padding: 19px;
      color: #000000;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #ffffff;
      border: 1px solid #000000;
    }

    .section {
      margin-top: 10px;

      .sec-title {
        background: #f1efe7;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
        padding: 10px 20px;
      }
    }
  }
`

export default Container

import squareAction from 'src/store/domain/payment/square'
import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'

import squarePayReq from 'src/api/requests/squarePayment'
import { FormValues } from 'src/containers/MyPage/PaymentInfo/components/usePaymentInfo'
import history from 'src/libs/history'
import { PATH as USER_DETAIL_PATH } from 'src/pages/myPage/UserDetail'
import { PATH } from '../pages/payment'
import { getInfo } from './user'

export const squareChangeCard = createAsyncThunk<any, FormValues>(
  'change_card',
  async (params, { dispatch }) => {
    try {
      await squarePayReq.changeCard(params)
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
      history.push(USER_DETAIL_PATH)
    } catch (error) {
      const msg = _.get(error, 'response.data.message', '')
      const code = _.get(error, 'response.data.code', '')
      dispatch(squareAction.updateLoading(false))
      dispatch(squareAction.updateErrorMsg(msg))
      dispatch(squareAction.updateErrorCode(code))
      history.push(PATH.PAYMENT_ERROR)
    }
  },
)

import DeliveryDestinationInfo, {
  PATH as DELIVERY_DESTINATION_INFO_PATH,
} from './DeliveryDestinationInfo'
import OtherMenu, { PATH as OTHER_MENU } from './OtherMenu'
import PurchaseHistory, { PATH as PURCHASE_HISTORY } from './PurchaseHistory'
import UserDetail, { PATH as USER_DETAIL } from './UserDetail'
import DeliverySchedule, { PATH as DELIVERY_SCHEDULE } from './DeliverySchedule'
import AllDeliverySchedule, {
  PATH as ALL_DELIVERY_SCHEDULE,
} from './AllDeliverySchedule'
import UpdatePassword, { PATH as UPDATE_PASSWORD_PATH } from './UpdatePassword'
import PaymentInfoPage, { PATH as PAYMENT_INFO_PATH } from './PaymentInfo'
import Treatment, { PATH as TREATMENT_PATH } from './Treatment'

export default {
  OtherMenu,
  PurchaseHistory,
  DeliverySchedule,
  UserDetail,
  AllDeliverySchedule,
  UpdatePassword,
  DeliveryDestinationInfo,
  PaymentInfoPage,
  Treatment,
}
export const PATH = {
  OTHER_MENU,
  PURCHASE_HISTORY,
  DELIVERY_SCHEDULE,
  USER_DETAIL,
  ALL_DELIVERY_SCHEDULE,
  UPDATE_PASSWORD_PATH,
  DELIVERY_DESTINATION_INFO_PATH,
  PAYMENT_INFO_PATH,
  TREATMENT_PATH,
}

import React from 'react'

import CallingContainer from 'src/containers/Video/Calling'

const VideoCalling: React.FC = () => {
  return <CallingContainer />
}

export const PATH = '/video/calling' as const
export default VideoCalling

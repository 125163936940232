import React from 'react'
import styled from 'styled-components'

type Props = {
  name: string
  className?: string
  noneBorder?: boolean
}

const ShampooHeader = ({ name, className, noneBorder }: Props) => {
  return (
    <Template noneBorder={noneBorder}>
      <div className={`step-header-content ${className || ''}`}>
        <div className="step-header-padding">
          <div className="d-flex justify-content-between mb-1 step-header">
            <span className="step-name">{name}</span>
          </div>
        </div>
      </div>
    </Template>
  )
}

const Template = styled.div`
  .step-header-content {
    position: absolute;
    width: 100%;
    left: 0;
    // padding-top: 25px;
    /* top: 25px; */
  }
  .step-header-padding {
    padding: 10px 20px;
    border-bottom: ${(props) =>
      props.noneBorder ? 'none' : '2px solid var(--color-rangoon-green)'};
  }
  .step-name {
    font-weight: 400;
    padding-top: 2px;
  }
  .icon-close {
    height: 1rem;
    margin-top: 5px;
  }
`
export default ShampooHeader

import React, { useMemo } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useAppSelector } from 'src/store'
import qs from 'query-string'
import _ from 'lodash'

// use case
import { handleRedirectByState } from 'src/useCase/keepFlow'
import StatusErrorSkipStepContainer from 'src/containers/Error/StatusErrorSkipStep'

const CheckUploadedFileLayout: React.FC = () => {
  const location = useLocation()

  const urlParams = qs.parse(location.search)
  const userTreatments = useAppSelector(
    (state) => state.domain.myPage.userTreatment.entities,
  )

  const user = useAppSelector((state) => state.ui.app.authInfo)

  const redirect = useMemo(() => {
    let path: any = null
    if (user && user.new_state === 'registered_member') {
      return null
    }

    if (user) {
      _.forEach(userTreatments, (userTreatment: any) => {
        path = handleRedirectByState(userTreatment, user, urlParams)
        if (path.includes('/automatic-plan/')) {
          return
        }
      })
    }

    return path
  }, [userTreatments, user])

  if (redirect) {
    return <StatusErrorSkipStepContainer path={redirect} />
  }

  if (!redirect && (!userTreatments.length || !user)) {
    return <div />
  }

  return <Outlet />
}

export default CheckUploadedFileLayout

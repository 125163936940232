import format from 'string-format'

import HTTPRequest from '../index'
import {
  FeedbackDetailRequestInterface,
  FeedbackDetailResponseInterface,
} from 'src/interfaces/feedback'

const apiPathFeedbackDetail = 'api/users/feedback/{0}'

class Feedback extends HTTPRequest {
  getFeedbackDetail(
    feedbackId: number,
    params: FeedbackDetailRequestInterface,
  ) {
    return this.get<FeedbackDetailResponseInterface>(
      format(`${apiPathFeedbackDetail}`, feedbackId),
      { params },
    )
  }
}

export default new Feedback()

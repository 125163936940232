import React, { useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useAppSelector } from 'src/store'

// Selectors
import { selectChannel } from 'src/store/domain/chat/channel/selector'

//img
import defaultChat from 'src/assets/images/members/chat/default-doctor.png'
import LazyLoadImage from 'src/components/common/LazyLoadImage'

type Props = {
  item: {
    message_content: string
    created_at: string
    attachment_image_url: string
  }
}

const TheirMessage = (props: Props) => {
  const { item } = props
  const { message_content, created_at, attachment_image_url } = item

  const channel = useAppSelector(selectChannel)
  const manager = channel?.managers?.[0]
  const info = useMemo(() => {
    const imageUrl = manager?.avatar_url
    return {
      imageUrl,
    }
  }, [manager])

  const messageTime = useMemo(() => {
    moment.locale('ja')
    let startDate = moment(created_at).startOf('day')
    let time = moment(created_at)
    if (moment().diff(startDate, 'days') == 0) {
      return time.format('HH:mm')
    }
    return time.format('M/DD HH:mm')
  }, [created_at])

  return (
    <Wrapper>
      <div className="their-message">
        <img
          src={info.imageUrl ? info.imageUrl : defaultChat}
          alt="their-avatar"
          className="their-message__avatar"
        />
        {/* <LazyLoadImage
          imgSrc={info.imageUrl ? info.imageUrl : defaultChat}
          height="auto"
          className="their-message__avatar"
        /> */}
        {message_content && (
          <div
            className="their-message__message"
            dangerouslySetInnerHTML={{ __html: message_content }}
          ></div>
        )}

        {attachment_image_url && (
          // <Image className="w-30 ml-10" src={attachment_image_url} />
          <LazyLoadImage
            height="100px"
            width="30%"
            className="w-30 ml-10"
            imgSrc={attachment_image_url}
          />
        )}

        <span className="their-message__time">{messageTime}</span>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .their-message {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;

    &__avatar {
      width: 40px;
      height: 40px;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
      border-radius: 50%;
      object-fit: cover;
    }

    &__message {
      margin-left: 10px;
      background: white;
      padding: 10px 8px;
      border-radius: 10px;
      max-width: 66%;
      word-break: break-word;
      font-size: 14px;
      text-align: left;
      white-space: pre-line;
      border: 1px solid #000;
    }

    &__time {
      align-items: flex-end;
      display: flex;
      font-size: 12px;
      color: #989898;
      width: 44px;
      padding-left: 8px;
    }
  }
`

export default TheirMessage

import { useAppDispatch } from './../../../store/index'
import { useAppSelector } from 'src/store'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { EMAIL_REGEX, PHONE_REGEX, POSTAL_NUMBER_REGEX } from 'src/utils/regex'
import { updateAddressSkipSMS } from 'src/useCase/address'

export type FormValues = {
  postal_number?: string
  prefecture_id?: string
  city_id?: string
  address?: string
  building_name?: string
  phone?: string
  email: string
  isIgnoreSms?: boolean
}

export const useUpdatePassword = () => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.ui.app.authInfo)

  const defaultValues: FormValues = {
    postal_number: user?.postal_number || '',
    prefecture_id: user?.prefecture_id || '',
    city_id: user?.city_id || '',
    address: user?.address || '',
    building_name: user?.building_name || '',
    phone: user?.phone || '',
    email: user?.email || '',
    isIgnoreSms: true,
  }

  const validationSchema = Yup.object().shape({
    postal_number: Yup.string()
      .required('入力してください。')
      .matches(POSTAL_NUMBER_REGEX, '郵便番号は半角数字7桁で入力してください')
      .max(7, '郵便番号は半角数字7桁で入力してください'),
    prefecture_id: Yup.string()
      .required('入力してください。')
      .max(30, '都道府県は30文字以下で入力してください'),
    city_id: Yup.string()
      .required('入力してください。')
      .max(30, '市区町村は30文字以下で入力してください'),
    address: Yup.string()
      .required('入力してください。') //必須項目が入力されていません
      .max(30, '建物名/部屋番号は30文字以下で入力してください'),
    building_name: Yup.string().max(
      30,
      '建物名・部屋番号は30文字以下で入力してください',
    ),
    phone: Yup.string()
      .required('入力してください。') //電話番号を入力してください。
      .length(11, '携帯番号は11桁で入力してください。')
      .matches(PHONE_REGEX, '電話番号は０から始まる必要があります。'),
    email: Yup.string()
      .required('入力してください。') //必須項目が入力されていません
      .email('メールアドレスを正しく入力してください')
      .matches(EMAIL_REGEX, 'メールアドレスを正しく入力してください'),
  })

  const onSubmit = async (data: FormValues) => {
    await dispatch(updateAddressSkipSMS({ credentials: data, meta: form }))
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

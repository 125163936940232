import { STORAGE_KEY } from 'src/constants/app'

export class LocalStorage {
  static get accessToken() {
    return localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN) || null
  }

  static get refreshToken() {
    return localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN) || null
  }

  // static get authInfo() {
  //   return JSON.parse(localStorage.getItem(STORAGE_KEY.AUTH_ME_INFO) || 'null')
  // }

  static setToken(payload: any) {
    localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, payload)
    localStorage.setItem(STORAGE_KEY.REFRESH_TOKEN, payload)
  }

  // static saveAuthInfo(payload: any) {
  //   localStorage.setItem(STORAGE_KEY.AUTH_ME_INFO, JSON.stringify(payload))
  // }

  static removeInfo() {
    localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN)
    localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN)
    localStorage.removeItem(STORAGE_KEY.AUTH_ME_INFO)
  }

  static get videoSchedule() {
    return JSON.parse(
      localStorage.getItem(STORAGE_KEY.VIDEO_SCHEDULE) || 'null',
    )
  }

  static clearLocalStorage() {
    localStorage.clear()
  }

  static get precaution() {
    return JSON.parse(localStorage.getItem(STORAGE_KEY.PRECAUTION) || 'null')
  }

  static setPrecaution(payload: any) {
    localStorage.setItem(STORAGE_KEY.PRECAUTION, JSON.stringify(payload))
  }

  static removePrecaution() {
    localStorage.removeItem(STORAGE_KEY.PRECAUTION)
  }

  static get activePlan() {
    return JSON.parse(localStorage.getItem(STORAGE_KEY.ACTIVE_PLAN) || 'null')
  }

  static setActivePlan(id) {
    localStorage.setItem(STORAGE_KEY.ACTIVE_PLAN, JSON.stringify(id))
  }

  static removeActivePlan() {
    localStorage.removeItem(STORAGE_KEY.ACTIVE_PLAN)
  }

  static get planType() {
    return JSON.parse(localStorage.getItem(STORAGE_KEY.PLAN_TYPE) || 'null')
  }

  static setPlanType(type) {
    localStorage.setItem(STORAGE_KEY.PLAN_TYPE, JSON.stringify(type))
  }

  static removePlanType() {
    localStorage.removeItem(STORAGE_KEY.PLAN_TYPE)
  }

  static getLocalStorage(key: string) {
    return JSON.parse(localStorage.getItem(key) || 'null')
  }

  static setLocalStorage(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data))
  }

  static removeLocalStorage(key: string) {
    localStorage.removeItem(key)
  }

  static setPathBeforeLoginLine(path) {
    return localStorage.setItem(STORAGE_KEY.PATH_BEFORE_LOGIN_LINE, path)
  }

  static getPathBeforeLoginLine() {
    return localStorage.getItem(STORAGE_KEY.PATH_BEFORE_LOGIN_LINE) || ''
  }

  static removePathBeforeLoginLine() {
    localStorage.removeItem(STORAGE_KEY.PATH_BEFORE_LOGIN_LINE)
  }

  static setClinicUuid(data) {
    return localStorage.setItem(STORAGE_KEY.CLINIC_UUID, data)
  }

  static getClinicUuid() {
    return localStorage.getItem(STORAGE_KEY.CLINIC_UUID) || ''
  }

  static removeClinicUuid() {
    localStorage.removeItem(STORAGE_KEY.CLINIC_UUID)
  }

  static setVideoToken(data) {
    return localStorage.setItem(STORAGE_KEY.VIDEO_TOKEN, data)
  }

  static getVideoToken() {
    return localStorage.getItem(STORAGE_KEY.VIDEO_TOKEN) || ''
  }

  static removeVideoToken() {
    localStorage.removeItem(STORAGE_KEY.VIDEO_TOKEN)
  }

  // user treatment id
  static setUserTreatmentId(id) {
    return localStorage.setItem(STORAGE_KEY.USER_TREATMENT_ID, id)
  }

  static getUserTreatmentId() {
    return localStorage.getItem(STORAGE_KEY.USER_TREATMENT_ID) || null
  }

  static removeUserTreatmentId() {
    localStorage.removeItem(STORAGE_KEY.USER_TREATMENT_ID)
  }

  // menu name
  static setMenuName(name) {
    return localStorage.setItem(STORAGE_KEY.MENU_NAME, name)
  }

  static getMenuName() {
    return localStorage.getItem(STORAGE_KEY.MENU_NAME) || null
  }

  static removeMenuName() {
    localStorage.removeItem(STORAGE_KEY.MENU_NAME)
  }

  static setGtmId(data) {
    return localStorage.setItem(STORAGE_KEY.GTM_ID, String(data).trim())
  }

  static getGtmId() {
    return localStorage.getItem(STORAGE_KEY.GTM_ID) || ''
  }

  static removeGtmId() {
    localStorage.removeItem(STORAGE_KEY.GTM_ID)
  }
}

export default LocalStorage

import React from 'react'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'

import LPHeader from 'src/components/layout/Header/LPHeader'
import ShampooHeader from 'src/components/layout/Header/ShampooHeader'
import { PLAN_TYPE } from 'src/constants/app'
import UserInfoForm from 'src/containers/Auth/Register/components/UserInfoForm'

const PersonalInfoContainer: React.FC = (props: any) => {
  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form">
        <ShampooHeader name="シャンプー定期購入手続き" className="top-70" />

        <HeaderStyle>ご利用者情報入力</HeaderStyle>
        <UserInfoForm flowName={PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION} />
      </div>
    </>
  )
}

export default PersonalInfoContainer

import React from 'react'
import { Button } from 'antd'
import { PoweroffOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import iconRight from 'src/assets/icons/right_white.svg'

type Props = {
  loading: boolean
  onClick?: any
  className: string
  name: string
  htmlType?: any
  disabled?: boolean
  id?: string
  ref?: any
  showIconRight?: boolean
}

const ButtonFixedBottom = (props: Props) => {
  const {
    loading,
    onClick,
    className,
    name,
    htmlType,
    disabled = false,
    id,
    ref,
    showIconRight,
  } = props

  return (
    <ButtonStyle>
      <div className="custom-button">
        <Button
          style={{
            width: '100%',
            height: '60px',
            fontSize: '18px',
            fontWeight: '700',
            position: 'relative',
          }}
          id={id}
          ref={ref}
          className={className}
          type="primary"
          loading={loading}
          onClick={onClick}
          htmlType={htmlType ? htmlType : 'text'}
          disabled={disabled}
          icon={loading ? <PoweroffOutlined /> : null}
        >
          {name}
          {showIconRight && (
            <img
              style={{
                position: 'absolute',
                right: '15px',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto',
              }}
              src={iconRight}
              alt=""
            />
          )}
        </Button>
      </div>
    </ButtonStyle>
  )
}

const ButtonStyle = styled.div`
  /* background-color: var(--color-spring-wood-2); */
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;

  .custom-button {
    background-color: var(--color-spring-wood-2);
    padding: 15px 35px 25px;
    width: 100%;
  }

  @media (min-width: 992px) {
    .custom-button {
      width: 50%;
    }
  }
`

export default ButtonFixedBottom

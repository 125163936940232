import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

import LocalStorage from 'src/utils/LocalStorage'
import { STORAGE_KEY } from 'src/constants/app'
import { PATH } from 'src/pages/aga'

export type FormValues = {
  nick_name: string
}

export const nickNameForm = () => {
  const navigate = useNavigate()

  const defaultValues: FormValues = {
    nick_name: '',
  }

  const validationSchema = Yup.object().shape({
    nick_name: Yup.string().required('入力してください。'),
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    LocalStorage.setLocalStorage(STORAGE_KEY.AGA_NICK_NAME, data.nick_name)
    navigate(PATH.LOADING)
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

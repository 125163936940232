import format from 'string-format'

import { PostAnswersInterView } from 'src/useCase/question'
import HTTPRequest from '../index'

const apiPathQuestion = 'api/users/questions'
const apiPathAnswer = 'api/users/create-answer'
const apiPathCheckPrecaution = 'api/users/check-caution'
const apiPathDraffAnswer = 'api/users/draft-answer'
const apiPathMenu = 'api/v2/users/menus'
const apiPathQuestionsByMenu = 'api/v2/users/menus/{0}/questions'
const apiPathPrecautionsByMenu = 'api/v2/users/precautions'

class Question extends HTTPRequest {
  getQuestions(menu_id: string) {
    const params = {
      // menu_id: menu_id,
      with: 'menu,draftAnswer',
    }
    return this.get(`${apiPathQuestion}`, { params })
  }
  getMenus(params) {
    return this.get(apiPathMenu, { params })
  }
  getQuestionsByMenuId(menu_id: string) {
    return this.get(format(apiPathQuestionsByMenu, menu_id))
  }
  answer(data: PostAnswersInterView) {
    return this.post(`${apiPathAnswer}`, data)
  }
  checkPrecaution(data) {
    return this.put<Response.Precaution>(`${apiPathCheckPrecaution}`, data)
  }
  getPrecautionsByMenuId(menu_id: string) {
    const params = {
      menu_id: menu_id,
    }
    return this.get(apiPathPrecautionsByMenu, { params })
  }
  draffAnswer(data: PostAnswersInterView) {
    return this.post(`${apiPathDraffAnswer}`, data, {
      headers: { 'skip-loading': true },
    })
  }
}

export default new Question()

import React from 'react'
import styled from 'styled-components'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import LPHeader from 'src/components/layout/Header/LPHeader'
import ReservationInfo from 'src/components/common/ReservationInfo'
import ButtonCustom from 'src/components/form/Button'
import { useNavigate } from 'react-router-dom'
import { PATH } from 'src/pages/myPage/OtherMenu'
import { useAppDispatch } from 'src/store'
import paramUrlAction from 'src/store/domain/paramUrl/paramUrl'

const Container: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const handleNextRoute = () => {
    dispatch(paramUrlAction.updateEntity({}))
    navigate(PATH)
  }

  return (
    <Wrapper>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview mt-15">
        <StepsHeader
          step={4}
          stepActive={4}
          stepActiveName="オンラインカウンセリング予約"
          className="top-70"
        />
        <div className="page-title fs-20 fw-500 mt-30">
          オンラインカウンセリング予約完了
        </div>
        <ReservationInfo />
        <div className="confirm-success__content">
          <p className="margin-b-15px">
            オンラインカウンセリング予約が完了しました。
          </p>
          <span>ご予約した日時に通知メッセージを送信いたします。</span>
          <br />
          <span>
            その通知内のリンクからオンラインカウンセリングを開始してください。
          </span>
        </div>
        <ButtonCustom
          className="btn-default my--30 btn-gurkha"
          loading={false}
          onClick={() => handleNextRoute()}
          name="マイページ"
        />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .confirm-success {
    &__content {
      margin: 20px 0;
      font-size: 14px;
      background-color: #f1efe7;
      padding: 20px;
    }
  }

  .top-70 {
    position: relative;
    top: 0px !important;
  }
`

export default Container

import React from 'react'

import UploadImageContainer from 'src/containers/AutomaticPlan/UploadImage'

const UploadImagePage: React.FC = () => {
  return <UploadImageContainer />
}

export const PATH = '/automatic-plan/upload-image' as const
export default UploadImagePage

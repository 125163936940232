import React from 'react'

import RegisteredContainer from 'src/containers/Auth/Registered'

const RegisterPage: React.FC = () => {
  return <RegisteredContainer />
}

export const PATH = '/registered' as const
export default RegisterPage

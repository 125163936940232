import React from 'react'

import TemporaryReserveConfirmContainer from 'src/containers/Video/TemporaryReserveConfirm'

const TemporaryReserveConfirm: React.FC = () => {
  return <TemporaryReserveConfirmContainer />
}

export const PATH = '/video/temporary-reserve-confirm' as const
export default TemporaryReserveConfirm

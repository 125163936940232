import React from 'react'

import { PATH } from 'src/pages/homeFlow'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import RestrictLayout from 'src/routes/components/RestrictLayout'

const AuthRoute: IRouter[] = [
  {
    element: <RestrictLayout />,
    children: [
      {
        path: PATH.FLOW,
        element: loadable(() => import('src/pages/homeFlow/HomeFlow')),
      },
    ],
  },
]

export default AuthRoute

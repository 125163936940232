import React from 'react'

import MedicalSpecialtyContainer from 'src/containers/MedicalSpecialty'

const MedicalSpecialtyPage: React.FC = () => {
  return <MedicalSpecialtyContainer />
}

export const PATH = '/medical-specialty' as const
export default MedicalSpecialtyPage

import { combineReducers } from '@reduxjs/toolkit'

import { reducer as paidOrder } from './paidOrder'
import { reducer as detailPaidOrder } from './detailPaidOrder'
import { reducer as orderTracking } from './orderTracking'
import { reducer as userTreatment } from './userTreatment'
import { reducer as detailUserTreatment } from './detailUserTreatment'

export default combineReducers({
  paidOrder,
  detailPaidOrder,
  orderTracking,
  userTreatment,
  detailUserTreatment,
})

import React from 'react'
import styled from 'styled-components'
import { useAppSelector } from 'src/store'
import LPHeader from 'src/components/layout/Header/LPHeader'

const NotAllowContainer: React.FC = () => {
  const msg = useAppSelector(
    (state) => state.domain.video.videoAppointment.errorMsg,
  )

  return (
    <Wrapper>
      <LPHeader />
      <div className="video-interview fixed-wrapper">
        <div className="page-title mb-50">オンラインカウンセリングの案内</div>
        <div
          className="video-interview__content"
          dangerouslySetInnerHTML={{ __html: msg || '' }}
        ></div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .video-interview {
    padding: 0 20px;

    .page-title {
      font-size: 24px;
      font-weight: 500;
      margin-top: 30px;
    }

    &__content {
      font-size: 14px;
      background-color: #f1efe7;
      padding: 20px;
      margin: 20px 0 30px;

      span {
        color: #998800;
        font-weight: 700;
      }
    }
  }
`

export default NotAllowContainer

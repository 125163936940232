import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import { Collapse } from 'antd'

import { DELIVERY_STATE, DELIVERY_STATE_NAME } from 'src/enum/deliveryStatus'
import { TrackingInterface } from 'src/interfaces/tracking'
import upIcon from 'src/assets/icons/up.svg'
import downIcon from 'src/assets/icons/down.svg'
import moment from 'moment'
import { JP_DATE_FORMAT } from 'src/constants/app'
import SkipDeliveryModal from '../Modal/SkipDeliveryModal'
import { OrderInterface } from 'src/interfaces/order'

export type PropsType = {
  order: OrderInterface
  currentOrderTracking: TrackingInterface | null
  actionSubmit: any
  setCurrentOrderTracking: any
}

const Container: React.FC<PropsType> = (props) => {
  const { order } = props
  const { Panel } = Collapse
  const [isShowModal, setIsShowModal] = useState(false)

  const getNameDelivery = (state: string) => {
    if (DELIVERY_STATE_NAME[state]) {
      return DELIVERY_STATE_NAME[state]
    } else {
      return '発送予定'
    }
  }

  const getClassDelivery = (state: string) => {
    return _.includes(
      [
        DELIVERY_STATE.DELIVERED,
        DELIVERY_STATE.CANCEL,
        DELIVERY_STATE.DELIVERY_COMPLETE,
      ],
      state,
    )
      ? 'btn mr-15 btn--stark-white'
      : 'btn mr-15 btn--gurkha'
  }

  const makePropsPanel = (tracking: TrackingInterface) => {
    const collapsible: 'header' | 'icon' | 'disabled' = 'disabled'
    return tracking.state == DELIVERY_STATE.DELIVERED ||
      (!order.is_delivery_skipped &&
        order.tracking.length > 1 &&
        _.includes(
          [
            DELIVERY_STATE.READY_FOR_DELIVERY,
            DELIVERY_STATE.PREPARING_FOR_DELIVERY,
          ],
          tracking.state,
        ))
      ? {}
      : { collapsible: collapsible, showArrow: false }
  }
  const makeDeliveryLink = (deliveryNumber: string) => {
    const link =
      'https://jizen.kuronekoyamato.co.jp/jizen/servlet/crjz.b.NQ0010?id='

    return `${link}${deliveryNumber ? deliveryNumber.replace('-', '') : ''}`
  }

  const isShowSkipBtn = (orderTracking: any) => {
    return (
      _.includes(
        [
          DELIVERY_STATE.READY_FOR_DELIVERY,
          DELIVERY_STATE.PREPARING_FOR_DELIVERY,
        ],
        orderTracking.state,
      ) && !orderTracking.skipped_at
    )
  }
  const handleCancel = () => {
    props.setCurrentOrderTracking(null)
    setIsShowModal(false)
  }

  const handleShowModal = (orderTracking: TrackingInterface) => {
    props.setCurrentOrderTracking(orderTracking)
    setIsShowModal(true)
  }

  return (
    <Wrapper>
      <div className="sec-body">
        <Collapse
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            isActive ? <img src={upIcon} /> : <img src={downIcon} />
          }
          bordered={false}
        >
          {order.tracking &&
            order.tracking.map((item, index) => {
              return (
                <Panel
                  header={
                    <div className="d-flex align-content-end">
                      <button className={getClassDelivery(item.state)}>
                        {getNameDelivery(item.state)}
                      </button>
                      <span>
                        {item.delivery_date
                          ? moment(item.delivery_date).format(JP_DATE_FORMAT)
                          : ''}
                      </span>
                    </div>
                  }
                  key={index}
                  {...makePropsPanel(item)}
                >
                  {item.state === DELIVERY_STATE.DELIVERED && (
                    <>
                      <p>配送業者：ヤマト運輸</p>
                      <p>
                        お問い合わせ番号：
                        <a
                          href={makeDeliveryLink(item.delivery_number)}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item.delivery_number}
                        </a>
                      </p>
                    </>
                  )}
                  {isShowSkipBtn(item) && (
                    <div>
                      <p className="cl-chestnut-rose">
                        ※スキップは定期プランの期間内で1回のみ可能。
                      </p>
                      <div className="d-flex justify-content-center p--10">
                        <button
                          className="btn btn--spring-wood btn--border btn--padding"
                          onClick={() => {
                            handleShowModal(item)
                          }}
                        >
                          次回配送スキップ
                        </button>
                      </div>
                    </div>
                  )}
                </Panel>
              )
            })}
        </Collapse>
      </div>
      <SkipDeliveryModal
        isOpen={isShowModal}
        deliveryDate={moment(props.currentOrderTracking?.delivery_date).format(
          JP_DATE_FORMAT,
        )}
        onSubmit={props.actionSubmit}
        onCancel={handleCancel}
      ></SkipDeliveryModal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .sec-body {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .btn {
      border: unset;
      font-size: 12px;
      font-weight: 500;
      min-width: 90px;

      &--stark-white {
        background-color: var(--color-stark-white);
      }

      &--gurkha {
        background-color: var(--color-gurkha);
      }
      &--spring-wood {
        background-color: var(--color-spring-wood);
      }

      &--border {
        border: 1px solid var(--color-rangoon-green);
        border-radius: 30px;
      }

      &--padding {
        padding: 5px 10px;
      }
    }
  }
  .ant-collapse {
    &-borderless > .ant-collapse-item:last-child {
      border-bottom: 1px solid #d9d9d9;
    }
    .ant-collapse-item-active {
      background-color: var(--color-spring-wood);
    }

    .ant-collapse-content-box {
      font-size: 12px;
      padding-bottom: 4px;
      p {
        margin-bottom: 0px;
      }
      a {
        color: #00426e;
      }
    }

    &-item {
      background-color: #ffffff;
    }

    &-icon-position-end > .ant-collapse-item > .ant-collapse-header {
      padding: 12px 12px;
    }

    &-item-disabled > .ant-collapse-header,
    & .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
      color: rgba(0, 0, 0, 0.85);
      cursor: not-allowed;
    }
  }
`

export default Container

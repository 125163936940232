import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch } from 'src/store'
import uiActions from 'src/store/ui/notification'
import styled from 'styled-components'
import { FILE_SIZE_2 } from './const'

type PropsType = {
  title: string
  setMedia: Function
}

export const VideoUpload: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch()

  const inputElFront = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<string>('')

  const onDrop = (event) => {
    const newFile = event.dataTransfer.files?.[0]
    if (!newFile) return
    if (newFile) {
      const fileExtension = newFile.name.split('.').pop().toLowerCase()

      if (fileExtension !== 'mp4' && fileExtension !== 'mov') {
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: 'MP4、MOVファイルのみアップロード可能です。',
          }),
        )
        return
      }

      if (newFile.size > FILE_SIZE_2) {
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: '500MB以内のファイルのみアップロード可能です。',
          }),
        )
        return
      }
      if (!newFile) return
      setFile(URL.createObjectURL(newFile))
      props.setMedia(newFile)
    }
  }

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault() // Disable open image in new tab
    }

    window.addEventListener('dragover', handler)
    window.addEventListener('drop', handler)

    return () => {
      window.removeEventListener('dragover', handler)
      window.removeEventListener('drop', handler)
    }
  }, [])

  return (
    <Wrapper>
      <div className="upload-card-input" onDrop={onDrop}>
        <p className="upload-card-input__text-header">{props.title}</p>
        <div>
          {file ? (
            <div className="image_upload d-flex justify-content-center">
              <video key={file} controls style={{ maxWidth: '100%' }}>
                <source src={file} type="video/mp4" />
              </video>
            </div>
          ) : (
            <div className="upload-card-input__image-demo">
              ※mp4、movファイルを選択してください
            </div>
          )}
          <div className="update-image">
            <p
              className="upload-new"
              onClick={() => {
                inputElFront.current?.click()
              }}
            >
              動画を選択する
            </p>
          </div>
        </div>
      </div>
      <input
        accept=".mp4, .mov"
        type="file"
        ref={inputElFront}
        style={{ display: 'none' }}
        onChange={(event) => {
          if (!event.target.files) return

          const selectedFile = event.target.files[0]
          const fileExtension = selectedFile.name
            .split('.')
            .pop()
            ?.toLowerCase()

          if (fileExtension !== 'mp4' && fileExtension !== 'mov') {
            dispatch(
              uiActions.onNotification({
                type: 'error',
                message: 'MP4、MOVファイルのみアップロード可能です。',
              }),
            )
            return
          }

          if (event.target.files && event.target.files[0].size > FILE_SIZE_2) {
            dispatch(
              uiActions.onNotification({
                type: 'error',
                message: '500MB以内のファイルのみアップロード可能です。',
              }),
            )
            return
          }
          setFile(URL.createObjectURL(event.target.files[0]))
          props.setMedia(event.target.files[0])
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .upload-card-input__image-demo {
    display: block;
    margin-top: 60px;
    margin-bottom: 5px;
  }
`

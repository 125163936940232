import { useState, useEffect, createRef } from 'react'

export default function useScrollToView({
  items,
  activeItem,
  key = 'id',
  options = {},
}) {
  const [stwRefs, setStwRefs] = useState<any>([])

  useEffect(() => {
    setStwRefs((refs) =>
      Array(items.length)
        .fill(0)
        .map((_, i) => refs[i] || createRef()),
    )
  }, [items.length])

  useEffect(() => {
    if (!activeItem) return
    const index = items.findIndex((x) => x[key] === activeItem)
    if (index > -1) stwRefs[index]?.current?.scrollIntoView(options)
    setTimeout(() => {
      if (index > -1) stwRefs[index]?.current?.scrollIntoView(options)
    }, 1000)
  }, [activeItem, stwRefs])

  return {
    stwRefs,
  }
}

import styled from 'styled-components'

export const Wrapper = styled.div`
  .landing {
    text-align: center;
    font-family: Futura, sans-serif;

    &__content {
      padding-top: 30px;

      &-step {
        font-size: 16px;
      }

      &-img {
        width: 100%;
      }

      &-img-first {
        width: 50%;
        float: right;
        margin-top: 10px;
      }

      &-img-second {
        margin-top: 10px;
      }

      &-img-third {
        width: 50%;
        float: left;
        margin-top: 10px;
      }

      &-text {
        display: flex;
        justify-content: center;
        width: 100%;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        padding: 20px;
      }

      &-btn {
        display: flex;
        justify-content: space-between;
        padding: 20px;

        button {
          background: #ffffff;
          border: 2px solid #cfc392;
          border-radius: 4px;
          padding: 20px;
          width: 40%;
          font-weight: bold;

          &.active {
            border: 4px solid #cfc392;
            background: #f1efe7;
          }
        }
      }

      &-option {
        background: #f1efe7;
        margin: 10px 0;
        padding: 30px 15px;

        &-content {
          background: #ffffff;
          border: 1px solid #cfc392;
          border-radius: 4px;
          margin: 10px 0;
          padding: 10px;
          display: flex;
          align-items: center;
          align-self: center;
        }

        &-img {
          height: 60px;
          margin-right: 20px;
        }

        &-title {
          font-weight: 500;
          font-size: 18px;
          line-height: 30px;
          font-weight: bold;
        }
      }

      .active {
        div {
          border: 4px solid #cfc392;
        }
      }

      .btn-black {
        margin: 60px 0;
      }
    }

    &-submit {
      width: 90%;
      padding: 19px;
      color: #ffffff;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 0.13em;
      background: #000000;
      margin: 60px 0 55px;
    }
  }

  .mt-70 {
    .step-header-content {
      margin-top: 70px;
    }
  }
`

import React from 'react'

import AgaLoadingContainer from 'src/containers/Aga/Loading'

const AGALoading: React.FC = () => {
  return <AgaLoadingContainer />
}

export const PATH = '/aga/loading' as const
export default AGALoading

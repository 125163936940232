import React from 'react'

import InterviewContainer from 'src/containers/Video/Interview'

const interview: React.FC = () => {
  return <InterviewContainer />
}

export const PATH = '/video/interview' as const
export default interview

import React from 'react'

import UpdatePassword from 'src/containers/MyPage/UpdatePassword'

const UpdatePasswordPage: React.FC = () => {
  return <UpdatePassword />
}

export const PATH = '/my-page/pwd/update' as const
export default UpdatePasswordPage

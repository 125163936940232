import { PATH as AUTH_PATH } from 'src/pages/auth'
import { createAsyncThunk } from '@reduxjs/toolkit'

import paidOrder from 'src/store/domain/myPage/paidOrder'
import detailPaidOrder from 'src/store/domain/myPage/detailPaidOrder'
import orderTracking from 'src/store/domain/myPage/orderTracking'
import myPageReq from 'src/api/requests/myPage'
import history from 'src/libs/history'
import uiActions from 'src/store/ui/notification'
import _ from 'lodash'

export type PayloadPaidOrders = {
  params: {
    with?: string
  }
  headers?: {
    'skip-loading': boolean
  }
}

export type PayloadPaidOrder = {
  id: string
  params: {
    with?: string
  }
}

export type PayloadOrderTracking = {
  order: string
}

export type PayloadUpdatePassword = {
  password: string
  password_confirmation: string
}

export type countOrdersType = {
  status: string
  paid_orders: boolean
}

export const getPaidOrders = createAsyncThunk<any, PayloadPaidOrders>(
  'get_paid_orders',
  async ({ params, headers }, { dispatch }) => {
    try {
      dispatch(paidOrder.updateLoading(true))
      const res = await myPageReq.paidOrder(params, headers)

      dispatch(paidOrder.updateEntities(res.data.data))
      dispatch(paidOrder.updateLoading(false))
    } catch (error) {
      dispatch(paidOrder.updateLoading(false))
    }
  },
)

export const getDetailPaidOrder = createAsyncThunk<any, PayloadPaidOrder>(
  'get_detail_paid_order',
  async ({ id, params }, { dispatch }) => {
    try {
      dispatch(detailPaidOrder.updateLoading(true))
      const res = await myPageReq.detailPaidOrder(id, params)

      dispatch(detailPaidOrder.updateEntity(res.data))
      dispatch(detailPaidOrder.updateLoading(false))
    } catch (error) {
      dispatch(detailPaidOrder.updateLoading(false))
    }
  },
)

export const getAllOrderTracking = createAsyncThunk<any, PayloadOrderTracking>(
  'get_all_order_tracking',
  async (params, { dispatch }) => {
    try {
      dispatch(orderTracking.updateLoading(true))
      const res = await myPageReq.orderTracking(params)
      const results = _.filter(res.data.data, function (o) {
        return !!o.delivery_date
      })

      dispatch(orderTracking.updateEntities(results))
      dispatch(orderTracking.updateLoading(false))
    } catch (error) {
      dispatch(orderTracking.updateLoading(false))
    }
  },
)

export const updatePassword = createAsyncThunk<any, PayloadUpdatePassword>(
  'my_page_reset_password',
  async (params, { dispatch }) => {
    try {
      await myPageReq.updatePassword(params)
      //AAGA-1055
      //LocalStorage.removeInfo()
      history.push(AUTH_PATH.COMPLETE_PAGE)
    } catch (err: any) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: err.response.data.message,
        }),
      )
    }
  },
)

export const getMyPageCount = async (params) => {
  const data = (await myPageReq.myPageCount(params)).data
  return data.data
}

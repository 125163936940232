import React from 'react'

import PaymentConfirmContainer from 'src/containers/Payment/Confirm'

const PaymentConfirmPage: React.FC = () => {
  return <PaymentConfirmContainer />
}

export const PATH = '/payment/confirm' as const
export default PaymentConfirmPage

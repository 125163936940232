import React from 'react'

import ShampooSubscriptionContainer from 'src/containers/Regular/RegularSubscription'

const ShampooSubscriptionPage: React.FC = () => {
  return <ShampooSubscriptionContainer />
}

export const PATH = '/regular/regular-subscription' as const
export default ShampooSubscriptionPage

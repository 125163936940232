import { Deserializable } from 'src/interfaces/deserializable'
import { QuestionInterface } from 'src/interfaces/question'
import { SubQuestion } from './sub-question'

export class Question implements Deserializable<Question>, QuestionInterface {
  id: number = 0
  name: string = ''
  question: string = ''
  type: string = ''
  options: string[] = []
  sub_question: QuestionInterface

  constructor() {
    this.sub_question = new SubQuestion()
  }

  deserialize(input: Partial<QuestionInterface>): Question {
    Object.assign(this, input)
    if (input.sub_question) {
      this.sub_question = new SubQuestion().deserialize(input.sub_question)
    }
    return this
  }
}

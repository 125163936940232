import React from 'react'

import CompleteIdentification from 'src/containers/AutomaticPlan/CompleteIdentification'

const CompleteIdentificationPage: React.FC = () => {
  return <CompleteIdentification />
}

export const PATH = '/automatic-plan/complete-identification' as const
export default CompleteIdentificationPage

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { State, Channel } from './interface'

const initialState: State = {
  isFetchingChannel: false,
  channel: {
    created_at: '',
    latest_message_at: '',
    ownerable_type: '',
    many_time: '',
    expired_at: '',
    suggest_plan_accepted: '',
    id: null,
    ownerable_id: null,
    status: null,
    type: null,
    is_confirm: false,
    is_high_priority: false,
  },
}

const { actions, reducer } = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    getChannelListRequestSuccess(state, action: PayloadAction<Channel>) {
      state.channel = action.payload
    },
    updateChannel(state, action: PayloadAction<Channel>) {
      state.channel = { ...state.channel, ...action.payload }
    },
    setIsFetchingChannel(state, action: PayloadAction<boolean>) {
      state.isFetchingChannel = action.payload
    },
  },
})

export { reducer, actions }
export default reducer

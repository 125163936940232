import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'

import LPHeader from 'src/components/layout/Header/LPHeader'
import ButtonCustom from 'src/components/form/Button'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'
import { PATH } from 'src/pages/automaticPlan'
import Form from './Form'

const FlowContainer: React.FC = (props: any) => {
  const navigate = useNavigate()

  const nextPrecaution = () => {
    navigate(PATH.PRECAUTION)
  }
  const back = () => {
    navigate(-1)
  }

  return (
    <Template>
      <LPHeader />
      <div className="w-full justify-center mx-auto layout-webview-step register-form">
        <StepsHeader
          step={10}
          stepActive={2}
          stepActiveName={'個人情報を入力、会員登録'}
          className="top-70"
        />
        <HeaderStyle>画像アップロード</HeaderStyle>
        <div>
          <p>
            頭部の状況の確認のため、よろしければ画像をアップロードお願いいたします。
          </p>
          <p>
            <b>
              アップロードは必須ではないので、スキップしていただいても構いません。
            </b>
          </p>
        </div>
        <Form />
        <ButtonCustom
          className="btn-default btn-white mb-30"
          loading={false}
          onClick={nextPrecaution}
          name="スキップして次へ進む"
        />
        <ButtonCustom
          className="btn-default btn-white mb-30"
          loading={false}
          onClick={back}
          name="戻る"
        />
      </div>
    </Template>
  )
}
const Template = styled.div`
  .upload-card-input {
    margin: 10px 0;
    margin-bottom: 40px;
    border: 1px solid #998800;
    &__text-header {
      font-size: 12px;
      font-weight: 700;
      text-align: center;
      margin: 20px;
      color: #998800;
    }
    &__image-demo {
      display: flex;
      &__col {
        width: 50%;
        img {
          margin: auto;
        }
      }
    }
    .update-image {
      display: flex;
      justify-content: center;
      padding: 6px;
      .upload-new {
        font-size: 15px;
        color: white;
        cursor: pointer;
        font-weight: bold;
        background: #998800;
        padding: 15px 40px;
        margin: 30px;
      }
    }
  }
  .btn-skip-aimerte {
    background: white;
    color: black;
    border: solid 1px black;
    margin-top: 30px;
    border-radius: 0;
  }
  .image_upload {
    width: 100%;
    height: 250px;
    &-item {
      width: 100%;
      height: 250px;
      object-fit: contain;
    }
    &-item[src=''] {
      display: none;
    }
  }
`

export default FlowContainer

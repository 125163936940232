import { DoctorInterface } from 'src/interfaces/doctor'
import HTTPRequest from '../index'

const apiPathFirstDoctor = 'api/users/get-first-doctor'

class Doctor extends HTTPRequest {
  getFirstDoctor() {
    const params = {
      with: 'clinics',
    }
    return this.get<DoctorInterface>(`${apiPathFirstDoctor}`, { params })
  }
}

export default new Doctor()

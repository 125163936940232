import React from 'react'
import { HeaderStyle } from 'src/components/layout/Header/HeaderStyle'

import LPHeader from 'src/components/layout/Header/LPHeader'
import ShampooHeader from 'src/components/layout/Header/ShampooHeader'
import ListOrderTracking from 'src/containers/Payment/components/ListOrderTracking'
import LocalStorage from 'src/utils/LocalStorage'

const ConfirmDeliveryContainer: React.FC = (props: any) => {
  const planId = LocalStorage.activePlan

  return (
    <>
      <LPHeader />
      <div className="w-full justify-center mx-auto">
        <ShampooHeader name="シャンプー定期購入手続き" className="top-70" />

        <div className="layout-webview mt-70">
          <HeaderStyle>ご購入内容確認</HeaderStyle>
        </div>
        <div className="w-full justify-center mx-auto page mb-30">
          <ListOrderTracking
            planId={Number(planId)}
            hasImage={false}
            hasInfo
            isHiddenUserInfo
            isHiddenTemporaryOrder
          />
        </div>
      </div>
    </>
  )
}

export default ConfirmDeliveryContainer

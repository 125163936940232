import React from 'react'

import AdditionalShampoo from 'src/containers/AdditionalShampoo'

const AdditionalShampooPage: React.FC = () => {
  return <AdditionalShampoo />
}

export const PATH = '/additional-shampoo' as const
export default AdditionalShampooPage

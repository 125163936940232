import React from 'react'

import TermsContainer from 'src/containers/Terms'

const TermsPage: React.FC = () => {
  return <TermsContainer />
}

export const PATH = '/terms' as const
export default TermsPage

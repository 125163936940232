import React, { useEffect } from 'react'
import styled from 'styled-components'

// Components
import ChatHeader from 'src/containers/Chat/components/ChatHeader'
import ChatContent from 'src/containers/Chat/components/ChatContent'

const ChatContainer = () => {
  useEffect(() => {
    // The trick to correct sizing on mobile
    const resize = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }
    resize()
    window.addEventListener('resize', resize)
    return () => window.removeEventListener('resize', resize)
  }, [])

  return (
    <Wrapper>
      <ChatHeader />
      <ChatContent />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
`

export default ChatContainer

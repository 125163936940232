import React from 'react'
import styled from 'styled-components'
import { Select, Skeleton } from 'antd'

import { useAppSelector } from 'src/store'
import LPHeader from 'src/components/layout/Header/LPHeader'
import SquareForm from './components/SquareForm'
import { usePayment } from './components/usePaymentInfo'
import ButtonCustom from 'src/components/form/Button'

import CardImg from 'src/assets/images/members/payment_card_info.png'
import { useNavigate } from 'react-router-dom'
import { PATH as USER_DETAIL_PATH } from 'src/pages/myPage/UserDetail'
import HeaderUnderlined from 'src/components/layout/Header/HeaderUnderlined'

const PaymentInfoContainer: React.FC = () => {
  const loading = useAppSelector(
    (state) => state.domain.video.videoAppointment.loading,
  )

  const payment = usePayment()
  const { onCharge } = payment
  const navigate = useNavigate()

  return (
    <Wrapper>
      <LPHeader />
      {loading ? (
        <Skeleton />
      ) : (
        <div className="w-full justify-center mx-auto register-form pl-20 pr-20">
          <HeaderUnderlined name="お支払い情報設定" />
          <SelectMethod>
            <label className="aga-label fw-500">支払い方法</label>
            <Select
              className={'aga-select'}
              defaultValue="1"
              options={[
                {
                  value: '1',
                  label: 'クレジットカード',
                },
              ]}
            />
            <img className={'card-img'} src={CardImg} alt="" />
          </SelectMethod>
          <SquareForm onCharge={(token) => onCharge(token)} />
          <ButtonCustom
            className="btn-default btn-white mt-30"
            loading={false}
            disabled={false}
            onClick={() => navigate(USER_DETAIL_PATH)}
            htmlType="button"
            name="キャンセル"
          />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``
const SelectMethod = styled.div`
  margin-top: 40px;

  .card-img {
    width: 100%;
    margin-top: 15px;
  }
`

export default PaymentInfoContainer

import { createAsyncThunk } from '@reduxjs/toolkit'

import notificationRequest from 'src/api/requests/notification'
import { store } from 'src/store'
import listNotificationActions from 'src/store/domain/notification/listNotification'
import uiActions from '../store/ui/notification'
import unreadNoticeAction from 'src/store/domain/notification/countUnreadNotification'
import listNoticeAction from 'src/store/domain/notification/listNotification'

export type PayloadParams = {
  page?: number
  per_page?: number
}

export const getListNotification = createAsyncThunk<any, PayloadParams>(
  'get_notification',
  async (params, { dispatch }) => {
    try {
      dispatch(listNotificationActions.updateLoading(true))
      const res = await notificationRequest.getListNotification(params)
      if (res.data) {
        dispatch(listNotificationActions.updateEntity(res.data.data))
      }
      dispatch(listNotificationActions.updateLoading(false))
    } catch (error) {
      dispatch(listNotificationActions.updateLoading(false))
    }
  },
)

export const getDetailNotification = createAsyncThunk<any, number>(
  'get_detail_notification',
  async (id, { dispatch }) => {
    try {
      await notificationRequest.getDetailNotification(id)
    } catch (error) {
      dispatch(
        uiActions.onNotification({
          type: 'error',
          message: 'error',
        }),
      )
    }
  },
)

export const setNumberOfUnreadNotice = (numberUnreadNotice) => {
  store.dispatch(unreadNoticeAction.updateEntity(numberUnreadNotice))
}

export const decrementUnreadNotice = (number) => {
  store.dispatch(unreadNoticeAction.decrementEntity(number))
}

export const pushNotificationToOldState = (notification) => {
  store.dispatch(listNoticeAction.pushNotificationToOldState(notification))
}

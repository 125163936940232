import React from 'react'
import styled from 'styled-components'
import moment from 'src/libs/moment'
import _ from 'lodash'

import { DELIVERY_STATE, DELIVERY_STATE_NAME } from 'src/enum/deliveryStatus'
import { TrackingInterface } from 'src/interfaces/tracking'
import { JP_DATE_FORMAT } from 'src/constants/app'

export type PropsType = {
  tracking: TrackingInterface[]
}

const Container: React.FC<PropsType> = (props) => {
  const { tracking } = props

  const getNameDelivery = (state: string) => {
    if (DELIVERY_STATE_NAME[state]) {
      return DELIVERY_STATE_NAME[state]
    } else {
      return '発送予定'
    }
  }

  const getClassDelivery = (state: string) => {
    return _.includes(
      [
        DELIVERY_STATE.DELIVERED,
        DELIVERY_STATE.CANCEL,
        DELIVERY_STATE.DELIVERY_COMPLETE,
      ],
      state,
    )
      ? 'btn-gray'
      : 'btn-blue'
  }

  return (
    <Wrapper>
      <div className="sec-body">
        {tracking &&
          tracking.map((item, index) => {
            return (
              <div
                key={index}
                className="d-flex justify-content-between content"
              >
                <span>{moment(item.delivery_date).format(JP_DATE_FORMAT)}</span>
                <button className={getClassDelivery(item.state)}>
                  {getNameDelivery(item.state)}
                </button>
              </div>
            )
          })}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .sec-body {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .btn-gray {
      color: var(--color-quill-gray);
      border: 1px solid var(--color-quill-gray);
      padding: 5px;
      min-width: 90px;
      border: unset;
    }
    .btn-blue {
      color: #1e4c82;
      border: 1px solid #1e4c82;
      padding: 5px;
      min-width: 90px;
      border: unset;
    }
  }
  .content {
    font-style: normal;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #f1efe7;
    padding-bottom: 15px;
  }
`

export default Container

import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

import moment from 'moment'
import { JP_DATE_FORMAT, JP_DATE_TIME_FORMAT } from 'src/constants/app'
import { useAppSelector } from 'src/store'
import LocalStorage from 'src/utils/LocalStorage'

const Container: React.FC = () => {
  const user = useAppSelector((state) => state.ui.app.authInfo)
  const schedule = LocalStorage.videoSchedule
  const reservationDate = schedule
    ? `${schedule.date} ${schedule.time}`
    : _.get(user, 'reservations[0].reservation_date')

  return (
    <Wrapper>
      <table className="reservation-info">
        <tbody>
          <tr>
            <td className="reservation-info-title">氏名</td>
            <td className="reservation-info-value">{`${user?.family_name} ${user?.given_name}`}</td>
          </tr>
          <tr>
            <td className="reservation-info-title">フリガナ</td>
            <td className="reservation-info-value">{`${user?.family_name_kana} ${user?.given_name_kana}`}</td>
          </tr>
          <tr>
            <td className="reservation-info-title">生年月日</td>
            <td className="reservation-info-value">
              {user?.date_of_birth
                ? moment(user?.date_of_birth).format(JP_DATE_FORMAT)
                : ''}
            </td>
          </tr>
          <tr>
            <td className="reservation-info-title">診療科目</td>
            <td className="reservation-info-value">
              {LocalStorage.getMenuName()}
            </td>
          </tr>
          <tr>
            <td className="reservation-info-title">ご予約日時</td>
            <td className="reservation-info-value">
              {moment(reservationDate).format(JP_DATE_TIME_FORMAT)}
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .reservation-info {
    margin-top: 20px;
    width: 100%;

    tr {
      border-bottom: 1px solid #f1efe7;
    }

    .reservation-info-value {
      padding: 15px 0px;
      text-align: right;
      font-weight: 500;
      line-height: 20px;
    }
  }
`

export default Container

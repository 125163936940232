import React from 'react'

import { PATH } from 'src/pages/notification'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'

const VideoRoute: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.LIST_NOTIFICATION,
        element: loadable(
          () => import('src/pages/notification/ListNotification'),
        ),
      },
    ],
  },
]

export default VideoRoute

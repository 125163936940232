import { useLocation, useSearchParams } from 'react-router-dom'
import qs from 'query-string'

const useQueryUrl = <T = Record<string, any>>() => {
  const [searchQuery, setSearchQuery] = useSearchParams({})
  const location = useLocation()

  const search = qs.parse(location.search, {
    parseBooleans: true,
    arrayFormatSeparator: '|',
    arrayFormat: 'bracket-separator',
  }) as unknown

  return { searchQuery, query: search as T, setSearchQuery }
}

export default useQueryUrl

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { PATH } from 'src/pages/myPage'
import LocalStorage from 'src/utils/LocalStorage'

const RestrictLayout: React.FC = () => {
  const accessToken = LocalStorage.accessToken
  if (accessToken) {
    return <Navigate to={`${PATH.OTHER_MENU}`} />
  }
  return <Outlet />
}

export default RestrictLayout

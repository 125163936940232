import React from 'react'

import PaymentErrorContainer from 'src/containers/Payment/Error'

const PaymentErrorPage: React.FC = () => {
  return <PaymentErrorContainer />
}

export const PATH = '/payment/error' as const
export default PaymentErrorPage

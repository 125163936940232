import React from 'react'

import { PATH as TERMS_PATH } from 'src/pages/terms'
import { PATH as POLICY_PATH } from 'src/pages/policy'
import { PATH as LEGAL_NOTICE_PATH } from 'src/pages/legalNotice'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import GuestLayout from 'src/routes/components/GuestLayout'

const AuthRoute: IRouter[] = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: TERMS_PATH.TERMS,
        element: loadable(() => import('src/pages/terms/Terms')),
      },
      {
        path: POLICY_PATH.POLICY,
        element: loadable(() => import('src/pages/policy/Policy')),
      },
      {
        path: LEGAL_NOTICE_PATH.LEGAL_NOTICE,
        element: loadable(() => import('src/pages/legalNotice/LegalNotice')),
      },
    ],
  },
]

export default AuthRoute

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  entity: Response.VideoAppointment | null
  errorMsg: string | null
  agoraChannel: Response.AgoraChannel | null
}

const initialState: State = {
  loading: false,
  entity: null,
  errorMsg: null,
  agoraChannel: null,
}

const { actions, reducer } = createSlice({
  name: 'video_appointment',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Response.VideoAppointment>) => {
      state.entity = action.payload
    },
    updateAgoraChannel: (
      state,
      action: PayloadAction<Response.AgoraChannel>,
    ) => {
      state.agoraChannel = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateErrorMsg: (state, action: PayloadAction<string>) => {
      state.errorMsg = action.payload
    },
  },
})

export { reducer }
export default actions

import { PLAN_TYPE } from './app'

export const PLAN_SHAMPOO_SUBSCRIPTION_ID = 1
export const PLAN_SHAMPOO_ADDITIONAL_ID = 4

export const PLAN_SHAMPOO = [
  PLAN_SHAMPOO_SUBSCRIPTION_ID,
  PLAN_SHAMPOO_ADDITIONAL_ID,
]

export const PLAN_ID_TO_TYPE = {
  1: PLAN_TYPE.BUY_SHAMPOO_SUBSCRIPTION,
  4: PLAN_TYPE.BUY_SHAMPOO_ADDITIONAL,
}

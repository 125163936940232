import { Col, ConfigProvider, Row } from 'antd'
import ja_JP from 'antd/es/locale/ja_JP'
import lodashGet from 'lodash/get'
import some from 'lodash/some'
import 'moment/locale/ja'
import qs from 'query-string'
import React from 'react'
import TagManager from 'react-gtm-module'
import {
  useLocation,
  useNavigate,
  useRoutes,
  useSearchParams,
} from 'react-router-dom'

import lodashOmit from 'lodash/omit'
import { PATH as PATH_ADDITIONAL_SHAMPOO } from 'src/pages/additionalShampoo'
import { PATH as PATH_AUTH } from 'src/pages/auth'
import { PATH as ERROR_PATH } from 'src/pages/error'
import { PATH as PATH_FLOW } from 'src/pages/homeFlow'
import { PATH as PATH_LEGAL_NOTICE } from 'src/pages/legalNotice'
import { PATH as PATH_MY_PAGE } from 'src/pages/myPage'
import { PATH as PATH_POLICY } from 'src/pages/policy'
import { PATH as PATH_TERMS } from 'src/pages/terms'
import { PATH as PATH_VIDEO } from 'src/pages/video'
import routeConfig from 'src/routes'
import paramUrlAction from 'src/store/domain/paramUrl/paramUrl'
import LoadingSuspense from './components/application/LoadingSuspense'
import { paramsType } from './containers/MyPage/Treatment'
import Notification from './containers/Notification'
import { USER_TREATMENT_STATE } from './enum/userTreatmentState'
import { useAppDispatch, useAppSelector } from './store'
import { getInfo } from './useCase/user'
import {
  fetcherUserTreatmentFromMail,
  getUserTreatmentList,
} from './useCase/userTreatment'
import LocalStorage from './utils/LocalStorage'
import { handleLinkFromMail } from './utils/helper'

const KEY_LINK_ACCESS_DIRECT = 'default'
export const KEY_FROM_MAIL = 'mail'

if (LocalStorage.getGtmId()) {
  TagManager.initialize({
    gtmId: LocalStorage.getGtmId(),
  })
}

function App() {
  const routes = useRoutes(routeConfig)
  const loading = useAppSelector((state) => state.ui.app.loading)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const user = useAppSelector((state) => state.ui.app.authInfo)
  const paramUrl = useAppSelector(
    (state) => state.domain.paramUrl.paramUrl.entity,
  )

  const accessToken = LocalStorage.accessToken
  const params = qs.parse(location.search)

  const [, setSearchParam] = useSearchParams()

  const paramsUrl = {
    ads: params.ads,
    utm_campaign: params.utm_campaign,
    utm_medium: params.utm_medium,
    utm_source: params.utm_source,
  }
  const id = location.pathname.substring(location.pathname.lastIndexOf('/') + 1)

  const NOT_CHECK_ERROR_ROUTERS = [
    PATH_MY_PAGE.OTHER_MENU,
    PATH_ADDITIONAL_SHAMPOO.PURCHASE_ADDITIONAL_PATH,
    PATH_ADDITIONAL_SHAMPOO.CONFIRM_DELIVERY_PATH,
    PATH_AUTH.LINK_LINE,
    PATH_AUTH.SIGN_IN,
    PATH_TERMS.TERMS,
    PATH_POLICY.POLICY,
    PATH_LEGAL_NOTICE.LEGAL_NOTICE,
    PATH_VIDEO.VIDEO_SCHEDULE,
    PATH_FLOW.FLOW.replace(':clinic_uuid', id.toString()),
  ]

  const NOT_IMPORT_TOKEN = [PATH_AUTH.RESET_PASSWORD_PATH]
  React.useEffect(() => {
    if (location.search) {
      dispatch(paramUrlAction.updateEntity(paramsUrl))
    }
  }, [])

  React.useEffect(() => {
    if (paramUrl) {
      setSearchParam({ ...paramUrl })
    }
  }, [location.pathname])

  React.useEffect(() => {
    if (
      location.key === KEY_LINK_ACCESS_DIRECT &&
      !some(
        NOT_CHECK_ERROR_ROUTERS,
        (link) => location && link === location.pathname,
      )
    ) {
      if (params?.from !== KEY_FROM_MAIL) {
        navigate(ERROR_PATH.STATUS_ERROR)
      } else {
        if (user) {
          // set gg tag id
          const gtmId = lodashGet(user, 'clinics[0].gtm_id', '')
          const currentGmtId = LocalStorage.getGtmId()
          LocalStorage.setGtmId(gtmId)
          if (currentGmtId !== gtmId) {
            TagManager.initialize({
              gtmId: gtmId,
            })
          }

          fetcherUserTreatmentAndRedirect()
        }
      }
    }
  }, [user])

  const fetcherUserTreatmentAndRedirect = async () => {
    if (params?.userTreatmentId) {
      const userTreatment = await fetcherUserTreatmentFromMail(
        params?.userTreatmentId,
        {
          with: 'menu',
        },
      )

      const redirectRoute = handleLinkFromMail(
        location,
        user?.new_state,
        userTreatment,
      )
      redirectRoute && navigate(redirectRoute)
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }, 10)
  }, [location])

  React.useEffect(() => {
    const params = qs.parse(location.search)
    if (
      params.token &&
      !some(NOT_IMPORT_TOKEN, (link) => location && link === location.pathname)
    ) {
      LocalStorage.setToken(params.token)
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,orderPaymented',
        }),
      )
    }

    if ((!user || (user && !user.id)) && accessToken) {
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,orderPaymented',
        }),
      )
      const urlParams = qs.parse(location.search)
      const notState = [
        USER_TREATMENT_STATE.DOCTOR_PRESCRIPTION_OK,
        USER_TREATMENT_STATE.SUBSCRIPTION_CONTRACT,
      ]

      let params: paramsType = {
        with: 'menu,reservations,lastDoctorFeedback,lastOrder.plan,userTreatmentTransitions',
        not_state: notState.join(','),
      }

      if (urlParams.from === 'chat') {
        params = lodashOmit(params, 'not_state')
      }
      dispatch(getUserTreatmentList(params))
    }
  }, [])

  return (
    <ConfigProvider locale={ja_JP} componentSize="middle">
      <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          {routes}
          {loading && <LoadingSuspense />}
          <Notification />
        </Col>
      </Row>
    </ConfigProvider>
  )
}

export default App

import React from 'react'

import LinkLineContainer from 'src/containers/Auth/LinkLine'

const LinkLinePage: React.FC = () => {
  return <LinkLineContainer />
}

export const PATH = '/link-line' as const
export default LinkLinePage

import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  default as userTreatmentApi,
  default as userTreatmentRequest,
} from 'src/api/requests/userTreatment'
import { FormValues } from 'src/containers/MedicalSpecialty/components/useMedicalSpecialtyForm'
import history from 'src/libs/history'
import { PATH as PATH_AUTOMATIC_PLAN } from 'src/pages/automaticPlan'
import detailUserTreatment from 'src/store/domain/myPage/detailUserTreatment'
import userTreatment from 'src/store/domain/myPage/userTreatment'
import { LocalStorage } from 'src/utils/LocalStorage'

export interface PostCreateUserTreatment {
  data: FormValues
}

export type PayloadIndexUserTreatmentType = {
  with?: string
}

export const getUserTreatmentList = createAsyncThunk<
  any,
  PayloadIndexUserTreatmentType
>('get_user_treatment_list', async (params, { dispatch }) => {
  try {
    dispatch(userTreatment.updateLoading(true))
    const res = await userTreatmentApi.index(params)

    LocalStorage.setUserTreatmentId(String(res.data.data[0].id))
    LocalStorage.setMenuName(res.data.data[0].menu?.name || '')
    dispatch(userTreatment.updateEntities(res.data.data))
    dispatch(userTreatment.updateLoading(false))
  } catch (error) {
    dispatch(userTreatment.updateLoading(false))
  }
})

export const createUserTreatment = createAsyncThunk<
  any,
  PostCreateUserTreatment
>('create_user_treatment', async ({ data }) => {
  try {
    const response = await userTreatmentRequest.create(data)
    const userTreatment = response.data.data
    LocalStorage.setUserTreatmentId(String(userTreatment.id))
    LocalStorage.setMenuName(String(userTreatment.menu.name))

    history.push(
      PATH_AUTOMATIC_PLAN.QUESTIONS.replace(':id', String(data.menu_id)),
    )
  } catch (err: any) {
    // eslint-disable-next-line no-console
    console.log(err)
  }
})

export const getDetailUserTreatment = createAsyncThunk<any, any>(
  'get_user_treatment_detail',
  async ({ id, params }, { dispatch }) => {
    try {
      dispatch(detailUserTreatment.updateLoading(true))
      const res = await userTreatmentApi.show(id, params)

      dispatch(detailUserTreatment.updateEntity(res.data.data))
      dispatch(detailUserTreatment.updateLoading(false))
    } catch (error) {
      dispatch(detailUserTreatment.updateLoading(false))
    }
  },
)

export const fetcherUserTreatmentFromMail = async (id, params) => {
  const userTreatment = (await userTreatmentApi.show(id, params)).data.data

  LocalStorage.setUserTreatmentId(userTreatment.id)
  LocalStorage.setMenuName(userTreatment.menu?.name)

  return userTreatment
}

export const uploadUserTreatmentImage = createAsyncThunk<any, any>(
  'upload_user_treatment_image',
  async ({ images, video_path }) => {
    const userTreatmentId = LocalStorage.getUserTreatmentId()
    if (!userTreatmentId) return

    await userTreatmentRequest.uploadUserTreatmentImage(userTreatmentId, {
      images,
      video_path,
    })
  },
)

export const getPresignedUrl = createAsyncThunk<any, any>(
  'get_presigned_url',
  async (filename) => {
    const { data } = await userTreatmentRequest.getPresignedUrl(filename)
    return data
  },
)

export const uploadByPresignedUrl = createAsyncThunk<any, any>(
  'upload_by_presigned_url',
  async ({ presigned_url, video }) => {
    await userTreatmentRequest.uploadByPresignedUrl(presigned_url, video)
  },
)

import HTTPRequest from '../index'

const apiPathCheckPrecaution = 'api/users/check-caution'
const apiPathPrecautionsByMenu = 'api/v2/users/precautions'

class Precaution extends HTTPRequest {
  checkPrecaution(data) {
    return this.put<Response.Precaution>(`${apiPathCheckPrecaution}`, data)
  }
  getPrecautionsByMenuId(menu_id: string) {
    const params = {
      menu_id: menu_id,
    }
    return this.get(apiPathPrecautionsByMenu, { params })
  }
}

export default new Precaution()

import React from 'react'

import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import GuestLayout from 'src/routes/components/GuestLayout'

const AuthRoute: IRouter[] = [
  {
    element: <GuestLayout />,
    children: [
      {
        path: '*',
        element: loadable(() => import('src/pages/error/404')),
      },
    ],
  },
]

export default AuthRoute

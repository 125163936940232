import { PlanDetailRequestInterface } from 'src/interfaces/plan'
import format from 'string-format'

import { TemporaryOrderResponseInterface } from 'src/interfaces/plan'

import { PlanResponseInterface } from 'src/interfaces/plan.d'
import { OrderRequest } from 'src/useCase/plan'
import HTTPRequest from '../index'

const apiPathPlan = 'api/v2/users/plans'
const apiPathOrder = 'api/users/orders'
const apiPathTemporaryOrder = 'api/users/plans/{0}/get-temporary-order'
const apiPathPlanDetail = 'api/users/plans/{0}'

class Plan extends HTTPRequest {
  getPlans(params) {
    return this.get<PlanResponseInterface[]>(`${apiPathPlan}`, {
      params,
    })
  }

  order(params: OrderRequest) {
    return this.post(`${apiPathOrder}`, params)
  }

  getTemporaryOrder(planId: number) {
    return this.get<TemporaryOrderResponseInterface>(
      format(`${apiPathTemporaryOrder}`, planId),
    )
  }

  getPlanDetail(planId: number, params: PlanDetailRequestInterface) {
    return this.get<PlanResponseInterface>(
      format(`${apiPathPlanDetail}`, planId),
      { params },
    )
  }
}

export default new Plan()

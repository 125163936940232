import React from 'react'

import PlanDetailContainer from 'src/containers/AutomaticPlan/PlanDetail'

const PlanDetailPage: React.FC = () => {
  return <PlanDetailContainer />
}

export const PATH = '/plans/:id' as const
export default PlanDetailPage

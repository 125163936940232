import { PlanInterface } from 'src/interfaces/plan'
import { CardInterface } from './Card'

export const defaultUserInfo: UserInterface = {
  address: '',
  admin_review_at: '',
  avatar: '',
  avatar_url: '',
  back_side_card: '',
  back_side_card_url: '',
  building_name: '',
  chat: true,
  city_id: '',
  created_at: '',
  current_router: '',
  date_of_birth: '',
  date_orthodontic: '',
  deleted_at: '',
  email: '',
  family_name: '',
  family_name_kana: '',
  first_payment_flag: '',
  front_side_card: '',
  front_side_card_url: '',
  gender_id: '',
  given_name: '',
  given_name_kana: '',
  go_page_chat: '',
  head_image: '',
  id: 0,
  insurance_card_number: '',
  is_bought_shampoo_subscription: false,
  is_review_valid: '',
  is_verified: '',
  line_follow_at: '',
  line_id: '',
  line_name: '',
  menu_choose: '',
  name: '',
  name_kana: '',
  note: '',
  noted_at: '',
  phone: '',
  plan_id: '',
  postal_number: '',
  prefecture_id: '',
  prescription_ng_lasted_at: '',
  previous_router: '',
  reason: '',
  registered_at: '',
  require_kit_at: '',
  reservation_verified: '',
  shampoo_router: '',
  state: '',
  status_review: 0,
  updated_at: '',
  user_upload_at: '',
  head_image_url: '',
  latestOrderCanPay: null,
}

export interface UserInterface {
  admin_review_at: null
  avatar: string
  avatar_url: null
  back_side_card: string
  back_side_card_url: null
  building_name: string
  chat: true
  city_id: string
  created_at: string
  current_router: string
  date_of_birth: string
  date_orthodontic: null
  deleted_at: null
  email: string
  family_name: string
  family_name_kana: string
  first_payment_flag: null
  front_side_card: string
  front_side_card_url: null
  gender_id: string
  given_name: string
  given_name_kana: string
  go_page_chat: null
  head_image: string
  id: number
  insurance_card_number: null
  is_bought_shampoo_subscription: false
  is_review_valid: null
  is_verified: null
  line_follow_at: string
  line_id: string
  line_name: string
  menu_choose: null
  name: string
  name_kana: null
  note: null
  noted_at: null
  phone: string
  plan_id: null
  postal_number: string
  prefecture_id: string
  prescription_ng_lasted_at: null
  previous_router: string
  reason: null
  registered_at: string
  require_kit_at: null
  reservation_verified: null
  shampoo_router: null
  state: string
  status_review: number
  updated_at: string
  user_upload_at: string
  card?: CardInterface
  reservations?: ReservationInterface[]
  address: string
  head_image_url: string
  unread_count: number
  notice_unread_count: number
  state_transitions?: StateTransitionInterface[]
  latestOrderCanPay: OrderCanPay
  cycleOrderPaid: null | cycleOrderPaid
  current_flow: string | null
  hasPassword: boolean
  last_doctor_feedback?: LatestOrderFeedback
  new_state: string
  user_treatments: any
}

export interface cycleOrderPaid {}

export interface OrderCanPay {
  plan: PlanInterface
  plan_id: number
}

export interface LatestOrderFeedback {
  content: string
  created_at: string
  deleted_at: string
  doctor_id: number
  id: number
  interview: string
  plan_id: string
  type: string
  updated_at: string
  user_id: number
}

export interface StateTransitionInterface {
  before_state: string
  created_at: string
  deleted_at: string
  id: number
  state: string
  updated_at: string
  user_id: number
}

export interface ReservationInterface {
  ampm1: string
  ampm2: string
  ampm3: string
  date1: string
  date2: string
  date3: string
  doctor_id: number
  id: number
  reservation_date: string
  status: string
  user_id: number
}
export interface UserRequestInterface {
  family_name: string
  given_name: string
  family_name_kana: string
  given_name_kana: string
  date_of_birth: string
  gender_id: string
  email: string
  clinic_uuid: string
}

export interface UserInfoRequestInterface {
  with?: string
}

export interface UpdateCurrentFlowRequestInterface {
  current_flow: string | null
}

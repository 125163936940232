import React from 'react'

import QuestionsContainer from 'src/containers/AutomaticPlan/Questions'

const QuestionPage: React.FC = () => {
  return <QuestionsContainer />
}

export const PATH = '/automatic-plan/interview/:id' as const
export default QuestionPage

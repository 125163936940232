import React, { Fragment, useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { useAppSelector } from 'src/store'

// Selectors
import { selectCountMessageRead } from 'src/store/domain/chat/message/selector'
// Constants
import { MESSAGE_TYPE } from 'src/containers/Chat/constants'

type Props = {
  item: {
    id: number
    type_message: number
    message_time: string
    image_url: string
    message_content: string
    created_at: string
    is_temporary: boolean
  }
}

const MyMessage = (props: Props) => {
  const { item } = props
  const {
    id,
    type_message,
    message_content,
    image_url,
    created_at,
    is_temporary,
  } = item
  const countMessageRead = useAppSelector(selectCountMessageRead)

  const isImage = useMemo(() => {
    if (!id) {
      return false
    }
    return type_message === MESSAGE_TYPE.IMAGE
  }, [id, type_message])

  const messageTime = useMemo(() => {
    moment.locale('ja')
    let startDate = moment(created_at).startOf('day')
    let time = moment(created_at)
    if (moment().diff(startDate, 'days') == 0) {
      return time.format('HH:mm')
    }
    return time.format('M/DD HH:mm')
  }, [created_at])

  return (
    <Wrapper>
      <div className="my-message">
        <Fragment>
          <div className="my-message__message-time">
            <div
              className={
                countMessageRead >= item.id
                  ? 'my-message__message-time__text'
                  : 'my-message__hidden'
              }
            >
              既読
            </div>
            <div>{is_temporary ? '送信中...' : messageTime}</div>
          </div>
          {isImage ? (
            <div className="my-message__message">
              <img src={image_url} />
            </div>
          ) : (
            <div
              className="my-message__message"
              dangerouslySetInnerHTML={{ __html: message_content }}
            ></div>
          )}
        </Fragment>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .my-message {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;

    &__hidden {
      visibility: hidden;
    }

    &__message-time {
      font-size: 12px;
      color: #989898;

      &__text {
        text-align: right;
      }
    }

    &__message {
      margin-left: 8px;
      background: #f1efe7;
      border: 1px solid #998800;
      padding: 10px 8px;
      border-radius: 10px;
      max-width: 75%;
      word-break: break-word;
      font-size: 14px;
      white-space: pre-line;
    }
  }
`

export default MyMessage

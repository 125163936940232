import React from 'react'

import { PATH } from 'src/pages/myPage'
import loadable from 'src/utils/Loadable'
import { IRouter } from 'src/interfaces/route'
import AuthenticatedLayout from 'src/routes/components/AuthenticatedLayout'

const MyPageRoute: IRouter[] = [
  {
    element: <AuthenticatedLayout />,
    children: [
      {
        path: PATH.OTHER_MENU,
        element: loadable(() => import('src/pages/myPage/OtherMenu')),
      },
      {
        path: PATH.PURCHASE_HISTORY,
        element: loadable(() => import('src/pages/myPage/PurchaseHistory')),
      },
      {
        path: PATH.DELIVERY_SCHEDULE,
        element: loadable(() => import('src/pages/myPage/DeliverySchedule')),
      },
      {
        path: PATH.ALL_DELIVERY_SCHEDULE,
        element: loadable(() => import('src/pages/myPage/AllDeliverySchedule')),
      },
      {
        path: PATH.USER_DETAIL,
        element: loadable(() => import('src/pages/myPage/UserDetail')),
      },
      {
        path: PATH.UPDATE_PASSWORD_PATH,
        element: loadable(() => import('src/pages/myPage/UpdatePassword')),
      },
      {
        path: PATH.DELIVERY_DESTINATION_INFO_PATH,
        element: loadable(
          () => import('src/pages/myPage/DeliveryDestinationInfo'),
        ),
      },
      {
        path: PATH.PAYMENT_INFO_PATH,
        element: loadable(() => import('src/pages/myPage/PaymentInfo')),
      },
      {
        path: PATH.TREATMENT_PATH,
        element: loadable(() => import('src/pages/myPage/Treatment')),
      },
    ],
  },
]

export default MyPageRoute

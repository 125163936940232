import videoIncoming, { PATH as VIDEO_INCOMING } from './Incoming'
import videoInterview, { PATH as VIDEO_INTERVIEW } from './Interview'
import videoSchedule, { PATH as VIDEO_SCHEDULE } from './Schedule'
import thank, { PATH as THANK } from './Thank'
import notAllowed, { PATH as NOT_ALLOWED } from './NotAllowed'
import videoCalling, { PATH as CALLING } from './Calling'
import temporaryReserveConfirm, {
  PATH as TEMPORARY_RESERVE_CONFIRM,
} from './TemporaryReserveConfirm'
import completeTemporaryReserve, {
  PATH as COMPLETE_TEMPORARY_RESERVE,
} from './CompleteTemporaryReserve'

export default {
  videoIncoming,
  videoInterview,
  videoSchedule,
  notAllowed,
  videoCalling,
  thank,
  temporaryReserveConfirm,
  completeTemporaryReserve,
}
export const PATH = {
  VIDEO_INCOMING,
  VIDEO_INTERVIEW,
  VIDEO_SCHEDULE,
  NOT_ALLOWED,
  CALLING,
  THANK,
  TEMPORARY_RESERVE_CONFIRM,
  COMPLETE_TEMPORARY_RESERVE,
}

import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import qs from 'query-string'

import { PATH } from 'src/pages/auth/SignIn'
import LocalStorage from 'src/utils/LocalStorage'
import { KEY_FROM_MAIL } from 'src/App'
import { useAppSelector } from 'src/store'
import { createEchoConnection } from 'src/utils/echoService'
import { SOCKET_EVENT } from 'src/containers/Chat/constants'
import {
  pushNotificationToOldState,
  setNumberOfUnreadNotice,
} from 'src/useCase/notification'

const AuthenticatedLayout: React.FC = () => {
  const location = useLocation()
  const accessToken = LocalStorage.accessToken
  const params = qs.parse(location.search)
  const user = useAppSelector((state) => state.ui.app.authInfo)

  if (!accessToken && params.from !== KEY_FROM_MAIL) {
    return <Navigate to={`${PATH}?redirect=${btoa(location.pathname)}`} />
  }

  useEffect(() => {
    if (user?.id) {
      const channelName = `private-user.${user?.id}`
      const socket = socketListen(channelName)
      return () => {
        socket.leaveChannel(channelName)
      }
    }
  }, [user?.id])

  const socketListen = (channelName) => {
    const echo = createEchoConnection()
    echo.channel(channelName).listen(SOCKET_EVENT.NOTIFICATION, ({ data }) => {
      setNumberOfUnreadNotice(data.notice_unread_count)
      pushNotificationToOldState(data)
    })
    return echo
  }

  return <Outlet />
}

export default AuthenticatedLayout

import {
  configureStore,
  ThunkAction,
  AnyAction,
  isRejectedWithValue,
  isRejected,
  isFulfilled,
  AsyncThunk,
} from '@reduxjs/toolkit'
import {
  useDispatch,
  TypedUseSelectorHook,
  useSelector,
  useStore,
} from 'react-redux'
import { Middleware } from 'redux'
import rootReducer from './rootReducer'

const endLoadingMiddleware: Middleware = () => (next) => (action) => {
  const result = next(action)
  if (
    isRejectedWithValue(action) ||
    isRejected(action) ||
    isFulfilled(action)
  ) {
    next({ type: 'app/updateLoading', payload: false })
  }

  return result
}

const notifyMiddleware: Middleware = () => (next) => (action) => {
  const result = next(action)

  if (isFulfilled(action)) {
    action.payload?.type === 'success' &&
      next({
        type: 'notification/onNotification',
        payload: {
          type: 'success',
          message: action.payload?.message,
        },
      })
  }

  if (isRejectedWithValue(action)) {
    action.payload?.type === 'error' &&
      next({
        type: 'notification/onNotification',
        payload: {
          type: 'error',
          message: action.payload?.message,
        },
      })
  }

  return result
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(endLoadingMiddleware, notifyMiddleware)
  },
  devTools: process.env.NODE_ENV !== 'production',
})

// const initDispatch = () => {
//   store.dispatch({
//     type: 'app/updateAuthInfo',
//     payload: LocalStorage.authInfo,
//   })
// }
// initDispatch()

export type RootState = ReturnType<typeof rootReducer>
export type Dispatch = typeof store.dispatch
export type Store = typeof store
export type Action<R = any> = ThunkAction<
  Promise<R>,
  RootState,
  null,
  AnyAction
>

export const useAppDispatch = () => useDispatch<Dispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppStore = () => useStore<RootState, AnyAction>()
export const useLoading = <
  Action extends AsyncThunk<any, any, any>,
  TSelect extends string | null,
>(
  action: Action,
  select: (state: RootState) => TSelect,
) => useAppSelector(select) === action.pending.type

import { Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import ButtonCustom from 'src/components/form/Button'
import {
  APP_SQ_ENDPOINT_JS,
  SQ_APPLICATION_ID,
  SQ_LOCATION_ID,
} from 'src/constants/env-constant'
import useScript from 'src/hook/useScript'
import styled from 'styled-components'

type Props = {
  onCharge: Function
}

const SquareForm = (props: Props) => {
  const { onCharge } = props

  const status = useScript(APP_SQ_ENDPOINT_JS)
  const [card, setCard] = useState<any>(undefined)
  const [loadingForm, setLoadingForm] = useState<boolean>(false)
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false)

  useEffect(() => {
    if (status === 'ready') {
      initPage()
    }
  }, [status])

  const initPage = async () => {
    setLoadingForm(true)

    const square = (window as any).Square
    if (square) {
      const payments = square.payments(SQ_APPLICATION_ID, SQ_LOCATION_ID)
      const customCard = await payments.card({
        style: {
          input: {
            color: '#000',
            backgroundColor: '#F1EFE7',
          },
          '.input-container': {
            borderColor: '#CFC392',
          },
          '.input-container.is-focus': {
            borderColor: '#CFC392',
          },
        },
      })
      await payments.setLocale('ja-JP')
      await customCard.attach('#card-container')

      setCard(customCard)
    }
    setLoadingForm(false)
  }

  const chargeSquare = async () => {
    setLoadingPayment(true)
    const tokenResult = await card.tokenize()
    onCharge(tokenResult)
    setLoadingPayment(false)
  }

  return (
    <Wrapper>
      {loadingForm && <Skeleton />}
      <div id="card-container"></div>
      <ButtonCustom
        onClick={chargeSquare}
        loading={loadingPayment}
        className="btn-default btn-chestnut-rose"
        htmlType="button"
        name="保存する"
        showIconRight
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: 20px;
`

export default SquareForm

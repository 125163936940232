import { createAsyncThunk } from '@reduxjs/toolkit'
import { get } from 'lodash'
import { UseFormReturn } from 'react-hook-form/dist/types/form'
import addressRequest from 'src/api/requests/address'
import userRequest from 'src/api/requests/user'
import { FLOW_NAME, HTTP_CODE, PLAN_TYPE } from 'src/constants/app'
import { USER_STATE } from 'src/enum/userStatus'
import {
  AddressByPostalCodeRequestInterface,
  UpdateAddressRequestInterface,
  verifyOTPRequestInterface,
} from 'src/interfaces/address'
import history from 'src/libs/history'
import { PATH as AUTH_PATH } from 'src/pages/auth'
import { PATH as AUTOMATIC_PLAN_PATH } from 'src/pages/automaticPlan'
import { PATH as PATH_USER_DETAIL } from 'src/pages/myPage/UserDetail'
import addressActions from 'src/store/domain/address/addressByPostalCode'
import appActions from 'src/store/ui/app'
import uiActions from 'src/store/ui/notification'
import { updateCurrentFlow } from 'src/useCase/user'
import { LocalStorage } from 'src/utils/LocalStorage'
import { AddressByPostalCodeInterface } from './../interfaces/address.d'
import { getInfo } from './user'

export const getAddressByPostalCode = createAsyncThunk<
  any,
  AddressByPostalCodeRequestInterface
>('get_address_by_postal_code', async ({ postal_code }, { dispatch }) => {
  try {
    dispatch(addressActions.updateLoading(true))
    const res = await addressRequest.getAddressByPostalCode({ postal_code })
    if (res.data) {
      dispatch(addressActions.updateEntity(res.data))
    }
    dispatch(addressActions.updateLoading(false))
  } catch (error: any) {
    const message = error?.response?.data.message
    const defaultValue: AddressByPostalCodeInterface = {
      address1: '',
      address2: '',
      address3: '',
    }
    dispatch(addressActions.updateLoading(false))
    dispatch(addressActions.updateEntity(defaultValue))
    dispatch(
      uiActions.onNotification({
        type: 'error',
        message: message,
      }),
    )
  }
})

export interface PostAddress {
  credentials: UpdateAddressRequestInterface
  meta: UseFormReturn<UpdateAddressRequestInterface, any>
  planType?: string
}

export const updateAddress = createAsyncThunk<any, PostAddress>(
  'update_address',
  async ({ credentials, meta }, { dispatch }) => {
    try {
      dispatch(addressActions.updateLoading(true))
      await addressRequest.updateAddress(credentials)
      dispatch(addressActions.updateLoading(false))

      history.push(AUTOMATIC_PLAN_PATH.SMS_AUTH)
    } catch (error: any) {
      const status = error?.response?.status
      const errors = error?.response?.data.errors

      if (
        status === HTTP_CODE.UNPROCESSABLE_ENTITY &&
        Object.entries(errors).length > 0
      ) {
        errors &&
          Object.entries(errors).map((value) => {
            const getFieldName = get(value, 0)
            const getFieldValue = get(value, '1.0') || ''

            meta.setError(
              getFieldName as
                | 'postal_number'
                | 'prefecture_id'
                | 'city_id'
                | 'address'
                | 'building_name'
                | 'phone'
                | 'email'
                | 'password',
              { type: 'custom', message: getFieldValue },
            )
          })
      } else {
        const message = error?.response?.data.message
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: message,
          }),
        )
      }
      dispatch(addressActions.updateLoading(false))
    }
  },
)

export interface PostOTP {
  credentials: verifyOTPRequestInterface
  meta: UseFormReturn<verifyOTPRequestInterface, any>
  planType: string
}

export const verifyOTP = createAsyncThunk<any, PostOTP>(
  'verify_otp',
  async ({ credentials, meta, planType }, { dispatch }) => {
    try {
      dispatch(addressActions.updateLoading(true))
      const userTreatmentId = LocalStorage.getUserTreatmentId()
      await addressRequest.verifyOTP({
        ...credentials,
        ...{ plan_type: planType, user_treatment_id: userTreatmentId },
      })

      const res = await userRequest.userInfo({
        with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay,userTreatments',
      })
      const firstStateTransition = res.data?.state_transitions
        ? res.data?.state_transitions[0]
        : null

      dispatch(appActions.updateAuthInfo(res.data))

      if (!userTreatmentId) {
        history.push(AUTH_PATH.REGISTERED)
        return
      }

      if (
        firstStateTransition?.before_state ===
          USER_STATE.REQUESTING_SMS_VERIFICATION &&
        firstStateTransition?.state === USER_STATE.OTP_VERIFIED &&
        res.data.current_flow === FLOW_NAME.AUTOMATIC_SUGGEST_PLAN_REGISTER
      ) {
        await dispatch(updateCurrentFlow({ current_flow: null }))
        history.push(AUTH_PATH.MEDICAL_SPECIALTY)
        return
      }

      history.push(AUTH_PATH.REGISTRATION_COMPLETE)
      dispatch(addressActions.updateLoading(false))
    } catch (error: any) {
      const status = error?.response?.status
      const message = error?.response?.data.message

      if (status === HTTP_CODE.BAD_REQUEST) {
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: message,
          }),
        )
      }
      dispatch(addressActions.updateLoading(false))
    }
  },
)

export const resendOTP = createAsyncThunk<any>(
  'resend_otp',
  async (prams, { dispatch }) => {
    try {
      dispatch(addressActions.updateLoading(true))
      await addressRequest.resendOTP()
      dispatch(addressActions.updateLoading(false))
      dispatch(
        uiActions.onNotification({
          type: 'success',
          message: '認証コードを再発行しました',
        }),
      )
    } catch (error: any) {
      const status = error?.response?.status
      const message = error?.response?.data.message

      if (status === HTTP_CODE.BAD_REQUEST) {
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: message,
          }),
        )
      }

      dispatch(addressActions.updateLoading(false))
    }
  },
)

export const updateAddressSkipSMS = createAsyncThunk<any, PostAddress>(
  'update_address_skip_sms',
  async ({ credentials, meta, planType }, { dispatch }) => {
    try {
      dispatch(addressActions.updateLoading(true))
      await addressRequest.updateAddress(credentials)
      dispatch(addressActions.updateLoading(false))
      dispatch(
        getInfo({
          with: 'reservations,card,stateTransitions,cycleOrderPaid,latestOrderCanPay',
        }),
      )
      if (planType === PLAN_TYPE.PRESCRIPTION) {
        history.push(AUTH_PATH.REGISTRATION_COMPLETE)
      } else {
        history.push(PATH_USER_DETAIL)
      }
    } catch (error: any) {
      const status = error?.response?.status
      const errors = error?.response?.data.errors
      if (
        status === HTTP_CODE.UNPROCESSABLE_ENTITY &&
        Object.entries(errors).length > 0
      ) {
        errors &&
          Object.entries(errors).map((value) => {
            const getFieldName = get(value, 0)
            const getFieldValue = get(value, '1.0') || ''

            meta.setError(
              getFieldName as
                | 'postal_number'
                | 'prefecture_id'
                | 'city_id'
                | 'address'
                | 'building_name'
                | 'phone'
                | 'email',
              { type: 'custom', message: getFieldValue },
            )
          })
      } else {
        const message = error?.response?.data.message
        dispatch(
          uiActions.onNotification({
            type: 'error',
            message: message,
          }),
        )
      }
      dispatch(addressActions.updateLoading(false))
    }
  },
)

import React, { useEffect, useMemo, useState, useRef } from 'react'
import styled from 'styled-components'
import { useAppDispatch } from 'src/store'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useAppSelector } from 'src/store'

// Utils
import { createEchoConnection } from 'src/utils/echoService'
// Hooks
import useScrollToView from 'src/hook/useScrollToView'
import usePrevious from 'src/hook/usePrevious'
// Actions
import { getMessagesRequest, resetUnread } from 'src/useCase/message'
import { getChannelRequest } from 'src/useCase/channel'
import { actions } from 'src/store/domain/chat/message'
import { actions as channelActions } from 'src/store/domain/chat/channel'
// Selectors
import {
  selectMessages,
  selectLastPage,
  selectIsFetchingMessage,
  selectScrollToBottom,
} from 'src/store/domain/chat/message/selector'
import { selectIsFetchingChannel } from 'src/store/domain/chat/channel/selector'

// Components
import NewMessageForm from './NewMessageForm'
import MessageItem from './MessageItem'
// Constants
import {
  SOCKET_EVENT,
  SENDER_BY,
  MESSAGE_TYPE,
} from 'src/containers/Chat/constants'
// Icons
import Spinner from 'src/assets/icons/spinner.svg'
import LocalStorage from 'src/utils/LocalStorage'

const ChatContainer = () => {
  const messages = useAppSelector(selectMessages)
  const lastPage = useAppSelector(selectLastPage)
  const isFetchingMessage = useAppSelector(selectIsFetchingMessage)
  const isFetchingChannel = useAppSelector(selectIsFetchingChannel)
  const scrollToBottom = useAppSelector(selectScrollToBottom)

  const prevCountMessage: any = usePrevious(messages.length)
  const channelId = useRef<any>(null)
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)

  const [pagination, setPagination] = useState({
    page: 1,
    per_page: 20,
    order: '-id',
  })
  const [activeItem, setActiveItem] = useState(null)
  const { stwRefs } = useScrollToView({
    items: messages,
    activeItem: scrollToBottom ? activeItem : null,
    key: 'id',
    options: {
      behavior: 'smooth',
    },
  })

  useEffect(() => {
    return () => {
      dispatch(actions.resetMessageStore())
    }
  }, [])

  useEffect(() => {
    if (user?.id) {
      const channelName = `private-user.${user?.id}`
      const socket = socketListen(channelName)
      return () => {
        socket.leaveChannel(channelName)
      }
    }
  }, [user?.id])

  useEffect(() => {
    getMessages({ isInitial: pagination.page === 1 })
  }, [pagination.page])

  useEffect(() => {
    if (messages.length > prevCountMessage) {
      setActiveItem(messages[0]?.id)
      return
    }
    setActiveItem(null)
  }, [messages, prevCountMessage])

  const hasMore = useMemo(() => {
    return pagination.page < lastPage
  }, [pagination.page, lastPage])

  const socketListen = (channelName) => {
    const echo = createEchoConnection()
    echo
      .channel(channelName)
      .listen(SOCKET_EVENT.MESSAGE_CREATED, ({ data }) => {
        if (data.channel_id !== channelId.current.id) {
          return
        }
        dispatch(resetUnread({ channelId: data.channel_id }))
        messageCreated(data)
      })
      .listen(SOCKET_EVENT.MESSAGE_READ, ({ data }) => {
        const countMessageRead = data?.message_recipient?.message_read ?? 0
        dispatch(actions.setCountMessageRead(countMessageRead))
      })
      .listen(SOCKET_EVENT.MESSAGE_DELETED, ({ data }) => {
        const payload = {
          id: data.id,
        }
        dispatch(actions.deleteMessage(payload))
      })
      .listen(SOCKET_EVENT.DOCTOR_FINISH_CHAT, ({ channel }) => {
        const payload = { expired_at: channel.expired_at }
        dispatch(channelActions.updateChannel(payload))
      })
    return echo
  }

  const messageCreated = (data) => {
    if (
      data.sender_by === SENDER_BY.USER &&
      data.type_message === MESSAGE_TYPE.TEXT
    ) {
      const payload = {
        ...data,
        key: 'is_temporary',
        is_temporary: false,
      }
      dispatch(actions.updateMessage(payload))
      dispatch(actions.setIsSending(false))
      return
    }
    if (
      data.sender_by === SENDER_BY.MANAGER &&
      (data.type_message === MESSAGE_TYPE.TEXT ||
        data.type_message === MESSAGE_TYPE.IMAGE)
    ) {
      dispatch(actions.createMessage(data))
      return
    }
  }

  const getMessages = async ({ isInitial }) => {
    if (isInitial) {
      const userTreatmentId = LocalStorage.getUserTreatmentId()
      const params = {
        user_treatment_id: userTreatmentId,
        with: 'managers.clinics',
      }
      const { payload } = await dispatch(getChannelRequest(params))
      if (!payload) return
      dispatch(resetUnread({ channelId: payload.id }))
      channelId.current = payload
      const countMessageRead =
        payload?.message_recipients_manager?.message_read ?? 0
      dispatch(actions.setCountMessageRead(countMessageRead))
    }
    const paramsMessage = {
      id: channelId.current.id,
      per_page: pagination.per_page,
      order: pagination.order,
      page: pagination.page,
    }
    dispatch(getMessagesRequest(paramsMessage))
  }

  const nextPage = () => {
    setPagination((prevState) => ({ ...prevState, page: prevState.page + 1 }))
  }

  // check prettier
  return (
    <Wrapper>
      <div className="chat-content">
        <div
          id="scrollableDiv"
          className="chat-content__message-area"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {(isFetchingChannel || isFetchingMessage) &&
            pagination.page === 1 && (
              <div className="chat-content__message-area__loading">
                <img src={Spinner} alt="loading" />
              </div>
            )}
          <InfiniteScroll
            dataLength={messages.length}
            next={nextPage}
            style={{ display: 'flex', flexDirection: 'column-reverse' }}
            inverse={true}
            hasMore={hasMore}
            loader={
              <div className="chat-content__loading">
                <img src={Spinner} alt="loading" />
              </div>
            }
            scrollableTarget="scrollableDiv"
          >
            {messages.map((item, i) => {
              return (
                <div key={item.id} ref={stwRefs[i]}>
                  <MessageItem item={item} />
                </div>
              )
            })}
          </InfiniteScroll>
        </div>
        <NewMessageForm />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
  .chat-content {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;

    &__message-area {
      flex-grow: 1;
      overflow: auto;
      padding: 15px;
      background: #fff;
      font-size: 14px;
      color: #444444;
      line-height: 25px;
      position: relative;

      &__loading {
        position: absolute;
        width: 100%;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }

    &__loading {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      img {
        width: 35px;
        height: 35px;
      }
    }
  }
`

export default ChatContainer

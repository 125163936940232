import React from 'react'
import styled from 'styled-components'
import { Col, Form, Row } from 'antd'

import { InputField } from 'src/components/form'
import ButtonCustom from 'src/components/form/Button'
import MenuHeader from 'src/components/layout/Header/MenuHeader'
import LPHeader from 'src/components/layout/Header/LPHeader'
import nicknameImage from 'src/assets/images/landing/nickname.png'
import { FormValues, nickNameForm } from './nickNameForm'

const NickNameContainer: React.FC = () => {
  const { form, onSubmit } = nickNameForm()
  const { control, handleSubmit } = form

  return (
    <Wrapper>
      <LPHeader />
      <MenuHeader name="簡単AGA診断"></MenuHeader>
      <div className="landing nickname">
        <div className="nickname-content">
          <img className="landing__content-img" src={nicknameImage} />
        </div>

        <div className="nickname-form">
          <StyleForm>
            <Row>
              <Col xs={24}>
                <InputField<FormValues>
                  controller={{
                    control,
                    name: 'nick_name',
                  }}
                  inputProps={{
                    placeholder: '入力してください',
                  }}
                />
              </Col>
            </Row>

            <ButtonCustom
              className="btn-default btn-black mt-5 btn-submit"
              loading={false}
              name="結果を見る"
              onClick={handleSubmit(onSubmit)}
            />
          </StyleForm>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .nickname {
    font-family: Futura, sans-serif;

    &-form {
      margin: 20px;
      text-align: left;
    }

    &-input {
      background: #ffffff;
      border: 1px solid #cccccc;
      padding: 10px;
      width: 100%;
    }

    .errors {
      font-size: 13px;
    }

    .step-header-content {
      margin-top: 70px;
    }

    .landing__content-img {
      width: 100%;
    }
  }
`

const StyleForm = styled(Form)``

export default NickNameContainer

import React from 'react'

import LegalNoticeContainer from 'src/containers/LegalNotice'

const LegalNoticePage: React.FC = () => {
  return <LegalNoticeContainer />
}

export const PATH = '/legal-notice' as const
export default LegalNoticePage

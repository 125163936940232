import React from 'react'

import ListNotification from 'src/containers/Notification/ListNotification'

const ListNotificationPage: React.FC = () => {
  return <ListNotification />
}

export const PATH = '/notifications' as const
export default ListNotificationPage

import { OrderInterface } from 'src/interfaces/order'
import HTTPRequest from 'src/api/index'
import { PayloadIndexOrdersType } from 'src/useCase/order'

const apiPath = 'api/users'
// const apiPathV2 = 'api/v2/users'

type OrdersListResponse = {
  data: OrderInterface[]
}

class Order extends HTTPRequest {
  index(params: PayloadIndexOrdersType) {
    return this.get<OrdersListResponse>(`${apiPath}/orders`, {
      params,
    })
  }
}

export default new Order()

import queryString from 'query-string'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { resetPassword } from 'src/useCase/auth'
import { useAppDispatch } from 'src/store'
import { useLocation } from 'react-router-dom'
import history from 'src/libs/history'
import { PASSWORD_REGEX } from 'src/utils/regex'

export type FormValues = {
  password: string
  password_confirmation: string
  email: string
  token: string
}

export const useResetPassword = () => {
  const { search } = useLocation()
  const params = queryString.parse(search)
  const dispatch = useAppDispatch()

  const defaultValues: FormValues = {
    password: '',
    password_confirmation: '',
    email: String(params.email),
    token: String(params.token),
  }

  if (!defaultValues.token || !defaultValues.email) {
    history.push('/404')
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワードは 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      ),
    password_confirmation: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワードは 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      )
      .oneOf([Yup.ref('password'), null], 'パスワードが一致しません。'),
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await dispatch(resetPassword({ resetPassword: data, form: form }))
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit'

// Services
import chatService from 'src/api/requests/message'
// Interfaces
import { MessageITF } from 'src/store/domain/chat/message/interface'
// Actions
import { actions } from 'src/store/domain/chat/message'

export const getMessagesRequest = createAsyncThunk(
  'message/getMessageRequest',
  async (
    payload: {
      id: string | number
      per_page: number
      page: number
      order: string
    },
    { dispatch },
  ) => {
    dispatch(actions.setFetchingMessage(true))
    const { data, last_page } = await chatService.getMessages(payload)

    dispatch(actions.getMessagesRequestSuccess({ data, lastPage: last_page }))
    dispatch(actions.setFetchingMessage(false))
    return data
  },
)

export const createMessagesRequest = createAsyncThunk<MessageITF, any>(
  'message/createMessageRequest',
  async (payload, { dispatch }) => {
    const data = await chatService.createMessage(payload)
    return data
  },
)

export const resetUnread = createAsyncThunk<any, any>(
  'message/resetUnread',
  async (payload, { dispatch }) => {
    const data = await chatService.resetUnread(payload)
    return data
  },
)

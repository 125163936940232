import { includes } from 'lodash'
import React from 'react'
import styled from 'styled-components'

type Props = {
  listText: string[]
  textColorId?: number
}
const DescriptionHint = (props: Props) => {
  const { listText, textColorId } = props
  return (
    <Description>
      {listText.map((t, index) => {
        const isColor = textColorId === index
        if (t === '<br />') {
          return <br key={index} />
        }
        if (includes(t, '@')) {
          return (
            <div key={index}>
              Mail:{' '}
              <a href={`mailto:${t}`} className="link-mail">
                {t}
              </a>
            </div>
          )
        }
        return (
          <p
            key={index}
            className={`hint-text ${
              isColor ? 'cl-primary font-weight-bold' : ''
            }`}
          >
            {t}
          </p>
        )
      })}
    </Description>
  )
}

const Description = styled.div`
  padding: 20px;
  background-color: #f1efe7;

  .hint-text {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 0;
  }
`

export default DescriptionHint

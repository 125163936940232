import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Skeleton } from 'antd'
import StepsHeader from 'src/components/layout/Header/StepHeader'
import { useAppDispatch, useAppSelector } from 'src/store'
import { getWorkingDates } from 'src/useCase/video'
import LPHeader from 'src/components/layout/Header/LPHeader'
import Form from './Form'

const Container: React.FC = () => {
  const dispatch = useAppDispatch()
  const workingDates = useAppSelector((state) => state.domain.video.workingDate)
  useEffect(() => {
    const params = {}
    dispatch(getWorkingDates({ params }))
  }, [])

  return (
    <Wrapper>
      <LPHeader />
      {workingDates.loading ? (
        <Skeleton />
      ) : (
        <div className="w-full justify-center mx-auto layout-webview">
          <StepsHeader
            step={10}
            stepActive={4}
            stepActiveName="オンラインカウンセリング予約"
            className="top-70"
          />
          <div className="mt-10">
            <div className="page-title fs-20 fw-500 mt-30">
              オンラインカウンセリング予約
            </div>
            <div className="page-sub-title mt-30">
              ご希望の日時を選択してください。
            </div>
            <Form />
          </div>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker {
    background-color: var(--color-spring-wood);
    border-radius: 8px;
    border: 1px solid var(--color-spring-wood);
  }

  .ant-select-selection-placeholder {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .top-70 {
    position: relative;
    top: 0px !important;
  }
`

export default Container

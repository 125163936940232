import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  loading: boolean
  entity: Response.SquareCharge | null
  errorMsg: string | null
  errorCode: string | null
}

const initialState: State = {
  loading: false,
  entity: null,
  errorMsg: null,
  errorCode: null,
}

const { actions, reducer } = createSlice({
  name: 'square_payment',
  initialState,
  reducers: {
    updateEntity: (state, action: PayloadAction<Response.SquareCharge>) => {
      state.entity = action.payload
    },
    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    updateErrorMsg: (state, action: PayloadAction<string>) => {
      state.errorMsg = action.payload
    },
    updateErrorCode: (state, action: PayloadAction<string>) => {
      state.errorCode = action.payload
    },
  },
})

export { reducer }
export default actions

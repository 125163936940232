import React from 'react'

import UserDetail from 'src/containers/MyPage/UserDetail'

const UserDetailPage: React.FC = () => {
  return <UserDetail />
}

export const PATH = '/my-page/user' as const
export default UserDetailPage

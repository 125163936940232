import React from 'react'

import NickNameContainer from 'src/containers/Aga/NickName'

const NickName: React.FC = () => {
  return <NickNameContainer />
}

export const PATH = '/aga/nickname' as const
export default NickName

import { PASSWORD_REGEX } from 'src/utils/regex'
import { SubmitHandler, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import qs from 'query-string'
import { useLocation } from 'react-router-dom'

import { login } from 'src/useCase/auth'
import { useAppDispatch } from 'src/store'

export type FormValues = {
  email?: string
  password?: string
}

export type ParamsType = {
  redirect?: string
}

export const useLogin = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const defaultValues: FormValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('メールアドレスを正しく入力してください')
      .required('入力してください。'),
    password: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワードは 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      ),
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const params = qs.parse(location.search)
    await dispatch(login({ credentials: data, meta: form, params }))
  }

  const form = useForm<FormValues>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: yupResolver(validationSchema),
  })

  return {
    form,
    onSubmit,
  }
}

import styled from 'styled-components'

export const WrapperPlan = styled.div`
  margin-top: 50px;

  .plan-detail {
    &__title {
      font-size: 18px;
      padding: 0 20px;
    }
    &__header {
      padding: 0 20px 30px;
      background: var(--color-white);
      font-weight: bold;
      font-size: 22px;
      color: var(--color-rangoon-green);
    }
    &__submit {
      background-color: var(--color-white);
      color: #000;
      padding: 20px;
      text-align: center;

      &-btn {
        background-color: #000;
        color: #fff;
        font-weight: bold;
        text-align: center;
        padding: 16px 20px;
        margin: 10px auto;
        width: 100%;
      }
      &-price {
        font-weight: bold;
        font-size: 24px;
      }

      &-money {
        text-align: right;
        margin-right: 5%;
      }
    }
    &__image {
      background-color: #000;
      text-align: center;
      // height: 50px;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      border: 1px solid #000;
      height: auto;
      padding: 10px 5px;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__medicines {
      margin: 0 15px;

      &-top {
        font-size: 20px;
        border-bottom: 1px solid var(--color-rangoon-green);
        padding-bottom: 5px;
        margin: 40px 0 20px;
      }

      &-div {
        background-color: var(--color-spring-wood);
        margin-bottom: 10px;
      }

      &-header {
        pre {
          white-space: pre-wrap;
        }

        &-img {
          margin: auto;
        }
      }

      &-title {
        font-weight: bold;
        margin: 10px;
        font-size: 18px;
        text-align: center;
      }

      &-des {
        display: flex;
        justify-content: space-between;
        /* border-top: 1px solid #ccc; */
        margin: 15px;
        margin-bottom: 0;
        margin-top: 0;
        transition: height, 0.5s linear;
      }

      &-item-0 {
        margin: 10px 20px;
        /* font-weight: bold; */
        width: 40%;
      }

      &-item {
        width: 30%;
        margin-top: 10px;
        /* font-weight: bold; */
      }

      &-item-2 {
        margin: 0 20px 10px 20px;
        width: calc(100vw - 40px);
        resize: none;
        pointer-events: none;
        cursor: default;
        /* transition: height, 0.5s linear; */
        overflow: hidden;
      }
    }

    &-absolute {
      position: absolute;
      width: 100%;
      z-index: 1000;
    }
    &-logo {
      display: flex;
      justify-content: center;
      height: 30px;
      margin-top: 14px;

      img {
        filter: invert(100%) !important;
      }
    }

    &-img {
      margin: auto;
      height: 290px;
      object-fit: contain;
    }

    .slick-dots {
      padding: 10px !important;
    }
  }
  .img-icon {
    height: 23px;
    margin: 15px;
    width: 20px;
  }
  .img-icon-open {
    width: 15px;
    height: 2px;
    margin-bottom: 45px;
    margin-top: 21px;
  }
  .VueCarousel-pagination {
    bottom: 0;
    margin-top: -64px;
    z-index: 1000;
    background-color: transparent !important;
  }
  .detail-hr {
    margin: 0 15px 40px 15px;
    border-color: #ccc;
  }
  .detail-hr-mt {
    margin-top: 15px;
  }
  .slick-dots {
    bottom: 10px;
    border-radius: unset !important;
  }
  .slick-dots li button {
    background: var(--color-star-dust);
    width: 20px;
    height: 4px;
  }
  .slick-dots li {
    margin: 0 4px !important;
  }
  .slick-dots li.slick-active button {
    background: var(--color-limed-ash);
  }
  .slick-slider {
    margin-bottom: -8px;
  }
  .position-relative {
    position: relative;
  }
  .fs-14 {
    font-size: 14px;
  }
  .d-none-height {
    position: relative;
    height: 0 !important;
    overflow: hidden;
    transition: height 0.3s;
  }
  .d-block {
    animation-duration: 0.2s;
    animation-name: lineInserted;
    transition: height 0.2s;
  }

  @keyframes lineInserted {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  @keyframes lineRemoved {
    from {
      transform: scaleY(1);
    }
    to {
      transform: scaleY(0);
    }
  }

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  textarea {
    height: 100px;
    border: unset;
    outline: none;
    width: calc(100vw - 40px);
    margin: 0 20px;
    overflow: hidden;
    resize: none;
    width: calc(100vw - 40px) !important;
    background-color: var(--color-spring-wood);
  }
`

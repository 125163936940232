import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import {
  EMAIL_REGEX,
  PASSWORD_REGEX,
  PHONE_REGEX,
  POSTAL_NUMBER_REGEX,
} from 'src/utils/regex'
import { UpdateAddressRequestInterface } from 'src/interfaces/address'
import { updateAddress, updateAddressSkipSMS } from 'src/useCase/address'
import { useAppDispatch, useAppSelector } from 'src/store'
import { PLAN_TYPE } from 'src/constants/app'

interface Props {
  planType: string
  isMedical: boolean
}

export const useAddressEntryForm = (props: Props) => {
  const dispatch = useAppDispatch()

  const user = useAppSelector((state) => state.ui.app.authInfo)

  const isPrescription = props.planType === PLAN_TYPE.PRESCRIPTION

  const defaultValues: UpdateAddressRequestInterface = {
    postal_number: user?.postal_number || '',
    prefecture_id: user?.prefecture_id || '',
    city_id: user?.city_id || '',
    // address: user?.address || '',
    building_name: user?.building_name || '',
    phone: user?.phone || '',
    email: user?.email || '',
    password: '',
    password_confirmation: '',
  }

  const validationAddress = {
    postal_number: Yup.string()
      .required('入力してください。')
      .matches(POSTAL_NUMBER_REGEX, '郵便番号は半角数字7桁で入力してください')
      .max(7, '郵便番号は半角数字7桁で入力してください'),
    prefecture_id: Yup.string()
      .required('入力してください。')
      .max(30, '都道府県は30文字以下で入力してください'),
    city_id: Yup.string()
      .required('入力してください。')
      .max(30, '市区町村は30文字以下で入力してください'),
    // address: Yup.string()
    //   .required('入力してください。') //必須項目が入力されていません
    //   .max(30, '建物名/部屋番号は30文字以下で入力してください'),
    building_name: Yup.string().max(
      30,
      '建物名・部屋番号は30文字以下で入力してください',
    ),
    phone: Yup.string()
      .required('入力してください。') //電話番号を入力してください。
      .length(11, '携帯番号は11桁で入力してください。')
      .matches(PHONE_REGEX, '電話番号は０から始まる必要があります。'),
    email: Yup.string()
      .required('入力してください。') //必須項目が入力されていません
      .email('メールアドレスを正しく入力してください')
      .matches(EMAIL_REGEX, 'メールアドレスを正しく入力してください'),
  }
  const validationEmail = {
    email: Yup.string()
      .required('入力してください。') //必須項目が入力されていません
      .email('メールアドレスを正しく入力してください')
      .matches(EMAIL_REGEX, 'メールアドレスを正しく入力してください'),
  }
  const validationPassword = {
    password: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワードは 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      ),
    password_confirmation: Yup.string()
      .required('入力してください。')
      .matches(
        PASSWORD_REGEX,
        'パスワード（確認）は 8文字以上で、文字と数字がそれぞれ1つ以上含まれている必要があります。',
      )
      .oneOf([Yup.ref('password'), null], 'パスワード（確認）が一致しません。'),
  }

  const validationPrescriptionSchema = Yup.object().shape(
    !user?.hasPassword
      ? {
          ...validationAddress,
          ...validationEmail,
          ...validationPassword,
        }
      : {
          ...validationAddress,
          ...validationEmail,
        },
  )

  const validationAutomaticSchema = Yup.object().shape(
    !user?.hasPassword
      ? {
          ...validationAddress,
          ...validationPassword,
        }
      : {
          ...validationAddress,
        },
  )
  const validationMedicalSchema = Yup.object().shape(
    !user?.hasPassword
      ? {
          ...validationEmail,
          ...validationPassword,
        }
      : {
          ...validationEmail,
        },
  )

  const onSubmit = async (data: UpdateAddressRequestInterface) => {
    if (props.isMedical) {
      const submitDataMedical = {
        email: data.email,
        password: data.password,
        password_confirmation: data.password_confirmation,
        isIgnoreSms: true,
      }

      await dispatch(
        updateAddressSkipSMS({
          credentials: submitDataMedical,
          meta: form,
          planType: props.planType,
        }),
      )
    } else {
      if (isPrescription) {
        delete data.password
        delete data.password_confirmation
      }
      await dispatch(updateAddress({ credentials: data, meta: form }))
    }
  }

  const resultSchema = props.isMedical
    ? yupResolver(validationMedicalSchema)
    : isPrescription
    ? yupResolver(validationPrescriptionSchema)
    : yupResolver(validationAutomaticSchema)

  const form = useForm<UpdateAddressRequestInterface>({
    defaultValues,
    shouldFocusError: true,
    mode: 'all',
    resolver: resultSchema,
  })

  return {
    form,
    onSubmit,
  }
}
